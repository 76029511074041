import { Editor } from '@meisterlabs/slate';

import { HighlightedText } from '../types';

export const toggleHighlight = (editor: Editor, color: string | null) => {
  const mark = Editor.marks(editor);

  if (color == null) return editor.removeMark('highlight');
  if ((mark as HighlightedText)?.highlight === color)
    editor.removeMark('highlight');
  else editor.addMark('highlight', color);
};
