import {
  withInlineStyle,
  withInlineStyleSerialization,
} from './withInlineStyle';

/**
 * Enable inline style underline with a shortcut.
 */
export const withInlineUnderline = function (shortcut: string) {
  withInlineStyle(shortcut, 'underline');
};

export const withInlineUnderlineSerialization = function () {
  withInlineStyleSerialization('underline', {
    html: {
      tag: 'u',
      alias: [],
    },
  });
};
