import {
  Editor,
  Node,
  Path,
  Range,
  convertBlock,
  parser,
} from '@meisterlabs/slate';
import { withDeleteBackward } from '@meisterlabs/slate-react';

import { DepthBlock } from './withBlockDepth';

const convertEmptyToType = function (editor: Editor, type: string) {
  const { selection } = editor;

  if (!selection) return;
  if (!Range.isCollapsed(selection)) return;
  if (selection.focus.offset !== 0 || Path.hasPrevious(selection.focus.path))
    return;

  const parentBlock = Node.parent(editor, selection.focus.path) as DepthBlock;

  if (parentBlock.type === type) return;

  const depth = parser.parseToInt(parentBlock.depth);

  if (depth > 0) return;

  convertBlock(editor, parentBlock.key, {
    type,
  });

  return false;
};

/**
 * This middleware implements that deleting backward on a element with type that is also empty
 * that it will be converted to the given type, by default `paragraph`.
 */
export const withConvertWhenBackwardsDeletingEmpty = function (
  defaultType = 'paragraph'
) {
  withDeleteBackward((editor) => convertEmptyToType(editor, defaultType));
};
