import { Literal, Node } from 'unist';

export interface State<FromNode, ToNode> {
  handlers: Handlers<FromNode, ToNode>;
  one: <InputNode = FromNode, OutputNode = ToNode, ParentNode = InputNode>(
    node: InputNode,
    parent?: ParentNode
  ) => OutputNode;
  multiple: <InputNode = FromNode, OutputNode = ToNode, ParentNode = InputNode>(
    nodes: InputNode[],
    parent?: ParentNode
  ) => OutputNode[];
}

export type Check<FromNode, ToNode, ParentNode = FromNode> = (
  state: State<FromNode, ToNode>,
  node: FromNode,
  parent?: ParentNode
) => boolean;

export type Handler<FromNode, ToNode, ParentNode = FromNode> = (
  state: State<FromNode, ToNode>,
  node: FromNode,
  parent?: ParentNode
) => ToNode | ToNode[] | undefined;

export type Handlers<FromNode, ToNode> = Array<
  [Check<FromNode, ToNode>, Handler<FromNode, ToNode>]
>;

export interface StateOptions<FromNode, ToNode> {
  handlers?: Handlers<FromNode, ToNode>;
}

export const createState = function <
  FromNode extends Node,
  ToNode extends Node,
>(options: StateOptions<FromNode, ToNode>) {
  const { handlers = [] } = options;

  const state = {
    handlers,
    one,
    multiple,
  } as State<FromNode, ToNode>;

  function one(node: FromNode, parent?: FromNode): ToNode | ToNode[] {
    const index = state.handlers.findIndex(([check]) =>
      check(state, node, parent)
    );

    if (index !== -1) {
      const handle = state.handlers[index][1];

      if (handle) return handle(state, node, parent);
    }

    if ('children' in node && Array.isArray(node.children)) {
      return node.children.flatMap((child) => one(child, node));
    }

    if ('value' in node && typeof node.value === 'string') {
      const textNode = {
        type: 'text',
        value: node.value,
      } as FromNode & Literal;

      return one(textNode, parent);
    }

    // We don't want to throw an error here, because we want to be able to
    // handle only a subset of the nodes.
    console.error(`No handler for type: ${node.type}`);

    // Returning of an empty array so that our serializers/deserializers still work nicely
    // And most likely it just doesn't do anything with the content
    return [];
  }

  function multiple(nodes: FromNode[], parent?: FromNode): ToNode[] {
    return nodes.flatMap((node) => one(node, parent));
  }

  return {
    one,
    multiple,
  };
};
