import React, { useCallback, useRef, useState } from 'react';

import { ResizeContext } from './Context';

export interface HorizontalChildrenProps {
  width: string;
  isResizing: boolean;
  rootRefCallback: React.RefCallback<HTMLElement>;
  targetRefCallback: React.RefCallback<HTMLElement>;
  naturalWidthRefCallback: React.RefCallback<
    HTMLImageElement | HTMLVideoElement
  >;
}

export interface HorizontalProps {
  initialWidth?: string;
  minWidth?: number;
  children: (props: HorizontalChildrenProps) => JSX.Element;
  setNewWidth: (width: string) => void;
}

export const Horizontal = function (props: HorizontalProps) {
  const { initialWidth, minWidth, children, setNewWidth } = props;

  const [width, setWidth] = useState<string>(initialWidth);
  const [isResizing, setIsResizing] = useState(false);
  const widthRef = useRef(initialWidth);
  const rootRef = useRef<HTMLElement | null>(null);
  const targetRef = useRef<HTMLElement | null>(null);
  const naturalWidthRef = useRef<HTMLImageElement | HTMLVideoElement | null>(
    null
  );

  const handleResize = useCallback(
    ({ x }) => {
      const maxWidth =
        (naturalWidthRef.current as HTMLImageElement)?.naturalWidth ??
        (naturalWidthRef.current as HTMLVideoElement)?.videoWidth ??
        Infinity;

      if (!rootRef.current || !targetRef.current) return;
      if (maxWidth === 0) return;

      const rootWidth = rootRef.current.clientWidth;
      const targetWidth = targetRef.current.clientWidth;

      const newWidth = Math.max(
        Math.min(targetWidth + x, rootWidth, maxWidth),
        minWidth
      );

      const newWidthPercent = `${(newWidth / rootWidth) * 100}%`;

      setWidth(newWidthPercent);
      widthRef.current = newWidthPercent;
    },
    [minWidth]
  );

  const handleResizeStart = () => {
    setIsResizing(true);
  };

  const handleResizeStop = () => {
    setIsResizing(false);
    setNewWidth(widthRef.current);
  };

  const rootRefCallback = useCallback((node) => {
    if (node !== null) {
      rootRef.current = node;
      handleResize({ x: 0 });
    }
  }, []);

  const targetRefCallback = useCallback((node) => {
    if (node !== null) {
      targetRef.current = node;
      handleResize({ x: 0 });
    }
  }, []);

  const naturalWidthRefCallback = useCallback((node) => {
    if (node !== null) {
      naturalWidthRef.current = node;
      handleResize({ x: 0 });
    }
  }, []);

  return (
    <ResizeContext.Provider
      value={{
        handleResize,
        handleResizeStart,
        handleResizeStop,
      }}
    >
      {children({
        isResizing,
        width,
        rootRefCallback,
        targetRefCallback,
        naturalWidthRefCallback,
      })}
    </ResizeContext.Provider>
  );
};
