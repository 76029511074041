import { Editor } from 'slate';

import { EditorContext } from '../EditorContext';

/**
 * Adds a callback to the editor's onChange event.
 */
export const withOnChange = function (callback: (editor: Editor) => void) {
  const { editor } = EditorContext.get();
  const { onChange } = editor;

  editor.onChange = function () {
    callback(editor);
    onChange();
  };
};
