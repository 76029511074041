import logo from '../assets/Lucidchart.png';
import { Parser } from '../types';
import findMatch from './utils/findMatch';

const chartIdRe =
  /[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/;

const Embed: Parser = {
  logo,
  name: 'Lucidchart',
  parseUrl(string) {
    return findMatch({
      string,
      find({ url }) {
        if (!url.host || !url.pathname) return false;

        return url.host.includes('lucid') && chartIdRe.test(url.pathname);
      },
      transform({ href, url }) {
        if (!url.pathname) return null;

        const chartId = url.pathname.match(chartIdRe)?.[0];

        if (!chartId) return null;

        return {
          type: 'Lucidchart',
          url: href,
          frameSrc: `https://lucidchart.com/documents/embeddedchart/${chartId}`,
          frameAspectRatio: 12 / 16,
          frameBorder: true,
        };
      },
    });
  },
};

export default Embed;
