import React, { useEffect, useMemo, useRef } from 'react';
import { useDragLayer } from 'react-dnd';
import { isBlockElement } from '@meisterlabs/slate';
import { ReactEditor, useSlate } from '@meisterlabs/slate-react';

import style from './style.module.css';
import { WithDndOptions } from '../middlewares/withDnD';
import { useAutoScroller } from '../hooks/useAutoScroller';

const DraggedNodes: React.FC<{ keys: Array<string> }> = function ({ keys }) {
  const editor = useSlate();
  const ref = useRef(null);
  const parentEl = useRef(null);

  const descendants = useMemo(() => {
    return editor.children.filter(
      (node) => isBlockElement(node) && keys.includes(node.key)
    );
  }, [keys]);

  useEffect(() => {
    if (parentEl.current) {
      parentEl.current.remove();
      parentEl.current = null;
    }

    const editorDom = ReactEditor.toDOMNode(editor as ReactEditor, editor);
    const width = editorDom.clientWidth;

    const newParent = document.createElement('div');
    newParent.classList.add('mn-editor-dnd-parent');
    newParent.style.width = `${width}px`;

    descendants.forEach(function (element) {
      const node = ReactEditor.toDOMNode(
        editor as ReactEditor,
        element
      ).closest('.element-root');
      const clonedNode = node.cloneNode(true);

      newParent.appendChild(clonedNode);
    });

    ref.current.appendChild(newParent);
    parentEl.current = newParent;
  }, [descendants]);

  return <div ref={ref} className={style.dragLayer} data-slate-editor />;
};

export const DragLayer: React.FC<WithDndOptions> = function ({ onDragStart }) {
  const {
    clientOffset,
    itemType,
    isDragging,
    keys,
    diffFromInitialOffset,
    sourceOffset,
  } = useDragLayer((monitor) => ({
    keys: monitor.getItem(),
    itemType: monitor.getItemType(),
    // we cannot only use sourceOffset because the mn editor is also a fixed div.
    sourceOffset: monitor.getSourceClientOffset(),
    diffFromInitialOffset: monitor.getDifferenceFromInitialOffset(),
    isDragging: monitor.isDragging(),
    clientOffset: monitor.getClientOffset(),
  }));

  // This doesn't work for Chrome or Safari but does work in Firefox
  useEffect(() => {
    if (isDragging) {
      onDragStart?.(keys);

      document.body.style.cursor = 'grabbing';
    } else document.body.style.cursor = null;
  }, [isDragging, onDragStart, keys]);

  useAutoScroller(clientOffset);

  if (itemType !== 'block') return null;
  if (!isDragging) return null;
  if (!sourceOffset) return null;

  return (
    <div
      style={{
        position: 'fixed',
        pointerEvents: 'none',
        zIndex: 100,
        top: 0,
        width: '100%',
        height: '100%',
      }}
    >
      <div
        style={{
          transform: `translate(${diffFromInitialOffset.x}px, ${sourceOffset.y}px)`,
        }}
      >
        <DraggedNodes keys={keys} />
      </div>
    </div>
  );
};
