import {
  BlockElement,
  Editor,
  Node,
  Range,
  Transforms,
  parser,
} from '@meisterlabs/slate';
import {
  withDeleteBackward,
  withElementAttributes,
  withHotKey,
} from '@meisterlabs/slate-react';

export type DepthBlock = BlockElement & {
  depth: string;
};

export interface Options {
  hotKeyForIdent?: string;
  hotKeyForOutdent?: string;
  blocksWithDepth: string[];
}

const depthBlockAndRemove = function (editor: Editor) {
  const { selection } = editor;

  if (!selection) return false;
  if (!Range.isCollapsed(selection)) return false;
  if (selection.focus.offset !== 0) return false;

  const parentBlock = Node.parent(editor, selection.anchor.path) as DepthBlock;
  const depth = parser.parseToInt(parentBlock.depth);

  if (!parentBlock.depth || depth === 0) return false;

  Transforms.setNodes<DepthBlock>(editor, {
    depth: parser.parseToString(depth - 1),
  });

  return true;
};

/**
 * Enable that the given block types have the possibility of being idented.
 * Can be styled in a css with the `data-has-depth` attribute and the `--depth` css variable.
 *
 * example:
 * ```css
 * [data-slate-editor=true] .element[data-has-depth="true"] {
 *     margin-left: calc(var(--depth, 0) * 25px);
 * }
 * ```
 */
export const withBlockDepth = function (options: Options) {
  const {
    hotKeyForIdent = 'tab',
    hotKeyForOutdent = 'shift+tab',
    blocksWithDepth,
  } = options;

  withDeleteBackward(function (editor) {
    if (depthBlockAndRemove(editor)) return false;
  });

  const increaseBlockDepth = function (
    event: React.KeyboardEvent,
    step: number,
    editor: Editor
  ) {
    if (!editor.selection) return;

    let anyNodeChanged = false;

    const [start, end] = Range.edges(editor.selection);

    const nodes = Node.nodes(editor, {
      from: start.path,
      to: end.path,
    });

    for (const [node, path] of nodes) {
      const block = node as DepthBlock;
      const type = block.type;

      if (!blocksWithDepth.includes(type)) continue;

      event.preventDefault();

      const initialDepth = parser.parseToInt(block.depth ?? '0');
      const depth = Math.max(0, initialDepth + step);

      Transforms.setNodes<DepthBlock>(
        editor,
        { depth: parser.parseToString(depth) },
        { at: path }
      );

      anyNodeChanged = true;
    }

    // Only stop keyDown event if any node was changed
    if (anyNodeChanged) return false;
  };

  withHotKey(hotKeyForIdent, function (event, editor) {
    return increaseBlockDepth(event, 1, editor);
  });

  withHotKey(hotKeyForOutdent, function (event, editor) {
    return increaseBlockDepth(event, -1, editor);
  });

  withElementAttributes<DepthBlock>(function (attributes, element) {
    if (!blocksWithDepth.includes(element.type)) return attributes;

    const style = (attributes.style ?? {}) as object;

    return {
      ...attributes,
      'data-has-depth': 'true',
      style: {
        ...style,
        '--depth': element.depth,
      },
    };
  });
};
