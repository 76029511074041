import React from 'react';
import { Transforms, parser, removeUnusedProperties } from '@meisterlabs/slate';
import {
  withElementRenderer,
  withElementNormalizer,
} from '@meisterlabs/slate-react';

import Icon from '../components/Icon';
import { IconBlock, isIconBlock } from '../types';

export interface IconType {
  id: number;
  type: string;

  // React 16/17 types have `children` as a prop on React.FC.
  // React 18 doesn't have it anymore. So, VFC is a better choice.
  component: React.VFC;
}

export interface ColorType {
  id: number;
  type: string;
  value: string;
  opacity: number;
}

export interface Options {
  icons: Array<IconType>;
  colors: Array<ColorType>;
  iconPickerHeader: string;
  defaultProps: {
    iconType: string;
    colorType: string;
    iconId: number;
    colorId: number;
  };
}

/**
 * This middleware adds the icon block.
 */
export const withIcon = function (options: Options) {
  withElementRenderer<IconBlock>(
    (element) => isIconBlock(element),
    (props) => <Icon {...props} {...options} />
  );

  withElementNormalizer<IconBlock>(
    (element) => isIconBlock(element),
    (element, path, editor) =>
      removeUnusedProperties(editor, element, path, [
        'iconType',
        'colorType',
        'iconId',
        'colorId',
      ])
  );

  // Make sure we always have at least the default icon and color.
  withElementNormalizer<IconBlock>(
    (element) => isIconBlock(element),
    (element, path, editor) => {
      if (
        element.iconType &&
        element.colorType &&
        element.iconId &&
        element.colorId
      ) {
        return;
      }

      Transforms.setNodes<IconBlock>(
        editor,
        {
          iconType: element.iconType ?? options.defaultProps.iconType,
          colorType: element.colorType ?? options.defaultProps.colorType,
          iconId:
            element.iconId ?? parser.parseToString(options.defaultProps.iconId),
          colorId:
            element.colorId ??
            parser.parseToString(options.defaultProps.colorId),
        },
        { at: path }
      );

      return false;
    }
  );
};
