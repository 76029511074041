import {
  BlockElement,
  Editor,
  Node,
  generateKey,
  isBlockElement,
} from '@meisterlabs/slate';
import { withOnCopy, withOnCut, withOnPaste } from '@meisterlabs/slate-react';

import {
  SerializationType,
  SerializerEditor,
  isSerializerEditor,
} from '../types';

interface Options {
  currentNode: BlockElement;
  isEditorPaste: boolean;
  plain?: string;
}

const handleSpecialPasteCases = (
  editor: Editor,
  nodes: Node[],
  options: Options
) => {
  const { currentNode, isEditorPaste = false, plain } = options;

  let finalNodes = nodes;

  // Insert the text from clipboard as is if the current node is a code block.
  // In the future we can refactor this part (if needed) to achieve the same thing with
  // a middleware but at this point in time, it is an overkill as code block is the only
  // block that has a special paste logic.
  if (!isEditorPaste && currentNode.type === 'code' && plain) {
    editor.insertText(plain);

    return [];
  }

  // If the first node is a paragraph, we copy the type of the current node and just keep the children.
  // this way we don't override the current block type.
  if (
    isBlockElement(nodes[0]) &&
    nodes[0].type === 'paragraph' &&
    !editor.isVoid(currentNode)
  ) {
    finalNodes[0] = {
      ...currentNode,
      children: nodes[0].children,
    };
  }

  // If the current node is a list item, we copy the list type and depth to the pasted nodes.
  // This way it is easy to pastet lists into the editor
  if (
    !isEditorPaste &&
    currentNode.type === 'list-item' &&
    'listType' in currentNode
  ) {
    finalNodes = finalNodes.map((node) => {
      if (editor.isVoid(node as BlockElement)) return node;

      return {
        ...node,
        type: 'list-item',
        listType: currentNode.listType,
        depth: 'depth' in currentNode ? currentNode.depth : '0',
      };
    });
  }

  return finalNodes;
};

const copy = (editor: SerializerEditor, event: React.ClipboardEvent) => {
  const markdownText = editor.serialize(SerializationType.Markdown);

  const serializedHtml = editor.serialize(SerializationType.Html);
  const htmlText = `<div data-meisternote="true" data-schema-version="2">${serializedHtml}</div>`;

  event.clipboardData.clearData();
  event.clipboardData.setData('text/plain', markdownText);
  event.clipboardData.setData('text/html', htmlText);
};

const paste = (editor: SerializerEditor, event: React.ClipboardEvent) => {
  const { children, selection, deserialize } = editor;
  const currentNode = children[selection.anchor.path[0]];

  const plain = event.clipboardData.getData('text/plain');
  const html = event.clipboardData.getData('text/html');
  const isEditorPaste = html.includes('data-meisternote="true"');

  let nodes: Array<Node> = [];

  if (html && isEditorPaste) {
    nodes = deserialize(SerializationType.Html, html);
  }

  if (nodes.length === 0 && plain) {
    nodes = deserialize(SerializationType.Markdown, plain);
  }

  if (nodes.length === 0) return;

  nodes = nodes.map((node) => ({ ...node, key: generateKey() }));

  if (!isBlockElement(currentNode)) return;

  nodes = handleSpecialPasteCases(editor, nodes, {
    currentNode,
    isEditorPaste,
    plain,
  });

  editor.insertFragment(nodes);
};

export const withClipboard = function () {
  withOnCopy(function (event: React.ClipboardEvent, editor: Editor) {
    if (!isSerializerEditor(editor)) return;

    const { selection } = editor;

    if (!selection) return;

    copy(editor, event);

    event.preventDefault();

    return false;
  });

  withOnCut(function (event: React.ClipboardEvent, editor: Editor) {
    if (!isSerializerEditor(editor)) return;

    const { selection } = editor;

    if (!selection) return;

    copy(editor, event);

    editor.deleteFragment();
    event.preventDefault();

    return false;
  });

  withOnPaste(function (event: React.ClipboardEvent, editor: Editor) {
    if (!isSerializerEditor(editor)) return;

    const { selection } = editor;

    if (!selection) return;

    paste(editor, event);

    event.preventDefault();

    return false;
  });
};
