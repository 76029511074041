import React, { useContext } from 'react';
import { colors } from '@meisterlabs/colors';

import LopItem from './LopItem';
import { BlockRenderElementProps } from '@meisterlabs/slate-react';
import { LopBlock } from '../types';
import type { Options } from '../middleware/withLop';
import { LopContext } from './LopContext';

const style = {
  root: {
    cursor: 'pointer',
    paddingTop: 4,
    flexDirection: 'column' as const,
    userSelect: 'none' as const,
  },
  empty: {
    display: 'flex',
    flexDirection: 'row' as const,
    alignItems: 'center',
  },
  emptyIcon: {
    width: 32,
    height: 32,
    padding: 6,
  },
  emptyMessage: {
    color: colors.grey400,
  },
};

const Lop: React.FC<BlockRenderElementProps<LopBlock> & Options> = function (
  props
) {
  const {
    attributes,
    children,
    emptyMessage,
    emptyIcon: EmptyIcon,
    onClickItem,
  } = props;

  const context = useContext(LopContext);
  const { items } = context;

  return (
    <div {...attributes} style={attributes.style ?? {}} contentEditable={false}>
      <div style={style.root}>
        {items.length === 0 && (
          <div style={style.empty}>
            <div style={style.emptyIcon}>
              <EmptyIcon />
            </div>
            <span style={style.emptyMessage}>{emptyMessage}</span>
          </div>
        )}
        {items.map((item, i) => (
          <LopItem
            key={i}
            color={item.color}
            title={item.title}
            url={item.url}
            icon={item.icon}
            onClick={() => onClickItem(item)}
          />
        ))}
      </div>
      {children}
    </div>
  );
};

export default Lop;
