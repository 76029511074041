import { useState } from 'react';
import {
  createEditable,
  createMiddlewareEditor,
  withInitialValue,
  withMarkdownShortcuts,
  withParagraph,
  withUnorderedList,
} from '../exports.js';

const initialValue = [
  {
    type: 'paragraph',
    children: [{ text: 'Hello World!' }],
  },
];

const unorderedListBlockProps = {
  type: 'list-item',
  listType: 'unordered',
  children: [{ text: '' }],
};

export function Demo() {
  const [editor] = useState(() => {
    return createMiddlewareEditor(() => {
      withInitialValue(initialValue);
    });
  });

  const [Editable] = useState(() => {
    return createEditable(() => {
      withParagraph();
      withUnorderedList();

      withMarkdownShortcuts({
        '- ': unorderedListBlockProps,
      });
    });
  });

  return <Editable editor={editor} />;
}
