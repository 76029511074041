export enum Status {
  loading = 'Loading',
  success = 'Success',
  failed = 'Failed',
}

class File {
  url?: string;
  status: Status;
  promise?: Promise<File>;

  constructor(promise?: Promise<File>) {
    this.promise = promise;
    this.status = Status.loading;
  }

  failed() {
    this.status = Status.failed;
    this.promise = null;
    this.url = null;
  }

  success(data: Blob) {
    this.status = Status.success;
    this.promise = null;
    this.url = URL.createObjectURL(data);
  }

  loading(promise: Promise<File>) {
    this.status = Status.loading;
    this.promise = promise;
  }
}

class FileManager {
  files: Record<string, File> = {};

  load(url: string, fetchFile: (url: string) => Promise<Blob>): Promise<File> {
    const file = this.files[url];

    if (file) return file.promise ?? Promise.resolve(file);

    const promise = fetchFile(url)
      .then((data) => {
        this.files[url].success(data);

        return this.files[url];
      })
      .catch(() => {
        this.files[url].failed();

        return this.files[url];
      });

    this.files[url] = new File(promise);

    return promise;
  }

  status(url: string) {
    return this.files[url]?.status ?? Status.loading;
  }

  clientUrl(url: string) {
    return this.files[url]?.url;
  }
}

export default new FileManager();
