import { Editor, Transforms } from 'slate';

import { EditorContext } from '../EditorContext';
import { BlockElement } from '../types';
import { generateKey } from '../utils';

export interface WithNeverEmptyNormalizerOptions {
  /**
   * The type of the node to insert.
   * @default 'paragraph'
   */
  type?: string;
}

export const withNeverEmptyNormalizer = function ({
  type = 'paragraph',
}: WithNeverEmptyNormalizerOptions = {}) {
  const { editor } = EditorContext.get();
  const { normalizeNode } = editor;

  // Has to be a function, else the children will be shared between all nodes as it's a reference
  const getDefaultNode = (): BlockElement => ({
    type,
    key: generateKey(),
    children: [{ text: '' }],
  });

  editor.normalizeNode = (entry) => {
    const [node] = entry;

    if (Editor.isEditor(node)) {
      // Ensure there is at least one empty node with type 'paragraph'
      if (node.children.length === 0) {
        Transforms.insertNodes(editor, getDefaultNode());

        return;
      }
    }

    // Fall back to the original normalizeNode to handle other rules
    normalizeNode(entry);
  };
};
