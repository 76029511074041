import React from 'react';
import { useDraggable } from '@meisterlabs/slate-react-dnd';
import { FileBlock } from '../types';
import { BlockElement } from '@meisterlabs/slate';

export type DraggableFileComponentProps<T extends BlockElement> = {
  element: T;
};

export const DraggableFileComponent: React.FC<
  DraggableFileComponentProps<FileBlock>
> = (props) => {
  const { element, children } = props;
  const { drag } = useDraggable(element);

  const gripRef = React.useRef<HTMLDivElement>(null);
  drag(gripRef);

  return <div ref={gripRef}>{children}</div>;
};
