import React from 'react';
import {
  withEditableWrapper,
  withElementWrapper,
  withOnDrop,
} from '@meisterlabs/slate-react';

import { Droppable } from '../components/Droppable';
import { DndContext } from '../components/DndContext';

export interface WithDndOptions {
  onDragStart?: (keys: Array<string>) => void;
}

export const withDnD = function (options: WithDndOptions = {}): void {
  withElementWrapper(Droppable);
  withEditableWrapper((props) => <DndContext {...props} {...options} />);

  // If this is a block dnd, we don't want to execute the default slate editor onDrop behavior
  withOnDrop(function (event) {
    const items = event.dataTransfer.items;
    const firstItem = items[0];
    const hasOneItem = items.length === 1;

    const isBlockDnd =
      hasOneItem &&
      firstItem.kind === 'string' &&
      firstItem.type === 'application/json';

    if (isBlockDnd) return false;
  });
};
