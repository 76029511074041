import {
  HAST,
  SAST,
  withHtmlDeserializer,
  withHtmlSerializer,
} from '@meisterlabs/slate-serializer';

interface TocBlock extends SAST.Block {
  type: 'toc';
}

export const withTocSerializers = function () {
  withHtmlSerializer<TocBlock>(
    (_, node) => node.type === 'toc',
    () => {
      return {
        type: 'element',
        tagName: 'div',
        properties: {
          dataTableOfContentsBlock: 'true',
        },
        children: [],
      };
    }
  );

  withHtmlDeserializer<HAST.Element>(
    (_, node) =>
      node.tagName === 'div' && !!node.properties?.dataTableOfContentsBlock,
    () => {
      return {
        type: 'toc',
        properties: {},
        children: [
          {
            type: 'text',
            value: '',
          },
        ],
      };
    }
  );

  // TODO: Currently no markdown serializer, however, we could add a list with the names of the Headings and
  // links to these headings. But this is a bit more complex, so for the future.
};
