import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from 'react';
import clsx from 'clsx';
import { ReactEditor, useSlate } from '@meisterlabs/slate-react';

import { HunspellInstace, SupportedLanguages } from '../types';
import { Options } from '../middlewares/withSpellCheck';
import style from './style.module.css';
import { SpellcheckPopover } from './SpellcheckPopover';
import { DictionaryContext } from './DictionaryContext';
import { updateDictionary } from '../utils/CustomDictionary/updateDictionary';
import { SpellerrorItem, misspelledItems } from '../utils/misspelledItems';
import { itemIntersectingMouseEvent, rectForRangeRef } from '../utils/dom';

type SpellcheckOverlaysProps = Options & {
  hunspell: HunspellInstace;
  language: SupportedLanguages;
};

export const SpellcheckOverlays: React.FC<SpellcheckOverlaysProps> = function (
  options
) {
  const {
    hunspell,
    language,
    addToDictionaryText,
    ignoreText,
    onSelectedSpellcheckSuggestion,
  } = options;

  const editor = useSlate();
  const dictionary = useContext(DictionaryContext);
  const [updateRects, forceUpdateRects] = useReducer((x) => x + 1, 0);
  const [hoverIndex, setHoverIndex] = useState<number>(-1);
  const [activeIndex, setActiveIndex] = useState<number>(-1);

  const beforeWidth = useRef<number>(0);
  const beforeHeight = useRef<number>(0);

  const [items, setItems] = useState<Array<SpellerrorItem>>([]);
  const timer = useRef(null);

  const isOpen = activeIndex !== -1;

  const editorNode = useMemo(() => {
    return ReactEditor.toDOMNode(editor as ReactEditor, editor);
  }, [editor]);

  const rects = useMemo(
    () =>
      items.map(({ rangeRef }) => {
        return rectForRangeRef(editorNode, editor as ReactEditor, rangeRef);
      }),
    [items, updateRects]
  );

  const reloadBlockEntries = (updateAll: boolean = false) => {
    const items = misspelledItems({
      editor,
      hunspell,
      language,
      forceUpdate: updateAll,
    });

    setItems(items);
  };

  const debounceReloadBlockEntries = (updateAll: boolean = false) => {
    if (timer.current) clearTimeout(timer.current);

    timer.current = setTimeout(() => reloadBlockEntries(updateAll), 500);

    return () => clearTimeout(timer.current);
  };

  useEffect(() => {
    const requestID = requestAnimationFrame(forceUpdateRects);

    return () => cancelAnimationFrame(requestID);
  }, [editor.children]);

  useEffect(() => {
    return debounceReloadBlockEntries();
  }, [editor.children, hunspell]);

  // Since the background containers have pointer-events: none and therefore doesn't have a click/hover event.
  // We need to manually check if the mouse is clicking/hovering on one of the spellcheck error rects.
  useEffect(() => {
    let requestID: number;

    const onMouseDown = (event: MouseEvent) => {
      if (isOpen) return;

      const clickedIndex = itemIntersectingMouseEvent(rects, event);

      setActiveIndex(clickedIndex);
    };

    const onMouseMove = (event: MouseEvent) => {
      const hoveredIndex = itemIntersectingMouseEvent(rects, event);

      setHoverIndex(hoveredIndex);
    };

    const observer = new ResizeObserver((entry) => {
      const { width, height } = entry[0].contentRect;

      if (beforeWidth.current === width && beforeHeight.current === height)
        return;

      beforeWidth.current = width;
      beforeHeight.current = height;

      requestID = requestAnimationFrame(() => {
        forceUpdateRects();
        requestID = null;
      });
    });

    editorNode.addEventListener('mousedown', onMouseDown);
    editorNode.addEventListener('mousemove', onMouseMove);
    editorNode.addEventListener('keydown', close);
    observer.observe(editorNode);

    return () => {
      editorNode.removeEventListener('mousedown', onMouseDown);
      editorNode.removeEventListener('mousemove', onMouseMove);
      editorNode.removeEventListener('keydown', close);
      observer.disconnect();

      if (requestID) cancelAnimationFrame(requestID);
    };
  }, [editorNode, isOpen, rects]);

  useEffect(() => {
    updateDictionary(hunspell, dictionary.words);

    reloadBlockEntries();
  }, [dictionary.words]);

  const close = useCallback(() => {
    setActiveIndex(-1);
    setHoverIndex(-1);
  }, []);

  const item = items[activeIndex];
  const rect = rects[activeIndex];

  return (
    <>
      <SpellcheckPopover
        dictionary={dictionary}
        word={item?.word}
        location={item?.rangeRef.current}
        rect={rect}
        isOpen={isOpen}
        close={close}
        hunspell={hunspell}
        addToDictionaryText={addToDictionaryText}
        ignoreText={ignoreText}
        onSelectedSpellcheckSuggestion={onSelectedSpellcheckSuggestion}
      />
      {rects.map((rect, index) => {
        if (!rect) return null;

        const isHovered = index === hoverIndex;

        return (
          <div
            key={index}
            className={clsx(
              style.spellcheckError,
              isHovered && style.isHovered
            )}
            style={{
              top: rect.top,
              left: rect.left,
              width: rect.width,
              height: rect.height,
            }}
          />
        );
      })}
    </>
  );
};
