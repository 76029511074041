import {
  BlockElement,
  Editor,
  Node,
  Path,
  RangeRef,
  Text,
  getRangeFromOffsetsInTextList,
  isBlockElement,
} from '@meisterlabs/slate';

import anchorme from 'anchorme';

import { HunspellInstace, SupportedLanguages } from '../types';
import { tokenize } from './tokenize';
import { Hunspell } from '.';

interface SpellerrorItemsForBlockProps {
  hunspell: HunspellInstace;
  editor: Editor;
  node: BlockElement;
  path: Path;
  wordTokens: Array<{ value: string; isWord: boolean }>;
}

const spellerrorItemsForBlock = function (
  props: SpellerrorItemsForBlockProps
): SpellerrorItem[] {
  const { editor, hunspell, node, path, wordTokens } = props;

  let offset = 0;

  return wordTokens.flatMap(function (token) {
    const word = token.value;
    const start = offset;
    const end = start + word.length;
    offset = end;

    // If this was not a word, ignore it.
    if (!token.isWord) return [];

    // Now spellcheck the word.
    const isOk = Hunspell.checkWord(hunspell, word);

    // If the word contained no typos, ignore it.
    if (isOk) return [];

    const range = getRangeFromOffsetsInTextList(node, path, start, end);

    if (!range || !range.anchor || !range.focus) return [];

    // Return a patch with new attributes for the editor.
    return {
      rangeRef: Editor.rangeRef(editor, range, { affinity: 'inward' }),
      word,
    };
  });
};

export type SpellerrorItem = {
  rangeRef: RangeRef;
  word: string;
};

interface Props {
  editor: Editor;
  hunspell: HunspellInstace;
  forceUpdate?: boolean;
  language: SupportedLanguages;
}

let decoratorCache = new WeakMap<object, Array<SpellerrorItem>>();
let pathCache = new WeakMap<object, Path>();
let blockMisspelledCache = new WeakMap<object, Array<string>>();

const isValidBlock = function (
  editor: Editor,
  node: Node
): node is BlockElement {
  return isBlockElement(node) && node.type !== 'code' && !editor.isVoid(node);
};

const filterOutLinks = function (input: string) {
  return anchorme({
    input,
    options: {
      specialTransform: [
        {
          // Match all links
          test: /.*/,
          // Replace them with spaces
          transform: (s) => ' '.repeat(s.length),
        },
      ],
    },
  });
};

/**
 * This function will return all the misspelled items in the editor.
 * It will return an array of objects with the following properties:
 * - word: The misspelled word.
 * - range: The Slate range of the misspelled word.
 * - rect: The DOM rect of the misspelled word.
 */
export const misspelledItems = function (props: Props) {
  const { editor, hunspell, language, forceUpdate = false } = props;

  if (forceUpdate) {
    decoratorCache = new WeakMap();
    pathCache = new WeakMap();
    blockMisspelledCache = new WeakMap();
  }

  return editor.children.flatMap(function (block, index) {
    const path = [index];

    // Since the dictionary can be updated, this checks if the previously misspelled words are now in the dictionary
    // If they are, we need to update the decorators for this block.
    const misspelledWordBefore = blockMisspelledCache.get(block) ?? [];
    const didPathChange = pathCache.get(block) !== path;

    const isInDictionaryNow = misspelledWordBefore.some((word) => {
      // It is possible that the hunspell instance is not ready yet.
      if (!hunspell?.caches?.customDictionary) return false;

      return hunspell.caches.customDictionary.has(word);
    });

    const cachedItem = decoratorCache.get(block);

    if (cachedItem && !isInDictionaryNow && !didPathChange) return cachedItem;

    if (!isValidBlock(editor, block)) {
      decoratorCache.set(block, []);
      blockMisspelledCache.set(block, []);

      return [];
    }

    // Workaround so that the spellcheck ignores inline code marks.
    // Basically, setting the text of the code mark to spaces, this way offsets are correct.
    const blockText = block.children.reduce((acc, child) => {
      if (!Text.isText(child)) return acc + Node.string(child);
      if ('code' in child) return acc + ' '.repeat(child.text.length);

      let text = child.text;

      // Filter out links from the text by replacing with spaces
      text = filterOutLinks(text);

      return acc + text;
    }, '');

    const wordTokens = tokenize({
      text: blockText,
      language,
    });

    cachedItem?.forEach(({ rangeRef }) => rangeRef.unref());

    const result = spellerrorItemsForBlock({
      editor,
      hunspell,
      node: block,
      path,
      wordTokens,
    });

    decoratorCache.set(block, result);
    blockMisspelledCache.set(
      block,
      result.map(({ word }) => word)
    );

    return result;
  });
};
