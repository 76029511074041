import {
  BlockElement,
  Node as SlateNode,
  Text as SlateText,
  isBlockElement,
} from '@meisterlabs/slate';

import * as Sast from '../sast';

const createSastBlock = function (node: BlockElement): Sast.Block {
  const { children, type, ...rest } = node;

  // We don't want to include the key in the properties
  delete rest.key;

  return {
    type,
    children: children.map(walk) as Sast.Content[],
    properties: rest,
  };
};

const createSastText = function (node: SlateText): Sast.TextContent {
  const { text, ...rest } = node;

  return Object.entries(rest).reduce<Sast.TextContent>(
    (acc, [key, value]) => {
      return {
        type: 'mark',
        name: key,
        value,
        children: [acc],
      } as Sast.Mark;
    },
    {
      type: 'text',
      value: text,
    }
  );
};

const walk = function (node: SlateNode): Sast.Content {
  if (isBlockElement(node)) return createSastBlock(node);
  if (SlateText.isText(node)) return createSastText(node);
};

const walkNodes = function (node: SlateNode[]): Sast.Content[] {
  return node.map(walk);
};

export default function sastParse(doc): Sast.Root {
  const slate = JSON.parse(doc);

  if (Array.isArray(slate)) {
    return {
      type: 'root',
      children: walkNodes(slate),
    };
  }

  return {
    type: 'root',
    children: [walk(slate)],
  };
}
