import { createContext } from 'react';

import { FileType } from '../types';

export interface FileContextState {
  files: {
    [key: string]: FileType;
  };
}

export const FileContext = createContext<FileContextState>({
  files: {},
});

FileContext.displayName = 'FileContext';
