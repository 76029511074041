import React from 'react';
import { removeUnusedProperties } from '@meisterlabs/slate';
import {
  withElementNormalizer,
  withElementRenderer,
} from '@meisterlabs/slate-react';

import { OrderedListItem } from '../components/OrderedList';
import { OrderedListItemBlock, isOrderedListItemBlock } from '../types';

export interface WithOrderedListOptions {
  /**
   * This expects an array of block types which should have depth, and therefore will be taken into account when
   * calculating the index of the ordered list item
   */
  blocksWithDepth?: string[];

  /**
   * This expects an array of block types which should always continue the ordered list, even if they are not
   * at a certain depth, this is useful for blocks like files, which should not be part of the ordered list
   * but should not break the index calculation
   */
  blocksThatAlwaysContinue?: string[];
}

const defaultOptions: WithOrderedListOptions = {
  blocksWithDepth: ['list-item'],
  blocksThatAlwaysContinue: [],
};

/**
 * This middleware adds the Block of an ordered list.
 */
export const withOrderedList = function (options: WithOrderedListOptions = {}) {
  const optionsWithDefaults = { ...defaultOptions, ...options };

  withElementRenderer<OrderedListItemBlock>(
    (element) => isOrderedListItemBlock(element),
    (props) => <OrderedListItem {...props} {...optionsWithDefaults} />
  );

  withElementNormalizer<OrderedListItemBlock>(
    (element) => isOrderedListItemBlock(element),
    (element, path, editor) =>
      removeUnusedProperties(editor, element, path, ['listType'])
  );
};
