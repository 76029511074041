import { Editor, Transforms, parser } from '@meisterlabs/slate';

import { loadPicker } from './loadPicker';
import { MMBlock } from '../types';

interface Options {
  editor: Editor;
  key: string;
  pickerUrl: string;
  appKey: string;
  resourcesDomain: string;
  onSelectedMmMap?: (mapId: string) => void;
}

const show = function (appKey: string, resourcesDomain: string) {
  return new Promise(function (resolve) {
    global.MM.ui.Picker.load(appKey, {
      size: { width: '80%', height: '80%' },
      resourcesDomain: resourcesDomain,
      appDomain: window.location.origin,
      multiple: false,
      onPick: resolve,
    });

    global.MM.ui.Picker.show();

    // set focus to the picker
    const pickerIframe = document.querySelector<HTMLIFrameElement>(
      '#mm_picker_container iframe'
    );

    const pickerOnLoad = pickerIframe.onload;

    pickerIframe.onload = (event: Event) => {
      pickerOnLoad.bind(this)(event);

      pickerIframe.focus();
    };
  });
};

export const showPicker = function (options: Options) {
  loadPicker(options.pickerUrl)
    .then(() => show(options.appKey, options.resourcesDomain))
    .then((maps) => maps[0])
    .then(function (data) {
      options.onSelectedMmMap?.(data.id);

      Transforms.setNodes<MMBlock>(
        options.editor,
        {
          mapId: parser.parseToString(data.id),
          mapRootId: parser.parseToString(data.rootId),
          mapTitle: parser.parseToString(data.title),
        },
        {
          at: [],
          match: (n: MMBlock) => n.key === options.key,
        }
      );
    });
};
