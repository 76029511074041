import logo from '../assets/GoogleMaps.png';
import { Parser } from '../types';
import findMatch from './utils/findMatch';

const re = new RegExp('^https://www.google.com/maps/embed.*$');

const Embed: Parser = {
  logo,
  name: 'Google Maps',
  parseUrl(string) {
    return findMatch({
      string,
      find: ({ href }) => re.test(href),
      transform({ href }) {
        return {
          type: 'GoogleMaps',
          url: href,
          frameSrc: href,
          frameAspectRatio: 12 / 16,
          frameBorder: true,
        };
      },
    });
  },
};

export default Embed;
