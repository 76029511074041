import { withHotKey, withLeafClassNames } from '@meisterlabs/slate-react';

import { toggleMark } from '../commands/toggleMark';
import { StyledText } from '../types';
import {
  HAST,
  MDAST,
  SAST,
  withHtmlDeserializer,
  withHtmlSerializer,
  withMarkdownDeserializer,
  withMarkdownSerializer,
} from '@meisterlabs/slate-serializer';

export interface InlineSerialization {
  html?: {
    tag: string;
    alias: string[];
  };
  markdown?: {
    type: string;
  };
}

/**
 * Middleware to easily add an inline style, like bold or italic, that can be toggled with a shortcut.
 */
export const withInlineStyle = function (shortcut: string, name: string) {
  withHotKey(shortcut, (event, editor) => {
    event.preventDefault();

    toggleMark(editor, name);
  });

  withLeafClassNames<StyledText>(function (leaf) {
    const final = [];

    if (leaf[name]) final.push(name);

    return final;
  });
};

export const withInlineStyleSerialization = function (
  name: string,
  serialization: InlineSerialization
) {
  if (serialization.html) {
    withHtmlSerializer<SAST.Mark>(
      (_, node) => SAST.isMark(node) && node.name === name,
      ({ multiple }, node) => {
        return {
          type: 'element',
          tagName: serialization.html.tag,
          children: multiple<SAST.TextContent, HAST.Text>(node.children, node),
        };
      }
    );

    withHtmlDeserializer<HAST.Element>(
      (_, node) =>
        node.tagName === serialization.html.tag ||
        serialization.html.alias.includes(node.tagName),
      ({ multiple }, node) => {
        return {
          type: 'mark',
          name,
          value: true,
          children: multiple<HAST.ElementContent, SAST.TextContent>(
            node.children,
            node
          ),
        };
      }
    );
  }

  if (serialization.markdown) {
    withMarkdownSerializer<SAST.Mark>(
      (_, node) => SAST.isMark(node) && node.name === name,
      ({ multiple }, node) => {
        return {
          type: serialization.markdown.type,
          children: multiple<SAST.TextContent>(node.children, node),
        } as MDAST.PhrasingContent;
      }
    );

    withMarkdownDeserializer<MDAST.PhrasingContent>(
      (_, node) => node.type === serialization.markdown.type,
      ({ multiple }, node) => {
        const isParent = 'children' in node;

        return {
          type: 'mark',
          name,
          value: true,
          // In markdown sometimes things like inlineCode have a value directly and not extra children.
          children: isParent
            ? multiple<MDAST.Content, SAST.TextContent>(
                (node as MDAST.Parent).children,
                node
              )
            : [
                {
                  type: 'text',
                  value: 'value' in node ? node.value : '',
                },
              ],
        };
      }
    );
  }
};
