import logo from '../assets/Framer.png';
import { Parser } from '../types';
import findMatch from './utils/findMatch';

const re = new RegExp('^https://framer.com/embed/(.*)$');

const Embed: Parser = {
  logo,
  name: 'Framer',
  parseUrl(string) {
    return findMatch({
      string,
      find: ({ clean }) => re.test(clean),
      transform({ clean }) {
        return {
          type: 'Framer',
          url: clean,
          frameSrc: clean,
          frameAspectRatio: 12 / 16,
          frameBorder: true,
        };
      },
    });
  },
};

export default Embed;
