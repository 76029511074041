import React from 'react';
import { colors } from '@meisterlabs/colors';
import {
  BlockRenderElementProps,
  withElementRenderer,
  withIsVoid,
} from '@meisterlabs/slate-react';

import { HorizontalRuleBlock, isHorizontalRuleBlock } from '../types';

import style from './horizontalrule.module.css';

const HorizontalRule: React.FC<BlockRenderElementProps<HorizontalRuleBlock>> =
  function ({ attributes, children }) {
    return (
      <div
        {...attributes}
        contentEditable={false}
        style={
          {
            ...(attributes.style ?? {}),
            '--slate-horizontal-rule-color': colors.grey300,
          } as React.CSSProperties
        }
      >
        <hr className={style.horizontalRule} />
        {children}
      </div>
    );
  };

/**
 * This middleware adds the Horizontal rules block.
 */
export const withHorizontalRule = function () {
  withElementRenderer<HorizontalRuleBlock>(
    (element) => isHorizontalRuleBlock(element),
    HorizontalRule
  );

  withIsVoid((element) => isHorizontalRuleBlock(element));
};
