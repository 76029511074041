import { lru } from 'tiny-lru';

import { load } from './load';
import { loadForLanguage } from '../StandardDictionary';
import { HunspellInstace, SupportedLanguages } from '../../types';

const cache = new Map<string, HunspellInstace>();

export const create = async function (language: SupportedLanguages) {
  if (cache.has(language)) return cache.get(language);

  const [hunspell, dictionary] = await Promise.all([
    load(),
    loadForLanguage(language),
  ]);

  // Create our hunspell instance with the given dictionary buffers.
  const aff = hunspell.mountBuffer(dictionary.aff, `${language}.aff`);
  const dic = hunspell.mountBuffer(dictionary.dic, `${language}.dic`);
  const hunspellInstance = hunspell.create(aff, dic) as HunspellInstace;

  // Add the language property for convenience.
  hunspellInstance.language = language;

  hunspellInstance.caches = {
    words: lru(1000),
    suggestions: lru(1000),
  };

  // Make sure we don't create multiple instances.
  cache.set(language, hunspellInstance);

  return hunspellInstance;
};
