import React from 'react';

import {
  newBlockAfter,
  newBlockBefore,
  generateKey,
  BlockElement,
  removeUnusedProperties,
  Transforms,
  Editor,
  isBlockElement,
  Path,
} from '@meisterlabs/slate';

import {
  toSlateBlock,
  withElementRenderer,
  withIsVoid,
  withOnDrop,
  withElementNormalizer,
  withOnPaste,
  BlockRenderElementProps,
} from '@meisterlabs/slate-react';

import { FileComponent } from '../components/FileComponent';
import { AddFileSource, FileBlock, FileType, isFileBlock } from '../types';
import { updateFile } from '../commands';
import { DraggableFileComponent } from '../components/DraggableFileComponent';

export interface Options {
  placeholderText?: string;
  onClickPreview?: (element: FileBlock) => void;
  fetchFile: (element: FileBlock) => void;
  addFile: (
    key: string,
    file: File,
    source?: AddFileSource
  ) => Promise<FileType>;
  withDraggableWrapper?: boolean;
  onFileResized?: (width: number) => void;
}

const insertFileBlockBelow = function (editor: Editor) {
  const key = generateKey();

  const block = {
    type: 'file',
    key,
    children: [{ text: '' }],
  } as FileBlock;

  Transforms.insertNodes(editor, block);

  return key;
};

const setFileBlock = function (editor: Editor, path: Path) {
  const key = generateKey();

  Transforms.setNodes<FileBlock>(
    editor,
    {
      type: 'file',
      key,
    },
    { at: path }
  );

  return key;
};

const insertFileBlock = function (editor) {
  const currentBlocks = Editor.nodes<BlockElement>(editor, {
    at: editor.selection,
    match: (node) => isBlockElement(node),
  });

  const currentBlockEntry = currentBlocks.next().value;

  if (!currentBlockEntry) return insertFileBlockBelow(editor);

  const [currentBlock, path] = currentBlockEntry;

  if (
    !Editor.isEmpty(editor, currentBlock) ||
    currentBlock.type !== 'paragraph'
  ) {
    return insertFileBlockBelow(editor);
  }

  // If the current block is empty and a paragraph block, set it to a file block instead of adding it below it
  return setFileBlock(editor, path);
};

const renderFileComponent = (
  options: Options,
  props: React.PropsWithChildren<BlockRenderElementProps<FileBlock>>
) => {
  if (options.withDraggableWrapper) {
    return (
      <DraggableFileComponent element={props.element}>
        <FileComponent {...props} {...options} />
      </DraggableFileComponent>
    );
  }

  return <FileComponent {...props} {...options} />;
};

/**
 * This middleware adds the file block.
 */
export const withFile = function (options: Options) {
  withIsVoid<FileBlock>((node) => isFileBlock(node));

  withElementRenderer<FileBlock>(
    (element) => isFileBlock(element),
    (props) => renderFileComponent(options, props)
  );

  withElementNormalizer<FileBlock>(
    (element) => isFileBlock(element),
    (element, path, editor) =>
      removeUnusedProperties(editor, element, path, [
        'fileKey',
        'fileName',
        'fileContentType',
        'width',
      ])
  );

  withOnPaste((event, editor) => {
    const data = event.clipboardData;

    const files = Array.from(data.files).filter((file) => file.size > 0);

    if (files.length === 0) return;

    for (const file of Array.from(files)) {
      const key = insertFileBlock(editor);

      options.addFile(key, file, AddFileSource.FILE_DROP).then((file) => {
        updateFile(editor, key, {
          fileContentType: file.contentType,
          fileKey: file.id,
          fileName: file.fileName,
        });
      });
    }
  });

  withOnDrop((event, editor) => {
    const files = event.dataTransfer?.files;

    if (files.length === 0) return;

    const target = event.target as HTMLElement;
    const node = toSlateBlock(editor, target) as BlockElement;
    const targetRect = (event.target as HTMLElement).getBoundingClientRect();
    const dropYPosition = event.pageY;
    const isAbove = dropYPosition - targetRect.top < targetRect.height / 2;

    for (const file of Array.from(files)) {
      const key = generateKey();

      const block = {
        type: 'file',
        key,
        children: [{ text: '' }],
      } as FileBlock;

      if (isAbove) newBlockBefore(editor, block, node.key);
      else newBlockAfter(editor, block, node.key);

      options.addFile(key, file, AddFileSource.FILE_DROP).then((file) => {
        updateFile(editor, key, {
          fileContentType: file.contentType,
          fileKey: file.id,
          fileName: file.fileName,
        });
      });
    }
  });
};
