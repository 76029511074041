import logo from '../assets/GoogleSlides.png';
import { Parser } from '../types';

const re = new RegExp('^https?://docs.google.com/presentation/d/(.*)$');

const Embed: Parser = {
  logo,
  name: 'Google Slides',
  parseUrl(url) {
    const match = url.match(re);

    if (!match) return null;

    const frameSrc = url.replace('/edit', '/preview').replace('/pub', '/embed');

    return {
      type: 'GoogleSlides',
      url,
      frameSrc,
      frameAspectRatio: 9 / 16,
      frameBorder: true,
    };
  },
};

export default Embed;
