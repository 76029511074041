import { EditableContextState } from './types';

export class EditableContext {
  static current: EditableContextState | null = null;

  static create(context: EditableContextState): EditableContextState {
    EditableContext.current = context;

    return context;
  }

  static get(): EditableContextState {
    if (!EditableContext.current) {
      throw new Error('EditableContext not initialized');
    }

    return EditableContext.current;
  }

  static done() {
    EditableContext.current = null;
  }
}
