import logo from '../assets/Descript.png';
import { Parser } from '../types';

const re = new RegExp('https?://share.descript.com/view/(\\w+)$');

const Embed: Parser = {
  logo,
  name: 'Descript',
  parseUrl(url) {
    const shareId = url.match(re)?.[1];

    if (!shareId) return null;

    return {
      type: 'Descript',
      url,
      frameSrc: `https://share.descript.com/embed/${shareId}`,
      frameAspectRatio: 9 / 16,
      frameBorder: true,
    };
  },
};

export default Embed;
