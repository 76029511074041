import * as Parsers from '../parsers';
import { FrameData } from '../types';

export function parseUrl(url: string): FrameData {
  return Object.values(Parsers).reduce<null | FrameData>(function (
    acc,
    parser
  ) {
    if (acc) return acc;
    return parser.parseUrl(url);
  }, null);
}
