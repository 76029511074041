import { MMBlock } from '../types';

export const getBasicImageUrl = function (base: string, element: MMBlock) {
  return `${base}/export/image/${element.mapRootId}?variable_size=1`;
};

export const getPaidImageUrl = function (base: string, element: MMBlock) {
  return `${base}/export/image/${element.mapRootId}`;
};

export const getImageUrl = function (
  base: string,
  element: MMBlock,
  paid: boolean
) {
  return paid
    ? getPaidImageUrl(base, element)
    : getBasicImageUrl(base, element);
};

export const getOpeningUrl = function (base: string, element: MMBlock) {
  return `${base}/app/${element.mapRootId}`;
};
