import { createContext } from 'react';

interface LoadingContextState {
  onLoadStart: () => string;
  onLoadEnd: (token: string) => void;
}

const LoadingContext = createContext<LoadingContextState>({
  onLoadStart: () => '',
  onLoadEnd: () => {},
});

LoadingContext.displayName = 'LoadingContext';

export default LoadingContext;
