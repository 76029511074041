import { clsx } from 'clsx';
import React from 'react';
import { parser } from '@meisterlabs/slate';
import { BlockRenderElementProps } from '@meisterlabs/slate-react';

import { ListItem } from './ListItem';
import { UnorderedListItemBlock } from '../types';

import style from './style.module.css';

// We support three types of markers for unordered lists.
const markers = [style.solidMarker, style.strokeMarker, style.lineMarker];

export const UnorderedListItem: React.FC<
  BlockRenderElementProps<UnorderedListItemBlock>
> = function (props) {
  const { attributes, children, element } = props;

  const depth = parser.parseToInt(element.depth) ?? 0;
  const classNames = clsx(style.marker, markers[depth % 3]);

  return (
    <ListItem
      attributes={attributes}
      marker={<div className={classNames}></div>}
    >
      {children}
    </ListItem>
  );
};
