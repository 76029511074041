import {
  HAST,
  SAST,
  withHtmlDeserializer,
  withHtmlSerializer,
  withMarkdownSerializer,
} from '@meisterlabs/slate-serializer';

interface FileBlock extends SAST.Block {
  type: 'file';
  properties: {
    fileKey: string;
    fileName: string;
    fileContentType: string;
    width?: string;
  };
}

export const withFileSerializers = function () {
  withHtmlSerializer<FileBlock>(
    (_, node) => node.type === 'file',
    (_, node) => {
      const hasFile = node.properties.fileKey && node.properties.fileName;

      const children = !hasFile
        ? []
        : ([
            {
              type: 'element',
              tagName: 'div',
              properties: {
                dataFile: 'true',
                dataWidth: node.properties.width,
                dataName: node.properties.fileName,
                dataKey: node.properties.fileKey,
                dataContentType: node.properties.fileContentType,
              },
              children: [
                {
                  type: 'text',
                  value: '',
                },
              ],
            },
          ] as HAST.Element[]);

      return {
        type: 'element',
        tagName: 'div',
        properties: {
          dataFileBlock: 'true',
        },
        children,
      };
    }
  );

  withHtmlDeserializer<HAST.Element>(
    (_, node) => node.tagName === 'div' && !!node.properties?.dataFileBlock,
    (_, node) => {
      const file = node.children[0] as HAST.Element;

      const properties = !file?.properties
        ? {}
        : {
            width: file.properties.dataWidth,
            fileKey: file.properties.dataKey,
            fileName: file.properties.dataName,
            fileContentType: file.properties.dataContentType,
          };

      return {
        type: 'file',
        properties,
        children: [{ type: 'text', value: '' }],
      };
    }
  );

  // Maybe it would be possible to serialize the file as a link
  // However, that would require that we can access the link to the file
  // For now that is too much work, but should be quite easily possible in the future through the options
  withMarkdownSerializer<FileBlock>(
    (_, node) => node.type === 'file',
    (_, node) => {
      const name = node.properties.fileName ?? '';

      return {
        type: 'paragraph',
        children: [
          {
            type: 'text',
            value: name,
          },
        ],
      };
    }
  );
};
