import React from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import { DragLayer } from './DragLayer';
import type { WithDndOptions } from '../middlewares/withDnD';

export const DndContext: React.FC<WithDndOptions> = function ({
  children,
  onDragStart,
}) {
  // Because of a Bug in Chrome and the native Html5Backend we still show the Drag Handler
  // See: https://github.com/react-dnd/react-dnd/issues/325
  return (
    <DndProvider backend={HTML5Backend}>
      <DragLayer onDragStart={onDragStart} />
      {children}
    </DndProvider>
  );
};
