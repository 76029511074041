import { HunspellFactory } from 'hunspell-asm';
import { loadModule } from 'hunspell-asm/dist/cjs';

let cache: Promise<HunspellFactory> = null;

export const load = function () {
  cache = cache || loadModule();

  return cache;
};
