import React from 'react';
import { withElementRenderer, withIsVoid } from '@meisterlabs/slate-react';

import Lop from '../components/Lop';
import { LopBlock, isLopBlock } from '../types';
import { PageItem } from '../components/LopContext';

export interface Options {
  emptyIcon: React.VFC;
  emptyMessage: string;
  onClickItem?: (item: PageItem) => void;
}

/**
 * This middleware adds a block for a list of related pages.
 */
export const withLop = function (options: Options) {
  withIsVoid((element) => isLopBlock(element));

  withElementRenderer<LopBlock>(
    (element) => isLopBlock(element),
    (props) => <Lop {...props} {...options} />
  );
};
