import { getSuggestions } from './getSuggestions';

export const checkWord = function (hunspell, word) {
  const result = hunspell.caches.words.get(word);

  if (result != null) return result;

  const isOk = hunspell.spell(word);

  hunspell.caches.words.set(word, isOk);

  if (!isOk) getSuggestions.warmup(hunspell, word);

  return isOk;
};
