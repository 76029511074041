import React from 'react';
import { ReactEditor, useSlateStatic } from '@meisterlabs/slate-react';
import * as Popover from '@radix-ui/react-popover';
import { Range, Transforms } from '@meisterlabs/slate';

import { HunspellInstace, SupportedLanguages } from '../types';
import { Options } from '../middlewares/withSpellCheck';
import { SpellcheckContent } from './SpellcheckContent';
import { DictionaryContextValue } from './DictionaryContext';

type Props = Options & {
  dictionary: DictionaryContextValue;
  word?: string;
  location?: Range;
  rect?: DOMRect;
  isOpen: boolean;
  hunspell: HunspellInstace;
  close: () => void;
  onSelectedSpellcheckSuggestion?: (
    suggestion: string,
    word: string,
    language: SupportedLanguages
  ) => void;
};

export const SpellcheckPopover: React.FC<Props> = function (props) {
  const {
    dictionary,
    location,
    word,
    rect,
    isOpen,
    close,
    hunspell,
    addToDictionaryText,
    ignoreText,
    onSelectedSpellcheckSuggestion,
  } = props;

  const editor = useSlateStatic();

  const onSelectText = function (text) {
    ReactEditor.focus(editor as ReactEditor);

    onSelectedSpellcheckSuggestion?.(text, word, hunspell.language);

    Transforms.insertText(editor, text, { at: location });

    close();
  };

  const onAddToDictionary = function () {
    dictionary.addToDictionary(word);
    close();
  };

  const onIgnore = function () {
    dictionary.ignore(word);
    close();
  };

  return (
    <Popover.Root open={isOpen}>
      <Popover.Anchor asChild>
        <div
          style={{
            position: 'absolute',
            top: rect?.top,
            left: rect?.left,
            width: rect?.width,
            height: rect?.height,
            pointerEvents: 'none',
          }}
        />
      </Popover.Anchor>
      <Popover.Portal>
        <Popover.Content onPointerDownOutside={close} collisionPadding={20}>
          <SpellcheckContent
            addToDictionaryText={addToDictionaryText}
            ignoreText={ignoreText}
            hunspell={hunspell}
            word={word}
            onSelectText={onSelectText}
            onAddToDictionary={onAddToDictionary}
            onIgnore={onIgnore}
          />
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
};
