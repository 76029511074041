import {
  HAST,
  SAST,
  withHtmlDeserializer,
  withHtmlSerializer,
  withMarkdownSerializer,
} from '@meisterlabs/slate-serializer';

import { getImageUrl } from '../utils/urls';
import { MMBlock as SlateMMBLock } from '../types';

interface MMBlock extends SAST.Block {
  type: 'mindmeister';
  properties: {
    mapId: string;
    mapRootId: string;
    mapTitle: string;
    scale?: string;
    x?: string;
    y?: string;
    heightDelta?: string;
  };
}

export const withMindMeisterSerializers = function (baseDomain: string) {
  withHtmlSerializer<MMBlock>(
    (_, node) => node.type === 'mindmeister',
    (_, node) => {
      return {
        type: 'element',
        tagName: 'div',
        properties: {
          dataMindmapBlock: 'true',
          dataId: node.properties.mapId,
          dataRootId: node.properties.mapRootId,
          dataTitle: node.properties.mapTitle,
          dataScale: node.properties.scale,
          dataX: node.properties.x,
          dataY: node.properties.y,
          dataHeightDelta: node.properties.heightDelta,
        },
        children: [],
      };
    }
  );

  withHtmlDeserializer<HAST.Element>(
    (_, node) => node.tagName === 'div' && !!node.properties?.dataMindmapBlock,
    (_, node) => {
      const properties = {
        mapId: node.properties?.dataId,
        mapRootId: node.properties?.dataRootId,
        mapTitle: node.properties?.dataTitle,
        scale: node.properties?.dataScale,
        x: node.properties?.dataX,
        y: node.properties?.dataY,
        heightDelta: node.properties?.dataHeightDelta,
      };

      // Remove undefined properties to avoid unnecessary properties in the editor
      Object.keys(properties).forEach(
        (key) => properties[key] === undefined && delete properties[key]
      );

      return {
        type: 'mindmeister',
        properties: properties,
        children: [
          {
            type: 'text',
            value: '',
          },
        ],
      };
    }
  );

  withMarkdownSerializer<MMBlock>(
    (_, node) => node.type === 'mindmeister' && !!node.properties.mapId,
    (_, node) => {
      const simulatedSlateBlock = {
        type: 'mindmeister',
        ...node.properties,
        children: [
          {
            text: '',
          },
        ],
      } as SlateMMBLock;

      return {
        type: 'paragraph',
        children: [
          {
            type: 'text',
            value: `![${node.properties.mapTitle}](${getImageUrl(baseDomain, simulatedSlateBlock, false)})`,
          },
        ],
      };
    }
  );
};
