import * as Slate from '@meisterlabs/slate';

import * as Sast from '../sast';

export interface Options {
  generateKey: () => string;
}

export const toSlate = function (root: Sast.Root, options: Options) {
  const createSlateNode = function (node: Sast.Block): Slate.BlockElement {
    let children = node.children.map(walk);

    // It is possible that the children array is empty, Slate does not like that and will crash
    // so in this case we add an empty text node
    if (!Array.isArray(children) || children.length === 0) {
      children = [
        {
          text: '',
        },
      ];
    }

    return {
      type: node.type,
      key: options.generateKey(),
      children,
      ...node.properties,
    };
  };

  const createSlateText = function (node: Sast.TextContent): Slate.Text {
    if (Sast.isMark(node)) {
      return {
        [node.name]: node.value,
        ...createSlateText(node.children[0]),
      };
    }

    return {
      text: node.value,
    };
  };

  const walk = function (node: Sast.Content): Slate.BlockElement | Slate.Text {
    if (Sast.isText(node) || Sast.isMark(node)) return createSlateText(node);
    return createSlateNode(node);
  };

  const walkNodes = function (
    node: Sast.Content[]
  ): (Slate.BlockElement | Slate.Text)[] {
    return node.map(walk);
  };

  const nodes = root.children;
  const slateNodes = walkNodes(nodes);

  return slateNodes;
};
