import {
  HAST,
  MDAST,
  SAST,
  withHtmlDeserializer,
  withHtmlSerializer,
  withMarkdownDeserializer,
  withMarkdownSerializer,
} from '@meisterlabs/slate-serializer';

interface CodeBlock extends SAST.Block {
  type: 'code';
  properties: {
    language: string;
  };
}

/**
 * This function is used to add the serializers to the editor for the code block.
 * Has to be called in the `withSerializerEditor` setup function.
 */
export const withCodeSerializers = function () {
  withHtmlSerializer<CodeBlock>(
    (_, element) => SAST.isBlock(element) && element.type === 'code',
    ({ multiple }, element) => {
      const properties = element.properties?.language
        ? { dataLanguage: element.properties.language }
        : {};

      return {
        type: 'element',
        tagName: 'pre',
        children: [
          {
            type: 'element',
            tagName: 'code',
            properties,
            children: multiple<SAST.Content, HAST.ElementContent>(
              element.children
            ),
          },
        ],
      };
    }
  );

  withHtmlDeserializer<HAST.Element, CodeBlock>(
    (_, node) =>
      node.tagName === 'pre' &&
      node.children.length === 1 &&
      'tagName' in node.children[0] &&
      node.children[0].tagName === 'code' &&
      'properties' in node.children[0],
    ({ multiple }, node) => {
      const code = node.children[0] as HAST.Element;

      return {
        type: 'code',
        properties: {
          language: (code.properties.dataLanguage as string) ?? 'none',
        },
        children: multiple<HAST.ElementContent, SAST.Content>(code.children),
      };
    }
  );

  withMarkdownSerializer<CodeBlock>(
    (_, element) => SAST.isBlock(element) && element.type === 'code',
    (_, element) => {
      const hasLanguage =
        element.properties.language && element.properties.language !== 'none';
      const lang = hasLanguage ? element.properties.language : '';

      return {
        type: 'code',
        lang: lang,
        value: SAST.string(element),
      };
    }
  );

  withMarkdownDeserializer<MDAST.Code, CodeBlock>(
    (_, node) => node.type === 'code',
    (_, node) => {
      return {
        type: 'code',
        properties: {
          language: node.lang ?? 'none',
        },
        children: [
          {
            type: 'text',
            value: node.value,
          },
        ],
      };
    }
  );
};
