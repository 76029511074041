import * as Y from 'yjs';
import type { Node } from 'slate';
import { fromUint8Array } from 'js-base64';
import { slateNodesToInsertDelta } from '@slate-yjs/core';

export const slateNodesToYjsStateUpdate = function (nodes: Node[]): string {
  const yDoc = new Y.Doc();
  const sharedType = yDoc.get('content', Y.XmlText) as Y.XmlText;

  sharedType.applyDelta(slateNodesToInsertDelta(nodes));

  return fromUint8Array(Y.encodeStateAsUpdate(yDoc));
};
