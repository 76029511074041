import * as SAST from '../sast';

import { SerializerEditorContext } from '../SerializerEditorContext';
import { Check, Handler } from '../transformers/default';
import { HastNode, MdastNode } from '../types';

/**
 * Add a serializer for Html
 */
export const withHtmlSerializer = function <
  T extends SAST.Node,
  G extends HastNode = HastNode,
  P extends SAST.Node = T,
>(check: Check<T, G, P>, handler: Handler<T, G, P>) {
  const ctx = SerializerEditorContext.get();

  ctx.serializers.sastToHast.push([check, handler]);
};

/**
 * Add a serializer for Markdown
 */
export const withMarkdownSerializer = function <
  T extends SAST.Node,
  G extends MdastNode = MdastNode,
  P extends SAST.Node = T,
>(check: Check<T, G, P>, handler: Handler<T, G, P>) {
  const ctx = SerializerEditorContext.get();

  ctx.serializers.sastToMdast.push([check, handler]);
};

/**
 * Add a deserializer for Html
 */
export const withHtmlDeserializer = function <
  T extends HastNode,
  G extends SAST.Node = SAST.Node,
  P extends HastNode = T,
>(check: Check<T, G, P>, handler: Handler<T, G, P>) {
  const ctx = SerializerEditorContext.get();

  ctx.serializers.hastToSast.push([check, handler]);
};

/**
 * Add a deserializer for Markdown
 */
export const withMarkdownDeserializer = function <
  T extends MdastNode,
  G extends SAST.Node = SAST.Node,
  P extends MdastNode = T,
>(check: Check<T, G, P>, handler: Handler<T, G, P>) {
  const ctx = SerializerEditorContext.get();

  ctx.serializers.mdastToSast.push([check, handler]);
};
