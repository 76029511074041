import React, { useCallback, useMemo, useReducer } from 'react';
import { Editor, NodeEntry, Range, Text } from 'slate';
import {
  Editable,
  RenderElementProps,
  RenderLeafProps,
  RenderPlaceholderProps,
  Slate,
  withReact,
} from 'slate-react';
import classNames from 'classnames';
import { BlockElement, isBlockElement } from '@meisterlabs/slate';

import { EditableContext } from './EditableContext';
import {
  Attributes,
  BlockRenderElementProps,
  EditableContextState,
  ElementRenderer,
  LeafRenderProps,
} from './types';
import { DefaultElement } from './DefaultElement';
import { DefaultLeaf } from './DefaultLeaf';

import './style.css';
import { LoadingContextProvider } from './components/LoadingContext';

const findAndGetOr = function <Input, Output>(
  list: Array<Input>,
  checkFn: (item: Input) => false | Output,
  fallbackValue: Output
) {
  for (const item of list) {
    const result = checkFn(item);

    if (result) return result;
  }

  return fallbackValue;
};

const createOnKeyDown = function (
  ctx: EditableContextState
): React.KeyboardEventHandler<HTMLDivElement> {
  return function (event: React.KeyboardEvent) {
    for (const onKeyDown of ctx.onKeyDowns) {
      const shouldContinue = onKeyDown(event, ctx.editor);

      if (shouldContinue === false) return;
    }
  };
};

const createOnDrop = function (
  ctx: EditableContextState
): React.DragEventHandler {
  return function (event: React.DragEvent) {
    for (const onDrop of ctx.onDrops) {
      const shouldContinue = onDrop(event, ctx.editor);

      if (shouldContinue === false) return true;
    }
  };
};

const createOnPaste = function (
  ctx: EditableContextState
): React.ClipboardEventHandler {
  return function (event: React.ClipboardEvent) {
    for (const onPaste of ctx.onPastes) {
      const shouldContinue = onPaste(event, ctx.editor);

      if (shouldContinue === false) return true;
    }
  };
};

const createOnCopy = function (
  ctx: EditableContextState
): React.ClipboardEventHandler {
  return function (event: React.ClipboardEvent) {
    for (const onCopy of ctx.onCopies) {
      const shouldContinue = onCopy(event, ctx.editor);

      if (shouldContinue === false) return true;
    }
  };
};

const createOnCut = function (
  ctx: EditableContextState
): React.ClipboardEventHandler {
  return function (event: React.ClipboardEvent) {
    for (const onCut of ctx.onCuts) {
      const shouldContinue = onCut(event, ctx.editor);

      if (shouldContinue === false) return true;
    }
  };
};

const createDecorator = function (ctx: EditableContextState) {
  return function (entry: NodeEntry) {
    const decorations: Array<Range> = [];

    for (const decorator of ctx.decorators) {
      const result = decorator(entry, ctx.editor);

      if (result) Array.prototype.push.apply(decorations, result);
    }

    return decorations;
  };
};

const createNormalizer = function (ctx: EditableContextState) {
  const { normalizeNode } = ctx.editor;

  ctx.editor.normalizeNode = function (entry) {
    const [element, path] = entry;

    if (!isBlockElement(element)) {
      normalizeNode(entry);

      return;
    }

    for (const normalizer of ctx.elementNormalizers) {
      const check = normalizer[0](element, path, ctx.editor);

      if (check) {
        const shouldContinue = normalizer[1](element, path, ctx.editor);

        if (shouldContinue === false) return;
      }
    }

    // Fall back to the original `normalizeNode` to enforce other constraints.
    normalizeNode(entry);
  };
};

const createInsertText = function (ctx: EditableContextState) {
  const { insertText } = ctx.editor;

  ctx.editor.insertText = function (text, options) {
    for (const inserter of ctx.insertTexts) {
      const result = inserter(ctx.editor, text);

      if (result === false) return;
    }

    // Fall back to the original `insertText` to enforce other constraints.
    insertText(text, options);
  };
};

const createInsertBreak = function (ctx: EditableContextState) {
  const { insertBreak } = ctx.editor;

  ctx.editor.insertBreak = function () {
    for (const inserter of ctx.insertBreaks) {
      const result = inserter(ctx.editor);

      if (result === false) return;
    }

    // Fall back to the original `insertText` to enforce other constraints.
    insertBreak();
  };
};

const createDeleteBackwards = function (ctx: EditableContextState) {
  const { deleteBackward } = ctx.editor;

  ctx.editor.deleteBackward = function (unit) {
    for (const deleter of ctx.deleteBackwards) {
      const result = deleter(ctx.editor, unit);

      if (result === false) return;
    }

    // Fall back to the original `insertText` to enforce other constraints.
    deleteBackward(unit);
  };
};

const createDeleteFragment = function (ctx: EditableContextState) {
  const { deleteFragment } = ctx.editor;

  ctx.editor.deleteFragment = function (options) {
    for (const deleter of ctx.deleteFragments) {
      const result = deleter(ctx.editor, options);

      if (result === false) return;
    }

    // Fall back to the original `deleteFragment`
    deleteFragment(options);
  };
};

const createInsertDatas = function (ctx: EditableContextState) {
  const { insertData } = ctx.editor;

  ctx.editor.insertData = function (data) {
    for (const insertData of ctx.insertDatas) {
      const result = insertData(ctx.editor, data);

      if (result === false) return;
    }

    // Fall back to the original `insertText` to enforce other constraints.
    insertData(data);
  };
};

const createRenderElement = function (ctx: EditableContextState) {
  return function SlateElement({
    attributes: slateAttributes,
    children,
    element,
  }: BlockRenderElementProps<BlockElement>) {
    const Element = findAndGetOr<
      ElementRenderer<BlockElement>,
      React.FC<BlockRenderElementProps<BlockElement>>
    >(
      ctx.elementRenderers,
      ([check, Element]) => check(element, ctx.editor) && Element,
      ctx.DefaultElement
    );

    const className = classNames(
      `element element-${element.type}`,
      ctx.elementClassNames.map((middleware) => middleware(element))
    );

    const customAttributes = ctx.elementAttributes.reduce<Attributes>(
      function (attributes, middleware) {
        return middleware(attributes, element, ctx.editor) ?? attributes;
      },
      {
        className,
        'data-key': element.key,
      }
    );

    const props = {
      element,
      children,
      attributes: {
        ...customAttributes,
        ...slateAttributes,
      },
    };

    const FinalElement = useMemo(() => {
      return ctx.elementWrappers.reduce(function (Element, Wrapper) {
        return function WrappedElement(props) {
          return (
            <Wrapper {...props}>
              <Element {...props} />
            </Wrapper>
          );
        };
      }, Element);
    }, [Element]);

    return (
      <div className={`element-root`} data-key={props.element.key}>
        <FinalElement {...props} />
      </div>
    );
  };
};

const createRenderLeaf = function (ctx: EditableContextState) {
  const { editor } = ctx;

  return function SlateLeaf({
    attributes: slateAttributes,
    children,
    leaf,
    text,
  }: RenderLeafProps) {
    const Leaf = findAndGetOr(
      ctx.leafRenderers,
      ([check, Leaf]) => check(leaf) && Leaf,
      ctx.DefaultLeaf
    );

    const tagName = findAndGetOr(
      ctx.leafTagNames,
      (middleware) => middleware(leaf),
      'span'
    );

    const className = classNames(
      ctx.leafClassNames.map((middleware) => middleware(leaf, text, editor))
    );

    const customAttributes = ctx.leafAttributes.reduce<Attributes>(
      function (attributes, middleware) {
        return middleware(attributes, leaf) ?? attributes;
      },
      { className }
    ) as Attributes & { className: string };

    const props = {
      leaf,
      children,
      tagName,
      text,
      attributes: {
        ...customAttributes,
        ...slateAttributes,
      },
    };

    const FinalLeaf = useMemo(() => {
      return ctx.leafWrappers.reduce(function (Leaf, Wrapper) {
        return function WrappedLeaf(props: LeafRenderProps<Text>) {
          return (
            <Wrapper {...props}>
              <Leaf {...props} />
            </Wrapper>
          );
        };
      }, Leaf);
    }, [Leaf, text]);

    return React.createElement<LeafRenderProps<Text>>(FinalLeaf, props);
  };
};

const renderTopElements = function (ctx: EditableContextState) {
  return ctx.topElements.map((Element, index) => <Element key={index} />);
};

const createIsVoid = function (ctx: EditableContextState) {
  const { isVoid } = ctx.editor;

  ctx.editor.isVoid = (element) => {
    if (!isBlockElement(element)) return isVoid(element);

    for (const isVoidFn of ctx.isVoids) {
      if (isVoidFn(element, ctx.editor)) return true;
    }

    return isVoid(element);
  };
};

const createIsInline = function (ctx: EditableContextState) {
  const { isInline } = ctx.editor;

  ctx.editor.isInline = (element) => {
    if (!isBlockElement(element)) return isInline(element);

    for (const isInlineFn of ctx.isInlines) {
      if (isInlineFn(element, ctx.editor)) return true;
    }

    return isInline(element);
  };
};

const useCreateEditableContext = function ({ editor, forceUpdate, setupFn }) {
  return useMemo(() => {
    const ctx = EditableContext.create({
      editor,
      DefaultLeaf,
      DefaultElement,

      didSetup: false,
      readOnly: false,

      elementWrappers: [],
      leafWrappers: [],
      editableWrappers: [],
      decorators: [],
      onKeyDowns: [],
      onDrops: [],
      onPastes: [],
      onCopies: [],
      onCuts: [],
      onReady: [],
      topElements: [],
      isVoids: [],
      isInlines: [],

      leafTagNames: [],
      leafClassNames: [],
      leafAttributes: [],
      leafRenderers: [],

      elementClassNames: [],
      elementAttributes: [],
      elementRenderers: [],
      elementNormalizers: [],

      insertTexts: [],
      insertBreaks: [],
      deleteBackwards: [],
      deleteFragments: [],
      insertDatas: [],

      forceUpdate: () => forceUpdate(),
    });

    setupFn();
    EditableContext.done();

    createNormalizer(ctx);
    createIsVoid(ctx);
    createIsInline(ctx);
    createInsertText(ctx);
    createInsertBreak(ctx);
    createDeleteBackwards(ctx);
    createDeleteFragment(ctx);
    createInsertDatas(ctx);

    return ctx;
  }, [editor]);
};

export interface EditableProps {
  editor: Editor;
  placeholder?: string;
  renderPlaceholder?: (props: RenderPlaceholderProps) => JSX.Element;
}

export const createEditable = function (setupFn: () => void) {
  return React.memo<EditableProps>(function SlateEditable(
    props: EditableProps
  ) {
    const { editor: baseEditor, placeholder, renderPlaceholder } = props;

    const isMounted = React.useRef(false);
    const [, forceUpdate] = useReducer((x) => x + 1, 0);
    const editor = useMemo(() => withReact(baseEditor), [baseEditor]);

    const forceUpdateFn = useCallback(() => {
      if (isMounted.current) forceUpdate();
    }, []);

    React.useEffect(() => {
      isMounted.current = true;

      return () => {
        isMounted.current = false;
      };
    }, []);

    const ctx = useCreateEditableContext({
      editor,
      forceUpdate: forceUpdateFn,
      setupFn,
    });

    const decorator = useMemo(() => createDecorator(ctx), []);
    const renderElement = useMemo(() => createRenderElement(ctx), []) as (
      props: RenderElementProps
    ) => JSX.Element;
    const renderLeaf = useMemo(() => createRenderLeaf(ctx), []);
    const topElements = useMemo(() => renderTopElements(ctx), []);

    // Events
    const onKeyDown = useCallback(createOnKeyDown(ctx), []);
    const onDrop = useCallback(createOnDrop(ctx), []);
    const onPaste = useCallback(createOnPaste(ctx), []);
    const onCopy = useCallback(createOnCopy(ctx), []);
    const onCut = useCallback(createOnCut(ctx), []);

    const MainEditableWrapper: React.FC = useMemo(() => {
      const EditableWrapper = function ({ children }) {
        return ctx.editableWrappers.reduce(function (Last, Wrapper) {
          return <Wrapper>{Last}</Wrapper>;
        }, children);
      };

      return EditableWrapper;
    }, []);

    return (
      <LoadingContextProvider onReady={ctx.onReady}>
        <Slate editor={ctx.editor} initialValue={ctx.editor.children}>
          {topElements}
          <MainEditableWrapper>
            <Editable
              spellCheck={false}
              readOnly={ctx.readOnly}
              decorate={decorator}
              onKeyDown={onKeyDown}
              renderElement={renderElement}
              renderLeaf={renderLeaf}
              placeholder={placeholder}
              renderPlaceholder={renderPlaceholder}
              onDrop={onDrop}
              onPaste={onPaste}
              onCopy={onCopy}
              onCut={onCut}
            />
          </MainEditableWrapper>
        </Slate>
      </LoadingContextProvider>
    );
  });
};
