// Reference implementation from Plate:
// https://github.com/udecode/plate/blob/main/packages/node-id/src/withNodeId.ts
import { produce } from 'immer';
import { Editor, Text } from 'slate';

import { EditorContext } from '../EditorContext';
import { findNodeByKey, generateKey, isValidKey } from '../utils';
import { BlockElement } from '../types';

const nodeRequiresKey = (node: BlockElement) =>
  node.type != null && !Text.isText(node);
const nodeHasValidKey = (node: BlockElement) => isValidKey(node.key);

const nodeHasDuplicatedKey = function (
  editor: Editor,
  node: BlockElement
): boolean {
  return !!findNodeByKey(editor, node.key);
};

const shouldAddKey = function (node: BlockElement): boolean {
  if (!nodeRequiresKey(node)) return false;
  if (nodeHasValidKey(node)) return false;
  return true;
};

const shouldRemoveKey = function (editor: Editor, node: BlockElement): boolean {
  if (nodeRequiresKey(node) && !nodeHasValidKey(node)) return true;
  if (nodeRequiresKey(node) && nodeHasDuplicatedKey(editor, node)) return true;
  if (!nodeRequiresKey(node) && nodeHasValidKey(node)) return true;
  return false;
};

const removeKeyIfNeeded = function (editor: Editor, node: BlockElement) {
  if (!shouldRemoveKey(editor, node)) return;

  delete node.key;
};

const addKeyIfNeeded = function (node: BlockElement) {
  if (!shouldAddKey(node)) return;

  node.key = generateKey();
};

const addKeysDeeplyIfNeeded = function (node: BlockElement) {
  addKeyIfNeeded(node);
  node.children?.forEach((node) => addKeysDeeplyIfNeeded(node as BlockElement));
};

export const withKeysNormalizer = function () {
  const { editor } = EditorContext.get();
  const { apply, insertNode, insertNodes } = editor;

  editor.insertNode = function (node) {
    removeKeyIfNeeded(editor, node as BlockElement);
    addKeyIfNeeded(node as BlockElement);

    insertNode(node);
  };

  editor.insertNodes = function (_nodes, options) {
    const nodes = Array.isArray(_nodes) ? _nodes : [_nodes];

    insertNodes(
      nodes.map((node) => {
        removeKeyIfNeeded(editor, node as BlockElement);
        addKeyIfNeeded(node as BlockElement);

        return node;
      }),
      options
    );
  };

  editor.apply = function (operation) {
    if (operation.type === 'insert_node') {
      operation = produce(operation, (operation) => {
        removeKeyIfNeeded(editor, operation.node as BlockElement);
        addKeysDeeplyIfNeeded(operation.node as BlockElement);
      });

      return apply(operation);
    }

    if (operation.type === 'split_node') {
      operation = produce(operation, (operation) => {
        removeKeyIfNeeded(editor, operation.properties as BlockElement);
        addKeyIfNeeded(operation.properties as BlockElement);
      });

      return apply(operation);
    }

    // if (operation.type === 'set_node') {
    //     operation = produce(operation, operation => {
    //         removeKeyIfNeeded(editor, operation.newProperties as BlockElement);
    //         addKeyIfNeeded(operation.newProperties as BlockElement);
    //     });

    //     return apply(operation);
    // }

    return apply(operation);
  };
};
