import React from 'react';
import { removeUnusedProperties } from '@meisterlabs/slate';
import {
  BlockRenderElementProps,
  withElementNormalizer,
  withElementRenderer,
} from '@meisterlabs/slate-react';

import { HeaderBlock, isHeaderBlock } from '../types';

const VALID_HEADING_TAGS = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] as const;
type HeadingTag = (typeof VALID_HEADING_TAGS)[number];

const Component: React.FC<BlockRenderElementProps<HeaderBlock>> = function (
  props
) {
  const { attributes, children, element } = props;

  const Tag = `h${element.level}` as HeadingTag;

  return (
    <Tag {...attributes} id={element.key}>
      {children}
    </Tag>
  );
};

/**
 * This middleware implements the Header Block, with 6 levels.
 */
export const withHeader = function () {
  withElementRenderer<HeaderBlock>(
    (element) => isHeaderBlock(element),
    Component
  );

  withElementNormalizer<HeaderBlock>(
    (element) => isHeaderBlock(element),
    (element, path, editor) =>
      removeUnusedProperties(editor, element, path, ['level'])
  );
};
