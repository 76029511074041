import React, { useState, useRef, useEffect } from 'react';
import { Icon, Image, Text, View } from '@meisterlabs/knightrider';
import { colors, fonts, rgba } from '@meisterlabs/ui';
import { Image as ImageIcon } from '@meisterlabs/svgs/FileFormats';
import { Resize } from '@meisterlabs/svgs/Tint';
import { Resizer } from '@meisterlabs/slate-shared-components';
import { LoadingContextConsumer } from '@meisterlabs/slate-react';

interface Props {
  name?: string;
  extension?: string;
  url?: string;
  width?: string;
  minWidth?: number;
  readOnly?: boolean;
  onResize?: (width: string) => void;
  onClick?: () => void;
}

const styles = {
  root: {
    flexGrow: 1,
    flexShrink: 1,
    cursor: 'default',
    userSelect: 'none',
  },
  wrapper: {
    flexShrink: 1,
    borderRadius: 10,
    overflow: 'hidden',
    position: 'relative',
    transition: 'box-shadow 200ms ease-in-out',
  },
  image: {
    width: '100%',
    cursor: 'pointer',
  },
  footer: {
    root: {
      height: 44,
      position: 'absolute',
      bottom: 0,
      left: 0,
      right: 0,
      alignItems: 'center',
      paddingLeft: 15,
      paddingRight: 15,
      backgroundColor: colors.grey100,
      transition: 'transform 200ms ease-in-out',
      transform: 'translateY(44px)',
    },
    icon: {
      width: 24,
      height: 24,
    },
    title: {
      ...fonts.m.regular,
      flexGrow: 1,
      flexShrink: 1,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    meta: {
      ...fonts.s.bold,
      color: colors.grey500,
      paddingLeft: 15,
      textTransform: 'uppercase',
      whiteSpace: 'nowrap',
    },
    resizeHandler: {
      position: 'absolute',
      bottom: 4,
      right: 4,
      cursor: 'ew-resize',
    },
    spacer: {
      width: 15,
    },
    resizeIcon: {
      width: 12,
      height: 12,
      color: rgba.black(0.2),
    },
  },
};

interface FooterProps {
  name: string;
  extension: string;
  readOnly: boolean;
  show: boolean;
}

const Footer: React.VFC<FooterProps> = function (props) {
  const { name, extension, readOnly, show } = props;

  return (
    <View
      style={{
        ...styles.footer.root,
        transform: show ? 'translateY(0)' : 'translateY(44px)',
      }}
    >
      <Icon style={styles.footer.icon}>
        <ImageIcon />
      </Icon>
      <View style={styles.footer.spacer} />
      <Text style={styles.footer.title}>{name}</Text>
      <View style={styles.footer.spacer} />
      <View>
        <Text style={styles.footer.meta}>{extension}</Text>
      </View>
      {!readOnly && (
        <View style={styles.footer.resizeHandler}>
          <Resizer.Handle>
            <Icon style={styles.footer.resizeIcon}>
              <Resize />
            </Icon>
          </Resizer.Handle>
        </View>
      )}
    </View>
  );
};

const ImageView: React.VFC<Props> = function (props) {
  const {
    name = '',
    extension = '',
    url = '',
    width: initialWidth = null,
    minWidth = 150,
    readOnly = false,
    onResize = () => null,
    onClick = () => null,
  } = props;

  const imageRef = useRef(null);
  const [isHovered, setIsHovered] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const img = imageRef.current;

    img.onload = () => setIsLoading(false);
    img.onerror = () => setIsLoading(false);
    img.onabort = () => setIsLoading(false);
  }, []);

  const handleMouseOver = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);

  return (
    <Resizer.Horizontal
      initialWidth={initialWidth}
      minWidth={minWidth}
      setNewWidth={onResize}
    >
      {({
        width,
        rootRefCallback,
        targetRefCallback,
        naturalWidthRefCallback,
        isResizing,
      }) => (
        <View style={styles.root} onElement={rootRefCallback}>
          <LoadingContextConsumer isLoading={isLoading} />
          <View
            onElement={targetRefCallback}
            onMouseOver={handleMouseOver}
            onMouseLeave={handleMouseLeave}
            style={{
              ...styles.wrapper,
              width,
              boxShadow:
                isHovered || isResizing
                  ? `0 0 0 1px ${rgba.grey700(0.1)}`
                  : `0 0 0 1px ${rgba.white(0.1)}`,
            }}
          >
            <div>
              <Image
                src={url}
                onElement={(elem) => {
                  imageRef.current = elem;
                  naturalWidthRefCallback(elem);
                }}
                style={styles.image}
                onClick={onClick}
              />
            </div>
            <Footer
              name={name}
              extension={extension}
              readOnly={readOnly}
              show={isHovered && !isResizing}
            />
          </View>
        </View>
      )}
    </Resizer.Horizontal>
  );
};

export default ImageView;
