import React from 'react';
import { RenderElementProps } from 'slate-react';

export const DefaultElement: React.FC<RenderElementProps> = function ({
  attributes,
  children,
  element,
}) {
  if (process.env.NODE_ENV === 'production') {
    return <p {...attributes}>{children}</p>;
  }

  return (
    <p
      {...attributes}
      contentEditable={false}
      style={{
        fontFamily: 'monospace',
        color: '#F81616',
        backgroundColor: '#FFE0E0',
        padding: 15,
        borderRadius: 5,
        overflowX: 'auto',
        overflowY: 'hidden',
      }}
    >
      <b>Missing renderer for element:</b>
      <br />
      <br />
      {JSON.stringify(element, null, 2)}
    </p>
  );
};
