import React from 'react';
import { ScrollView, View } from '@meisterlabs/knightrider';
import { PopoverHeader } from '@meisterlabs/ui';
import { MixedGrid } from '@meisterlabs/react-grid';
import Keyboard from '@meisterlabs/react-keyboard';

export interface ListItem {
  id: string;
  type: string;
  isSelected: boolean;
}

const style = {
  root: {
    flexGrow: 1,
    flexShrink: 1,
    flexDirection: 'column',
    maxHeight: 'var(--radix-popover-content-available-height)',
  },
  header: {
    marginTop: 10,
    marginBottom: 10,
    paddingLeft: 20,
    paddingRight: 20,
  },
  iconGrid: {
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 10,
    paddingBottom: 10,
    justifyContent: 'center',
  },
  colorGrid: {
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 10,
    paddingBottom: 20,
    justifyContent: 'center',
  },
};

const gridWidth = 280;
const spaceWidth = 4;
const itemWidth = gridWidth / 4 - spaceWidth;
const colorItemWidth = gridWidth / 7 - spaceWidth;

// This context is needed to pass the icon and color list to the IconSelector component through a context
// because the IconSelectorPopover component is wrapped in a Popover component
// and the Popover component is rendered inside a Portal which isn't rerendered when the icon and color list changes
export const IconSelectorContext = React.createContext<{
  iconList: ListItem[];
  colorList: ListItem[];
}>({
  iconList: [],
  colorList: [],
});

export interface IconSelectorContentProps {
  iconSet: Record<string, React.FC>;
  colorSet: Record<string, string>;
  iconPickerHeader: string;
  close: () => void;
  onSelectColor: (color: string) => void;
  onSelectIcon: (icon: string) => void;
}

export const IconSelectorPopoverContent: React.VFC<IconSelectorContentProps> =
  function (props) {
    const {
      iconPickerHeader,
      iconSet,
      colorSet,
      close,
      onSelectColor,
      onSelectIcon,
    } = props;

    const { colorList, iconList } = React.useContext(IconSelectorContext);

    const onClickColor = (color) => onSelectColor(color);
    const onClickIcon = (icon) => onSelectIcon(icon);

    const bindings = {
      esc: close,
      backspace: close,
      enter: close,
    };

    return (
      <Keyboard bindings={bindings}>
        <View style={style.root}>
          <View style={style.header}>
            <PopoverHeader
              renderLeft={() => null}
              text={iconPickerHeader}
              onClickIconRight={close}
            />
          </View>
          <View style={style.colorGrid}>
            <MixedGrid
              itemSet={colorSet}
              itemList={colorList}
              gridWidth={gridWidth}
              spaceWidth={spaceWidth}
              itemWidth={colorItemWidth}
              onClickItem={onClickColor}
            />
          </View>
          <ScrollView autoHeight autoHeightMax='100%'>
            <View style={style.iconGrid}>
              <MixedGrid
                itemSet={iconSet}
                itemList={iconList}
                gridWidth={gridWidth}
                spaceWidth={spaceWidth}
                itemWidth={itemWidth}
                onClickItem={onClickIcon}
              />
            </View>
          </ScrollView>
        </View>
      </Keyboard>
    );
  };
