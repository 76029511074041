import { Editor } from '@meisterlabs/slate';

import { StyledText } from '../types';

export const toggleMark = (editor: Editor, name: string) => {
  const mark = Editor.marks(editor);

  if ((mark as StyledText)?.[name]) editor.removeMark(name);
  else editor.addMark(name, true);
};
