import React, { useMemo } from 'react';
import fuzzySort from 'fuzzysort';
import { Transforms } from '@meisterlabs/slate';
import { useSlateStatic } from '@meisterlabs/slate-react';
import { PopoverHeader } from '@meisterlabs/ui';
import { colors } from '@meisterlabs/colors';
import { StandaloneList, style as ListStyle } from '@meisterlabs/react-lists';

import { CodeBlock } from '../types';
import { languagesAsItems } from '../utils/languageItems';

export interface LanguageContentProps {
  blockKey: string;
  language: string;
  noneItemText: string;
  popoverHeaderText: string;
  onLanguageChanged?: (language: string) => void;
  close: () => void;
}

const style = {
  root: {
    display: 'flex',
    flexGrow: 1,
    flexShrink: 1,
    flexDirection: 'column',
    maxHeight: 'var(--radix-popover-content-available-height)',
  },
  header: {
    display: 'flex',
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 10,
    paddingBottom: 10,
  },
  List: ListStyle({
    itemMarginHorizontal: 10,
    headerMarginHorizontal: 10,
    paddingTop: 10,
    paddingBottom: 10,
    selectedColor: colors.sky,
  }),
} as const;

export const LanguagePopoverContent: React.VFC<LanguageContentProps> =
  function (props) {
    const {
      close,
      blockKey,
      language,
      noneItemText,
      popoverHeaderText,
      onLanguageChanged,
    } = props;

    const [filter, setFilter] = React.useState('');
    const editor = useSlateStatic();

    const onSelect = (language) => {
      onLanguageChanged?.(language);

      Transforms.setNodes<CodeBlock>(
        editor,
        { language },
        { at: [], match: (n: CodeBlock) => n.key === blockKey }
      );

      close();
    };

    const onInputValueSettle = (filter) => {
      setFilter(filter);
    };

    const filteredItems = useMemo(() => {
      const items = languagesAsItems({
        noneItemText,
        selectedLanguage: language,
      });

      return fuzzySort
        .go(filter, items, { key: 'content', threshold: -1000, all: true })
        .map(({ obj }) => obj);
    }, [filter, language]);

    return (
      <div style={style.root}>
        <div style={style.header}>
          <PopoverHeader
            renderLeft={() => null}
            text={popoverHeaderText}
            onClickIconRight={close}
          />
        </div>
        <StandaloneList
          selectFirst
          items={filteredItems}
          onEnter={onSelect}
          onClickItem={onSelect}
          listProps={{
            maxItemsHeight: '100%',
            style: style.List,
            onEscape: close,
            onInputValueSettle: onInputValueSettle,
          }}
        />
      </div>
    );
  };
