import { withOnKeyDown } from '@meisterlabs/slate-react';

/**
 * This middleware implements that on `shift+enter` just a new line is added instead of a new Block.
 */
export const withSoftBreak = function () {
  withOnKeyDown((event, editor) => {
    if (event.key !== 'Enter') return;
    if (event.shiftKey === false) return;

    event.preventDefault();
    event.stopPropagation();

    editor.insertText('\n');
  });
};
