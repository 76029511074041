import {
  Range,
  Node,
  Text,
  Transforms,
  Path,
  Editor,
  BlockElement,
  generateKey,
} from '@meisterlabs/slate';
import { withInsertText } from '@meisterlabs/slate-react';

import { DepthBlock } from './withBlockDepth';

export type Shortcuts = Record<string, BlockElement>;

const getShortcutKey = function (editor: Editor, text: string) {
  const { selection } = editor;

  if (!selection) return null;
  if (!Range.isCollapsed(selection)) return null;
  if (Path.hasPrevious(selection.focus.path)) return null;

  const node = Node.get(editor, selection.focus.path);

  if (!node) return null;
  if (!Text.isText(node)) return null;

  const startText = node.text.slice(0, selection.anchor.offset);
  const key = `${startText}${text}`;

  return key;
};

const shortcutAndInsert = function (
  editor: Editor,
  key: string,
  element: BlockElement
) {
  const { selection } = editor;
  const { anchor, focus } = selection;

  const parentBlock = Node.parent(editor, anchor.path) as DepthBlock;
  const depth = parentBlock.depth ?? (element as DepthBlock).depth;

  const range = {
    anchor: { path: anchor.path, offset: 0 },
    focus: { path: anchor.path, offset: key.length - 1 },
  };

  Editor.withoutNormalizing(editor, () => {
    // First delete the text so that we don't have text in void blocks
    Transforms.delete(editor, { at: range });

    // Only then set the current node to the new element type
    Transforms.setNodes(editor, {
      ...element,
      ...(depth ? { depth } : null),
    });

    if (
      Editor.isVoid(editor, { ...element, key: generateKey() } as BlockElement)
    ) {
      const newParagraph = {
        type: 'paragraph',
        key: generateKey(),
        children: [{ text: '' }],
      };

      Transforms.insertNodes(editor, newParagraph, { select: true });

      return;
    }

    const newSelection = {
      focus: { ...focus, offset: 0 },
      anchor: { ...focus, offset: 0 },
    };

    Transforms.setSelection(editor, newSelection);
  });
};

/**
 * This middleware implements that when a user types a specific shortcut, it will be replaced with a specific element.
 */
export const withMarkdownShortcuts = function (shortcutsToElement: Shortcuts) {
  withInsertText(function (editor, char) {
    const key = getShortcutKey(editor, char);
    const element = shortcutsToElement[key];

    if (!element) return;

    shortcutAndInsert(editor, key, element);
    return false;
  });
};
