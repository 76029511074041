import { useRef } from 'react';

export const useRafQueue = function <T>(callback: (value: T) => void) {
  const raf = useRef(null);
  const value = useRef(null);

  const onFrame = () => {
    const currentVal = value.current;

    raf.current = null;
    value.current = null;

    callback(currentVal);
  };

  const request = (val: T) => {
    value.current = val;

    if (raf.current) return;

    raf.current = requestAnimationFrame(onFrame);
  };

  const cancel = () => {
    if (raf.current) {
      cancelAnimationFrame(raf.current);
      raf.current = null;
    }
  };

  return { request, cancel };
};
