import React, { useMemo } from 'react';
import {
  BlockElement,
  Node,
  Range,
  removeUnusedProperties,
} from '@meisterlabs/slate';
import { colors } from '@meisterlabs/colors';

import {
  BlockRenderElementProps,
  useSelected,
  useSlateSelector,
  withElementRenderer,
  useFocused,
  useReadOnly,
  useSlateStatic,
  withElementNormalizer,
} from '@meisterlabs/slate-react';

import { ParagraphBlock, isParagraphBlock } from '../types';

export interface Options {
  placeholder?: React.ReactNode;
}

function useShowPlaceholder(element: BlockElement) {
  const editor = useSlateStatic();
  const isFocused = useFocused();
  const isSelected = useSelected();
  const isReadOnly = useReadOnly();

  const isOnlyBlock = useSlateSelector(function (editor) {
    return editor.children.length === 1;
  });

  const isEmpty = useMemo(() => {
    return Node.string(element).length === 0;
  }, [element]);

  if (isReadOnly) return false;
  if (isOnlyBlock) return isEmpty;
  if (!isSelected) return false;

  // We don't use useSlateSelector here because it will need to re-render the component anyway when the selection
  // changes to this element, so we can just check the collapse state here
  // and else it would always rerender when the collapse state of the selection changes anywhere else
  const isCollapsed = editor.selection && Range.isCollapsed(editor.selection);

  return isEmpty && isFocused && isCollapsed;
}

const Paragraph: React.FC<BlockRenderElementProps<ParagraphBlock> & Options> =
  function (props) {
    const { attributes, children, element, placeholder } = props;

    const showPlaceholder = useShowPlaceholder(element);

    return (
      <div {...attributes}>
        {placeholder != null && showPlaceholder && (
          <div
            contentEditable={false}
            style={{
              display: 'flex',
              flexDirection: 'column',
              position: 'absolute',
              top: 0,
              left: 1,
              marginLeft: 0,
              userSelect: 'none',
              pointerEvents: 'none',
              color: colors.grey500,
            }}
          >
            {placeholder}
          </div>
        )}
        {children}
      </div>
    );
  };

/**
 * This middleware adds the standard paragraph block.
 */
export const withParagraph = function (options?: Options) {
  withElementRenderer<ParagraphBlock>(
    (element) => isParagraphBlock(element),
    (props) => <Paragraph {...props} {...options} />
  );

  withElementNormalizer<ParagraphBlock>(
    (element) => isParagraphBlock(element),
    (element, path, editor) => removeUnusedProperties(editor, element, path, [])
  );
};
