import { Editor, Node, Path } from '@meisterlabs/slate';
import { DepthBlock } from '@meisterlabs/slate-react-helper';

import type { WithOrderedListOptions } from '../middlewares/withOrderedList';
import { ListItemBlock, isOrderedListItemBlock } from '../types';

const getPrevPath = function (path: Path | null) {
  if (!path) return null;

  try {
    return Path.previous(path);
  } catch {
    return null;
  }
};

export const getOrderedNumber = function (
  editor: Editor,
  path: Path,
  options: WithOrderedListOptions
) {
  const { blocksWithDepth, blocksThatAlwaysContinue } = options;

  const currentNode = Node.get(editor, path) as ListItemBlock;

  let prevPath: Path | null = path;
  let depth = parseInt(currentNode.depth ?? '0');
  const listIndex = [1];

  // eslint-disable-next-line no-cond-assign
  while ((prevPath = getPrevPath(prevPath))) {
    const prevNode = Node.get(editor, prevPath) as DepthBlock;
    const prevNodeDepth = parseInt(prevNode.depth ?? '0');

    if (blocksThatAlwaysContinue.includes(prevNode.type)) continue;

    // We need to do it this way and not just check if the previous element has a depth,
    // because the depth could be set even though the block isn't a block with depth
    // The reason is that the user can switch between blocks and the depth might not be cleaned up with
    // the normalizer
    if (!blocksWithDepth.includes(prevNode.type)) break;

    // If the previous node is not an ordered list, and it isn't a sublist, we stop
    if (!isOrderedListItemBlock(prevNode) && prevNodeDepth <= depth) break;
    if (prevNodeDepth > depth) continue;

    if (prevNodeDepth < depth) {
      listIndex.unshift(1);
      depth = prevNodeDepth;
      continue;
    }

    listIndex[0]++;
  }

  return listIndex;
};
