import {
  BlockElement,
  Editor,
  Range,
  Transforms,
  generateKey,
  isBlockElement,
} from '@meisterlabs/slate';
import { withInsertBreak } from '@meisterlabs/slate-react';

const getCurrentBlock = (editor: Editor): Array<BlockElement> => {
  const entries = Array.from(
    Editor.nodes<BlockElement>(editor, {
      at: editor.selection,
      match: isBlockElement,
    })
  );

  return entries.map(([node]) => node);
};

/**
 * This middleware prevents slate from always creating new blocks with the current block when hitting `Enter`.
 * Instead the used next block can be overwritten via the `newBlock` param.
 */
export const withNewBlock = function (
  newBlockFor: (editor: Editor, blocks: Array<BlockElement>) => object
) {
  withInsertBreak((editor) => {
    const blocks = getCurrentBlock(editor);
    const newBlock = newBlockFor(editor, blocks);
    const startSelection = Range.start(editor.selection);

    const isCursorAtBeginning =
      startSelection.path[startSelection.path.length - 1] === 0 &&
      startSelection.offset === 0;

    const nodeEntry = Editor.nodes<BlockElement>(editor, {
      at: startSelection,
      match: isBlockElement,
    }).next().value;

    if (!nodeEntry) return;

    Editor.withoutNormalizing(editor, () => {
      // If the cursor is at the beginning of a block that isn't a paragraph,
      // we insert the new block at the current position
      // basically adding a new block above the current one. Selection should be kept on the current block
      if (
        isCursorAtBeginning &&
        !Editor.isEmpty(editor, nodeEntry[0]) &&
        nodeEntry[0].type !== 'paragraph'
      ) {
        Transforms.insertNodes<BlockElement>(
          editor,
          {
            ...newBlock,
            key: generateKey(),
            children: [{ text: '' }],
          } as BlockElement,
          { at: startSelection }
        );

        return false;
      }

      Transforms.splitNodes(editor, { always: true });

      Transforms.setNodes<BlockElement>(editor, {
        ...newBlock,
        key: generateKey(),
      });
    });

    return false;
  });
};
