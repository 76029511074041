import { parser } from '@meisterlabs/slate';
import {
  HAST,
  MDAST,
  SAST,
  withHtmlDeserializer,
  withHtmlSerializer,
  withMarkdownDeserializer,
  withMarkdownSerializer,
} from '@meisterlabs/slate-serializer';

export interface WithIconSerializersOptions {
  defaultProps: {
    iconType: string;
    colorType: string;
    iconId: number;
    colorId: number;
  };
}

interface IconBlock extends SAST.Block {
  type: 'icon';
  properties: {
    iconType: string;
    colorType: string;
    iconId: number;
    colorId: number;
  };
}

export const withIconSerializers = function (
  options: WithIconSerializersOptions
) {
  withHtmlSerializer<IconBlock>(
    (_, node) => node.type === 'icon',
    ({ multiple }, node) => {
      return {
        type: 'element',
        tagName: 'blockquote',
        properties: {
          dataIconType: node.properties.iconType,
          dataIconId: node.properties.iconId,
          dataColorId: node.properties.colorId,
          dataColorType: node.properties.colorType,
        },
        children: multiple<SAST.Content, HAST.ElementContent>(
          node.children,
          node
        ),
      };
    }
  );

  withHtmlDeserializer<HAST.Element>(
    (_, node) =>
      node.tagName === 'blockquote' && !!node.properties?.dataIconType,
    ({ multiple }, node) => {
      return {
        type: 'icon',
        properties: {
          iconType:
            node.properties?.dataIconType ?? options.defaultProps.iconType,
          colorType:
            node.properties?.dataColorType ?? options.defaultProps.colorType,
          iconId:
            node.properties?.dataIconId ??
            parser.parseToString(options.defaultProps.iconId),
          colorId:
            node.properties?.dataColorId ??
            parser.parseToString(options.defaultProps.colorId),
        },
        children: multiple<HAST.ElementContent, SAST.TextContent>(
          node.children,
          node
        ),
      };
    }
  );

  withMarkdownSerializer<IconBlock>(
    (_, node) => node.type === 'icon',
    ({ multiple }, node) => {
      return {
        type: 'blockquote',
        children: [
          {
            type: 'paragraph',
            children: multiple<SAST.Content, MDAST.PhrasingContent>(
              node.children,
              node
            ),
          },
        ],
      };
    }
  );

  withMarkdownDeserializer<MDAST.Blockquote>(
    (_, node) => node.type === 'blockquote',
    ({ multiple }, node) => {
      const children = multiple<MDAST.Content, SAST.TextContent>(
        node.children,
        node
      );

      return {
        type: 'icon',
        properties: {
          iconType: options.defaultProps.iconType,
          colorType: options.defaultProps.colorType,
          iconId: parser.parseToString(options.defaultProps.iconId),
          colorId: parser.parseToString(options.defaultProps.colorId),
        },
        // Blockquotes can be nested, so we need to flatten the children
        // and convert them to text nodes if they are not already.
        children: children.flatMap((child) => {
          if (SAST.isText(child)) return child;

          return {
            type: 'text',
            value: SAST.string(child),
          };
        }),
      };
    }
  );
};
