import logo from '../assets/GoogleDrawings.png';
import { Parser } from '../types';

const re = new RegExp(
  '^https://docs.google.com/drawings/d/(.*)/(edit|preview)(.*)$'
);

const Embed: Parser = {
  logo,
  name: 'Google Drawings',
  parseUrl(url) {
    const match = url.match(re);

    if (!match) return null;

    const frameSrc = url.replace('/edit', '/preview');

    return {
      type: 'GoogleDrawings',
      url,
      frameSrc,
      frameAspectRatio: 12 / 16,
      frameBorder: true,
    };
  },
};

export default Embed;
