import {
  BaseEditor,
  Editor,
  EditorFragmentDeletionOptions,
  Location,
  Range,
  Transforms,
} from 'slate';
import { BlockElement } from '../types';
import { isBlockElement } from '../utils';

function getBlock(editor: Editor, at: Location): BlockElement | null {
  const nodes = Editor.nodes<BlockElement>(editor, {
    at,
    match: (n) => isBlockElement(n),
  });

  for (const [node] of nodes) {
    return node;
  }

  return null;
}

function deleteFragment(
  editor: Editor,
  options: EditorFragmentDeletionOptions
): boolean {
  const { selection } = editor;

  if (!selection) return false;
  if (Range.isCollapsed(selection)) return false;

  const [start, end] = Range.edges(selection);

  // If we proceed without this check, the second block is fully removed.
  if (end.offset === 0) return false;

  const block = getBlock(editor, start);
  const endBlock = getBlock(editor, end);

  if (!block) return false;

  Editor.withoutNormalizing(editor, () => {
    const props = Object.keys(endBlock).filter((k) => k !== 'key');

    // First let's remove the properties of the block where the selection ended
    Transforms.unsetNodes(editor, props, { at: end });

    // Then we delete the fragment
    Transforms.delete(editor, { reverse: options?.direction === 'backward' });

    // And finally set the properties of the block where the selection started
    Transforms.setNodes(editor, block, { at: start });

    // Sometimes the block isn't cleaned up properly, so we do it manually
    // since else we would have a block without type
    Transforms.removeNodes(editor, {
      at: [],
      match: (n: BlockElement) =>
        n.key === endBlock.key && n.type === undefined,
    });
  });

  return true;
}

/**
 * When a Fragment is deleted, leave the block type and properties of the block where the selection started
 * instead of using the block where the selection ended
 *
 * Example:
 * Slate default would be:
 *
 * [
 *   { type: 'paragraph', depth: '1', children: [{ text: 'Hello ' }] },
 *   { type: 'paragraph', depth: '2', children: [{ text: 'World' }] }
 * ]
 *
 * with a selection { anchor: { path: [0, 0], offset: 0 }, focus: { path: [1, 0], offset: 5 }
 *
 * Would result in:
 *
 * [
 *  { type: 'paragraph', depth: '2', children: [{ text: '' }] }
 * ]
 *
 * It would basically use the properties of the block where the selection ended
 *
 * With this fragment deletion, it would result in:
 *
 * [
 *  { type: 'paragraph', depth: '1', children: [{ text: '' }] }
 * ]
 *
 * Using the properties of the block where the selection started
 */
export function withDeleteFragmentLeaveAnchoredBlock(editor: Editor): Editor {
  const base = { ...editor };

  const overrides: Partial<BaseEditor> = {
    deleteFragment: (options) => {
      if (deleteFragment(editor, options)) return;
      base.deleteFragment(options);
    },
  };

  return Object.assign(editor, overrides);
}
