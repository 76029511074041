import React from 'react';
import { rgba } from '@meisterlabs/ui';
import { Image, View } from '@meisterlabs/knightrider';
import spinner from '@meisterlabs/images/spinners/128.png';
import { LoadingContextConsumer } from '@meisterlabs/slate-react';

const style = {
  root: {
    userSelect: 'none',
    cursor: 'pointer',
    display: 'flex',
    flexGrow: 1,
    flexShrink: 1,
    height: 44,
    backgroundColor: rgba.black(0.05),
    borderRadius: 10,
    paddingLeft: 20,
    paddingRight: 20,
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
  },
  image: {
    width: 16,
    height: 16,
  },
};

export const Spinner: React.VFC = function () {
  return (
    <View style={style.root}>
      <LoadingContextConsumer isLoading />
      <Image src={spinner} style={style.image} />
    </View>
  );
};
