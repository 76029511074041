import { clsx } from 'clsx';
import React from 'react';

import style from './bookmark.module.css';

export type BookmarkProps = {
  className?: string;

  // TODO: Since, Webpack 4 doesn't support package exports
  // we cannot use `IconProps` from `@meisterlabs/icons` directly.
  // And MN still uses Webpack 4.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  icon?: React.FC<any>;

  text: string;
  href: string;
  label?: string;
  onClick: (href: string) => void;
};

export function Bookmark(props: BookmarkProps) {
  const { icon: Icon, className, text, href, label, onClick } = props;

  return (
    <div className={clsx('bookmark', className)}>
      <div className={style.root}>
        {Icon ? <Icon className={style.icon} /> : null}
        <a
          href={href}
          className={clsx(style.text, style.link)}
          onClick={(e) => {
            e.preventDefault();
            onClick(href);
          }}
        >
          {text}
        </a>
        {label && <span className={style.label}>{label}</span>}
      </div>
    </div>
  );
}
