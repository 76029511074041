import {
  MDAST,
  SAST,
  withMarkdownDeserializer,
  withMarkdownSerializer,
} from '@meisterlabs/slate-serializer';

import {
  withInlineStyle,
  withInlineStyleSerialization,
} from './withInlineStyle';

/**
 * Enable inline style code with a shortcut.
 */
export const withInlineCode = function (shortcut: string) {
  withInlineStyle(shortcut, 'code');
};

const mapChildNode = (node) => {
  if (SAST.isText(node)) {
    return {
      type: 'inlineCode',
      value: node.value,
    };
  }

  return {
    ...node,
    children: node.children.map(mapChildNode),
  };
};

export const withInlineCodeSerialization = function () {
  withInlineStyleSerialization('code', {
    html: {
      tag: 'code',
      alias: [],
    },
  });

  // We need to customize the (de)serializer for markdown, because inlineCode has no children
  // and needs to be the leaf of the markdown AST.
  withMarkdownSerializer<SAST.Mark>(
    (_, node) => SAST.isMark(node) && node.name === 'code',
    ({ multiple }, node) => {
      const children = multiple<SAST.TextContent>(node.children, node);

      return children.flatMap(mapChildNode);
    }
  );

  withMarkdownDeserializer<MDAST.InlineCode>(
    (_, node) => node.type === 'inlineCode',
    (_, node) => {
      return {
        type: 'mark',
        name: 'code',
        value: true,
        children: [
          {
            type: 'text',
            value: 'value' in node ? node.value : '',
          },
        ],
      };
    }
  );
};
