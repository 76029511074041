import { languages } from './languages';

interface Props {
  noneItemText: string;
  selectedLanguage: string;
}

export const languagesAsItems = function ({
  noneItemText,
  selectedLanguage,
}: Props) {
  const items = languages.map(function ({ id, title }) {
    return {
      id,
      content: title,
      type: 'item',
      iconRight: selectedLanguage === id ? 'Checkmark' : null,
    };
  });

  return [
    {
      id: 'none',
      content: noneItemText,
      type: 'item',
      iconRight: selectedLanguage === 'none' ? 'Checkmark' : null,
    },
    ...items,
  ];
};
