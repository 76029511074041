import logo from '../assets/Figma.png';
import { Parser } from '../types';
import findMatch from './utils/findMatch';

const re = new RegExp(
  'https://([w.-]+.)?figma.com/(file|proto)/([0-9a-zA-Z]{22,128})(?:/.*)?$'
);

const Embed: Parser = {
  logo,
  name: 'Figma',
  parseUrl(string) {
    return findMatch({
      string,
      find: ({ clean }) => re.test(clean),
      transform({ clean }) {
        return {
          type: 'Figma',
          url: clean,
          frameSrc: `https://www.figma.com/embed?embed_host=outline&url=${clean}`,
          frameAspectRatio: 12 / 16,
          frameBorder: true,
        };
      },
    });
  },
};

export default Embed;
