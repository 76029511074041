import React, {
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useReadOnly } from '@meisterlabs/slate-react';

import { Hunspell } from '../utils';
import { HunspellInstace, SupportedLanguages } from '../types';
import { Options } from '../middlewares/withSpellCheck';
import { SpellcheckOverlays } from './SpellcheckOverlays';
import { DictionaryContext } from './DictionaryContext';

export type SpellcheckRootProps = Options & {
  language: SupportedLanguages;
};

export const SpellcheckRoot: React.FC<PropsWithChildren<SpellcheckRootProps>> =
  function ({ children, language, ...options }) {
    const [hunspell, setHunspell] = useState<HunspellInstace>(null);
    const readOnly = useReadOnly();
    const { enabled } = useContext(DictionaryContext);

    useEffect(() => {
      if (!language) return;

      setHunspell(null);

      Hunspell.create(language).then((hs) => setHunspell(hs));
    }, [language]);

    if (readOnly) return <>{children}</>;

    return (
      <div
        style={{
          position: 'relative',
        }}
      >
        <div
          className='meisternote-spellchecker'
          style={{
            pointerEvents: 'none',
            height: '100%',
            width: '100%',
            position: 'absolute',
          }}
        >
          {hunspell && enabled && (
            <SpellcheckOverlays
              language={language}
              hunspell={hunspell}
              {...options}
            />
          )}
        </div>
        {children}
      </div>
    );
  };
