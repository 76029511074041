import { EditorContext } from '@meisterlabs/slate';
import { IndexeddbPersistence } from 'y-indexeddb';

export const withYjsIndexedDBProvider = function ({ id }: { id: string }) {
  const ctx = EditorContext.get();

  if (!ctx.yDoc) {
    throw new Error('withYjsIndexedDBProvider requires withYjs middleware');
  }

  new IndexeddbPersistence(id, ctx.yDoc);
};
