import { find as findLinks } from 'linkifyjs';
import {
  BlockElement,
  Editor,
  Node,
  Point,
  Text,
  isBlockElement,
} from '@meisterlabs/slate';
import { withDecorator } from '@meisterlabs/slate-react';

const shouldIgnoreNode = function (editor: Editor, node: Node) {
  if (Editor.isEditor(node)) return true;

  if (isBlockElement(node)) {
    if (node.type === 'code') return true;
    if (editor.isVoid(node)) return true;
  }

  if (Text.isText(node)) return true;

  return false;
};

/**
 * Adds a decorator which recognizes links in text nodes and adds these as link props to the leafs.
 * This will also need the plugin `withLinkRenderer` to work properly.
 */
export const withLink = function () {
  const decoratorCache = new WeakMap();

  withDecorator<BlockElement, { dynamicLink: string }>(function (
    [node, rootPath],
    editor
  ) {
    if (decoratorCache.has(node)) return decoratorCache.get(node);

    if (shouldIgnoreNode(editor, node)) {
      decoratorCache.set(node, []);

      return [];
    }

    // Workaround so that inline code marks are ignored completely.
    // Basically, setting the text of the code mark to spaces, this way offsets are still correct though.
    const text = node.children.reduce((acc, child) => {
      if (!Text.isText(child)) return acc + Node.string(child);
      if ('code' in child) return acc + ' '.repeat(child.text.length);
      return acc + child.text;
    }, '');

    const textNodes = Array.from(Node.texts(node as Node));

    const pointFromOffset = (offset: number): Point | undefined => {
      for (const [textNode, path] of textNodes) {
        const length = textNode.text.length;

        if (offset <= length) return { path: [...rootPath, ...path], offset };

        offset -= length;
      }
    };

    const offsets = findLinks(text).map(({ start, end, href, type }) => {
      if (type !== 'url') return;

      return {
        anchor: pointFromOffset(start),
        focus: pointFromOffset(end),
        dynamicLink: href,
      };
    });

    const filteredOffsets = offsets.filter(Boolean);

    decoratorCache.set(node, filteredOffsets);

    return filteredOffsets;
  });
};
