import {
  BlockElement,
  Editor,
  Location,
  Point,
  Range,
  Text,
  Transforms,
  isBlockElement,
} from '@meisterlabs/slate';
import { withInsertText } from '@meisterlabs/slate-react';

const escapeRegExp = function (string) {
  // Escape special characters for regex
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
};

const createRegex = function (key: string) {
  const escapedKey = escapeRegExp(key);

  return new RegExp(`^${escapedKey}([^${escapedKey}]+)${escapedKey}$`);
};

const insert = function (
  editor: Editor,
  location: Location,
  locationAfterConversion: Location,
  text: string,
  mark: string
) {
  Editor.withoutNormalizing(editor, () => {
    Transforms.insertText(editor, text, {
      at: location,
    });

    Transforms.setNodes<Text>(
      editor,
      { [mark]: true },
      {
        at: locationAfterConversion,
        mode: 'lowest',
        match: (n) => Text.isText(n),
        split: true,
      }
    );
  });

  // This is needed so that the mark is not applied to the next character the user types
  Editor.removeMark(editor, mark);
};

/**
 * Add inline markdown shortcuts to the editor.
 * @param shortcutsToMark - A record of shortcuts to mark in the following format
 * ```ts
 * {
 *    '`': 'code',
 *    '*': 'italic',
 *    '**': 'bold',
 * }
 */
export const withInlineMarkdownShortcuts = (
  shortcutsToMark: Record<string, string>
) => {
  Object.entries(shortcutsToMark).forEach(([key, mark]) => {
    const lastChar = key[key.length - 1];
    const regex = createRegex(key);

    withInsertText((editor, text) => {
      if (text !== lastChar) return;

      const { selection } = editor;

      if (!selection || Range.isExpanded(selection)) return;

      const block = Editor.above<BlockElement>(editor, {
        match: (n) => isBlockElement(n),
      });

      if (block) {
        const [, path] = block;
        const [start, end] = Editor.edges(editor, selection);
        const startPoint = Editor.start(editor, path);
        const beforeCursor = Editor.before(editor, start, {
          unit: 'character',
        });

        if (!beforeCursor) return;

        const startLocation = { anchor: beforeCursor, focus: end };

        // Find the string before the cursor until a space occurs
        let beforeWord = start;
        let char = Editor.string(editor, startLocation);

        while (!Point.equals(beforeWord, startPoint)) {
          const lastBeforeWord = beforeWord;
          const newBeforeWord = Editor.before(editor, beforeWord, {
            unit: 'character',
          });

          char = Editor.string(editor, {
            anchor: newBeforeWord,
            focus: lastBeforeWord,
          });

          if (!/\S/.test(char)) break;

          beforeWord = newBeforeWord;
        }

        const completeLocation = {
          anchor: beforeWord,
          focus: end,
        };

        // Check if the word before the cursor matches the regex
        const word = Editor.string(editor, completeLocation);
        const completeWord = `${word}${lastChar}`;
        const matches = completeWord.match(regex);

        if (!matches) return;

        // The length of the key in characters, since the last character is the one that triggers the shortcut
        // we need to subtract 1 from the length of the key
        const shortcutLength = key.length * 2 - 1;

        const locationAfterConversion = {
          anchor: completeLocation.anchor,
          focus: {
            path: completeLocation.focus.path,
            offset: completeLocation.focus.offset - shortcutLength,
          },
        };

        insert(
          editor,
          completeLocation,
          locationAfterConversion,
          matches[1],
          mark
        );

        return false;
      }
    });
  });
};
