import { Editor, Path, Transforms } from 'slate';
import cloneDeep from 'lodash.clonedeep';

import { findNodeByKey } from './findNode';
import { BlockElement } from '../types';
import { generateKey } from './generateKey';

/**
 * Duplicate a block by its key
 */
export const duplicateBlock = function (editor: Editor, key: string) {
  const [node, path] = findNodeByKey(editor, key);

  Transforms.insertNodes<BlockElement>(
    editor,
    {
      ...cloneDeep(node),
      key: generateKey(),
    } as BlockElement,
    {
      at: Path.next(path),
    }
  );
};
