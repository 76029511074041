import {
  HAST,
  MDAST,
  SAST,
  withHtmlDeserializer,
  withHtmlSerializer,
  withMarkdownDeserializer,
  withMarkdownSerializer,
} from '@meisterlabs/slate-serializer';

interface HorizontalRuleBlock extends SAST.Block {
  type: 'horizontal-rule';
}

export const withHorizontalRuleSerializers = function () {
  withHtmlSerializer<HorizontalRuleBlock>(
    (_, node) => node.type === 'horizontal-rule',
    () => {
      return {
        type: 'element',
        tagName: 'hr',
        children: [],
      };
    }
  );

  withHtmlDeserializer<HAST.Element>(
    (_, node) => node.tagName === 'hr',
    () => {
      return {
        type: 'horizontal-rule',
        properties: {},
        children: [
          {
            type: 'text',
            value: '',
          },
        ],
      };
    }
  );

  withMarkdownSerializer<HorizontalRuleBlock>(
    (_, node) => node.type === 'horizontal-rule',
    () => {
      return {
        type: 'thematicBreak',
      };
    }
  );

  withMarkdownDeserializer<MDAST.ThematicBreak>(
    (_, node) => node.type === 'thematicBreak',
    () => {
      return {
        type: 'horizontal-rule',
        properties: {},
        children: [
          {
            type: 'text',
            value: '',
          },
        ],
      };
    }
  );
};
