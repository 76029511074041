import React from 'react';
import {
  withElementRenderer,
  withElementNormalizer,
} from '@meisterlabs/slate-react';
import { removeUnusedProperties } from '@meisterlabs/slate';

import '../style.css';
import { CodeBlock, isCodeBlock } from '../types';
import { Code } from '../components/Code';

export interface Options {
  noneItemText: string;
  popoverHeaderText: string;
  onLanguageChanged?: (language: string) => void;
}

export const withCode = function (options: Options) {
  withElementRenderer<CodeBlock>(
    (element) => isCodeBlock(element),
    (props) => <Code {...props} {...options} />
  );

  withElementNormalizer<CodeBlock>(
    (element) => isCodeBlock(element),
    (element, path, editor) =>
      removeUnusedProperties(editor, element, path, ['language'])
  );
};
