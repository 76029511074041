import { Editor, Transforms } from '@meisterlabs/slate';

import { FileBlock } from '../types';

export const updateFile = function (
  editor: Editor,
  key: string,
  file: Partial<FileBlock>
) {
  Transforms.setNodes<FileBlock>(editor, file, {
    at: [],
    match: (n: FileBlock) => n.type === 'file' && n.key === key,
  });
};
