import { useLayoutEffect } from 'react';
import { ConnectDragSource, useDrag } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import {
  BlockElement,
  Transforms,
  Path,
  findNodeByKey,
  Editor,
  isBlockElement,
  Range,
} from '@meisterlabs/slate';
import {
  ReactEditor,
  useReadOnly,
  useSlateStatic,
} from '@meisterlabs/slate-react';

import { DropResult } from '../components/Droppable';

export interface Draggable {
  isDragging: boolean;
  drag: ConnectDragSource;
}

export const useDraggable = function (element: BlockElement): Draggable {
  const editor = useSlateStatic();
  const readOnly = useReadOnly();

  const [{ isDragging }, drag, preview] = useDrag({
    type: 'block',
    item: () => {
      const { selection } = editor;

      if (!selection || Range.isCollapsed(selection)) return [element.key];

      const fromPath = Range.start(selection).path;
      const toPath = Range.end(selection).path;

      const nodeEntries = Editor.nodes<BlockElement>(editor, {
        at: [fromPath, toPath],
        match: (n) => isBlockElement(n),
      });

      const selectedKeys = Array.from(nodeEntries).map(([node]) => node.key);

      if (selectedKeys.includes(element.key)) return selectedKeys;

      return [element.key];
    },
    canDrag: () => !readOnly,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    options: {
      dropEffect: 'move',
    },
    isDragging: (monitor) => {
      return monitor.getItem().includes(element.key);
    },
    end: (dragKeys, monitor) => {
      const dropResult = monitor.getDropResult<DropResult>();

      if (!dropResult) return;

      // Don't replace items with themselves
      if (dragKeys.includes(dropResult.key)) return;

      const [, dropPath] = findNodeByKey(editor, dropResult.key);
      const [, dragPath] = findNodeByKey(editor, dragKeys[0]);
      const [, lastDragPath] = findNodeByKey(
        editor,
        dragKeys[dragKeys.length - 1]
      );

      let path = dropPath;

      const isBefore = Path.isBefore(dropPath, dragPath);
      const isAfter = Path.isAfter(dropPath, dragPath);

      if (
        dropResult.position === 'top' &&
        isAfter &&
        Path.hasPrevious(dropPath)
      ) {
        path = Path.previous(dropPath);
      }

      if (dropResult.position === 'bottom' && isBefore) {
        path = Path.next(dropPath);
      }

      const range: Range = {
        focus: Editor.start(editor, dragPath),
        anchor: Editor.end(editor, lastDragPath),
      };

      Transforms.moveNodes(editor, {
        at: range,
        to: path,
      });

      ReactEditor.focus(editor as ReactEditor);
    },
  });

  useLayoutEffect(() => {
    preview(getEmptyImage());
  }, []);

  return {
    isDragging,
    drag,
  };
};
