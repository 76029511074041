import { clsx } from 'clsx';
import React from 'react';
import { BlockRenderElementProps } from '@meisterlabs/slate-react';

import { ListItemBlock } from '../types';
import style from './style.module.css';

type Props = Omit<BlockRenderElementProps<ListItemBlock>, 'element'> & {
  marker: React.ReactNode;

  /** Classname that gets added to the `li` item. */
  className?: string;
};

export const ListItem: React.FC<Props> = function ({
  attributes,
  children,
  marker,
  className,
}) {
  return (
    <ul {...attributes} className={clsx(attributes.className, style.listRoot)}>
      <li className={clsx(className, style.listItem)}>
        <div
          className={clsx('list-item-marker', style.listMarkerBox)}
          contentEditable={false}
        >
          {marker}
        </div>
        <span className={clsx('list-item-content', style.listContent)}>
          {children}
        </span>
      </li>
    </ul>
  );
};
