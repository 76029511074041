import { Editor, isBlockElement, parser } from '@meisterlabs/slate';
import { HeaderBlock, isHeaderBlock } from '@meisterlabs/slate-react-header';

export type Item = {
  key: string;
  name: string;
  depth: number;
};

const getNodes = function (editor: Editor, maxLevel: number) {
  const headers = Editor.nodes<HeaderBlock>(editor, {
    at: [],
    match: (n) => isBlockElement(n) && isHeaderBlock(n),
  });

  const nodes = [];

  for (const [header, path] of headers) {
    const level = parser.parseToInt(header.level);

    // Do not show header if level is greater than maxLevel
    if (level > maxLevel) continue;

    const key = header.key;
    const name = editor.string(path);

    // Ignore empty headers
    if (name.trim().length === 0) continue;

    const parentKey = nodes.reduceRight(function (key, node) {
      if (key) return key;
      if (node.level < level) return node.key;
      return null;
    }, null);

    nodes.push({
      key,
      name,
      level,
      parentKey,
    });
  }

  return nodes;
};

const getHierarchy = function (nodes) {
  const hierarchy = [];
  const idx = {};

  nodes.forEach(function (node) {
    const { key, parentKey } = node;

    idx[key] = {
      ...node,
      children: [],
      hasChildren: false,
    };

    if (parentKey) {
      idx[parentKey].children.push(idx[key]);
      idx[parentKey].hasChildren = true;
    }

    if (!parentKey) {
      hierarchy.push(idx[key]);
    }
  });

  return hierarchy;
};

const getList = function (hierarchy) {
  const list = [];

  const onHeader = function (header, depth) {
    list.push({
      ...header,
      depth,
    });

    header.children.forEach(function (header) {
      onHeader(header, depth + 1);
    });
  };

  hierarchy.forEach(function (header) {
    onHeader(header, 0);
  });

  return list.map(({ key, name, depth }) => {
    return {
      key,
      name,
      depth,
    };
  });
};

export default function getToc(
  editor: Editor,
  options: { maxLevel?: number } = {}
) {
  const { maxLevel = 6 } = options;

  const nodes = getNodes(editor, maxLevel);
  const hierarchy = getHierarchy(nodes);
  const list = getList(hierarchy);

  return list;
}
