import logo from '../assets/Typeform.png';
import { Parser } from '../types';

const re = new RegExp(
  '^https://([A-Za-z0-9](?:[A-Za-z0-9-]{0,61}[A-Za-z0-9])?).typeform.com/to/(.*)$'
);

const Embed: Parser = {
  logo,
  name: 'Typeform',
  parseUrl(url) {
    const match = url.match(re);

    if (!match) return null;

    return {
      type: 'Typeform',
      url,
      frameSrc: url,
      frameAspectRatio: 12 / 16,
      frameBorder: true,
    };
  },
};

export default Embed;
