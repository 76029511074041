import logo from '../assets/Prezi.png';
import { Parser } from '../types';
import findMatch from './utils/findMatch';

const re = new RegExp('https://prezi.com/v/(.*)');

const Embed: Parser = {
  logo,
  name: 'Prezi',
  parseUrl(string) {
    return findMatch({
      string,
      find: ({ clean }) => re.test(clean),
      transform({ clean }) {
        return {
          type: 'Prezi',
          url: clean,
          frameSrc: clean.includes('v/embed/')
            ? clean
            : clean.replace('v/', 'v/embed/'),
          frameAspectRatio: 9 / 16,
          frameBorder: true,
        };
      },
    });
  },
};

export default Embed;
