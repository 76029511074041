import * as Y from 'yjs';
import type { Node } from 'slate';
import { fromUint8Array, toUint8Array } from 'js-base64';
import {
  slateNodesToInsertDelta,
  withYHistory,
  withYjs,
  YjsEditor,
} from '@slate-yjs/core';
import { EditorContext, withOnCreate } from '@meisterlabs/slate';

export type YdocEditor = YjsEditor & {
  /**
   * Get the current state vector of the YDoc
   * This helps to temporarily store the state vector locally
   */
  getYjsContent: () => string;
};

// See: https://docs.slate-yjs.dev/walkthroughs/installation
/**
 * Adds Yjs to the editor.
 */
export const withY = function ({
  currentValue,
}: {
  currentValue: string | Node[];
}) {
  const ctx = EditorContext.get();

  const yDoc = new Y.Doc();

  const sharedType = yDoc.get('content', Y.XmlText) as Y.XmlText;

  if (typeof currentValue === 'string') {
    const updateUint8Array = toUint8Array(currentValue);
    Y.applyUpdate(yDoc, updateUint8Array);
  } else {
    sharedType.applyDelta(slateNodesToInsertDelta(currentValue));
  }

  ctx.yDoc = yDoc;
  ctx.editor = withYjs(ctx.editor, sharedType);

  (ctx.editor as YdocEditor).getYjsContent = () =>
    fromUint8Array(Y.encodeStateAsUpdate(yDoc));

  // TODO I don't know how this will handle foreign changes out of the box, see `trackedOrigins` docs here:
  // - https://docs.slate-yjs.dev/api/slate-yjs-core/history-plugin
  // - https://docs.yjs.dev/api/undo-manager#example-specify-tracked-origins
  ctx.editor = withYHistory(ctx.editor as YjsEditor);

  withOnCreate(function () {
    // TODO:  This should be in a useEffect see: https://docs.slate-yjs.dev/walkthroughs/collaboration-hocuspocus#client-side-setup
    YjsEditor.connect(ctx.editor as YjsEditor);
  });
};
