import { RangeRef } from '@meisterlabs/slate';
import { ReactEditor } from '@meisterlabs/slate-react';

/**
 * Get the rect for a slate range relative to a parent container
 */
export const rectForRangeRef = function (
  parent: HTMLElement,
  editor: ReactEditor,
  rangeRef: RangeRef
): DOMRect | null {
  const parentRect = parent.getBoundingClientRect();

  // Since toDomRange can crash, we need to catch it.
  // This can happen if the selection update but the DOM is not yet updated.
  // This should be okay since the worst thing that happens is that a spellcheck error is not shown.
  try {
    const domRange = ReactEditor.toDOMRange(editor, rangeRef.current);
    const rect = domRange.getBoundingClientRect();

    // We need to add the parent rect to the dom rect, to offset the positions correctly.
    // Else we would have the position relative to the viewport instead of the parent.
    return {
      top: rect.top - parentRect.top,
      left: rect.left - parentRect.left,
      bottom: rect.bottom - parentRect.top,
      right: rect.right - parentRect.left,
      width: rect.width,
      height: rect.height,
    } as DOMRect;
  } catch {
    return null;
  }
};

/**
 * Check if a point is inside a rect
 */
const pointIntersectRect = function (rect: DOMRect, point: DOMPoint) {
  return (
    point.x >= rect.left &&
    point.x <= rect.right &&
    point.y >= rect.top &&
    point.y <= rect.bottom
  );
};

/**
 * Find the index of the item that intersects with the mouse event
 */
export const itemIntersectingMouseEvent = function (
  rects: Array<DOMRect>,
  event: MouseEvent
) {
  const node = event.currentTarget as HTMLElement;
  const rect = node.getBoundingClientRect();

  // We need to add the scroll position to the event point.
  // Otherwise, the point will be off if the editor is scrolled or is placed embedded in a container.
  const eventPoint = new DOMPoint(
    event.clientX - rect.left + node.scrollLeft,
    event.clientY - rect.top + node.scrollTop
  );

  return rects.findIndex((rect) => {
    return pointIntersectRect(rect, eventPoint);
  });
};
