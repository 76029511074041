import logo from '../assets/Spotify.png';
import { Parser } from '../types';

const getNormalizedPath = function (url: string) {
  try {
    const parsed = new URL(url);

    return parsed.pathname.replace(/^\/embed/, '/');
  } catch (err) {
    return null;
  }
};

const re = new RegExp('https?://open.spotify.com/(.*)$');

const Embed: Parser = {
  logo,
  name: 'Spotify',
  parseUrl(url) {
    const match = url.match(re);

    if (!match) return null;

    const normalizedPath = getNormalizedPath(url);

    if (!normalizedPath) return null;

    const frameHeight = (() => {
      if (normalizedPath.includes('episode')) return 232;
      if (normalizedPath.includes('show')) return 232;
      if (normalizedPath.includes('track')) return 80;
      return 380;
    })();

    return {
      type: 'Spotify',
      url,
      frameSrc: `https://open.spotify.com/embed${normalizedPath}`,
      frameHeight,
      frameBorder: true,
    };
  },
};

export default Embed;
