// Generated by create-importer script

const importers: { [key: string]: () => Promise<unknown> } = {
    'abap': () => import('prismjs/components/prism-abap.min.js' as string),
    'abnf': () => import('prismjs/components/prism-abnf.min.js' as string),
    'actionscript': () => import('prismjs/components/prism-actionscript.min.js' as string),
    'ada': () => import('prismjs/components/prism-ada.min.js' as string),
    'agda': () => import('prismjs/components/prism-agda.min.js' as string),
    'al': () => import('prismjs/components/prism-al.min.js' as string),
    'antlr4': () => import('prismjs/components/prism-antlr4.min.js' as string),
    'apacheconf': () => import('prismjs/components/prism-apacheconf.min.js' as string),
    'apex': () => import('prismjs/components/prism-apex.min.js' as string),
    'apl': () => import('prismjs/components/prism-apl.min.js' as string),
    'applescript': () => import('prismjs/components/prism-applescript.min.js' as string),
    'aql': () => import('prismjs/components/prism-aql.min.js' as string),
    'arduino': () => import('prismjs/components/prism-arduino.min.js' as string),
    'arff': () => import('prismjs/components/prism-arff.min.js' as string),
    'armasm': () => import('prismjs/components/prism-armasm.min.js' as string),
    'arturo': () => import('prismjs/components/prism-arturo.min.js' as string),
    'asciidoc': () => import('prismjs/components/prism-asciidoc.min.js' as string),
    'asm6502': () => import('prismjs/components/prism-asm6502.min.js' as string),
    'asmatmel': () => import('prismjs/components/prism-asmatmel.min.js' as string),
    'aspnet': () => import('prismjs/components/prism-aspnet.min.js' as string),
    'autohotkey': () => import('prismjs/components/prism-autohotkey.min.js' as string),
    'autoit': () => import('prismjs/components/prism-autoit.min.js' as string),
    'avisynth': () => import('prismjs/components/prism-avisynth.min.js' as string),
    'avro-idl': () => import('prismjs/components/prism-avro-idl.min.js' as string),
    'awk': () => import('prismjs/components/prism-awk.min.js' as string),
    'bash': () => import('prismjs/components/prism-bash.min.js' as string),
    'basic': () => import('prismjs/components/prism-basic.min.js' as string),
    'batch': () => import('prismjs/components/prism-batch.min.js' as string),
    'bbcode': () => import('prismjs/components/prism-bbcode.min.js' as string),
    'bbj': () => import('prismjs/components/prism-bbj.min.js' as string),
    'bicep': () => import('prismjs/components/prism-bicep.min.js' as string),
    'birb': () => import('prismjs/components/prism-birb.min.js' as string),
    'bison': () => import('prismjs/components/prism-bison.min.js' as string),
    'bnf': () => import('prismjs/components/prism-bnf.min.js' as string),
    'bqn': () => import('prismjs/components/prism-bqn.min.js' as string),
    'brainfuck': () => import('prismjs/components/prism-brainfuck.min.js' as string),
    'brightscript': () => import('prismjs/components/prism-brightscript.min.js' as string),
    'bro': () => import('prismjs/components/prism-bro.min.js' as string),
    'bsl': () => import('prismjs/components/prism-bsl.min.js' as string),
    'c': () => import('prismjs/components/prism-c.min.js' as string),
    'cfscript': () => import('prismjs/components/prism-cfscript.min.js' as string),
    'chaiscript': () => import('prismjs/components/prism-chaiscript.min.js' as string),
    'cil': () => import('prismjs/components/prism-cil.min.js' as string),
    'cilkc': () => import('prismjs/components/prism-cilkc.min.js' as string),
    'cilkcpp': () => import('prismjs/components/prism-cilkcpp.min.js' as string),
    'clike': () => import('prismjs/components/prism-clike.min.js' as string),
    'clojure': () => import('prismjs/components/prism-clojure.min.js' as string),
    'cmake': () => import('prismjs/components/prism-cmake.min.js' as string),
    'cobol': () => import('prismjs/components/prism-cobol.min.js' as string),
    'coffeescript': () => import('prismjs/components/prism-coffeescript.min.js' as string),
    'concurnas': () => import('prismjs/components/prism-concurnas.min.js' as string),
    'cooklang': () => import('prismjs/components/prism-cooklang.min.js' as string),
    'coq': () => import('prismjs/components/prism-coq.min.js' as string),
    'core': () => import('prismjs/components/prism-core.min.js' as string),
    'cpp': () => import('prismjs/components/prism-cpp.min.js' as string),
    'crystal': () => import('prismjs/components/prism-crystal.min.js' as string),
    'csharp': () => import('prismjs/components/prism-csharp.min.js' as string),
    'cshtml': () => import('prismjs/components/prism-cshtml.min.js' as string),
    'csp': () => import('prismjs/components/prism-csp.min.js' as string),
    'css-extras': () => import('prismjs/components/prism-css-extras.min.js' as string),
    'css': () => import('prismjs/components/prism-css.min.js' as string),
    'csv': () => import('prismjs/components/prism-csv.min.js' as string),
    'cue': () => import('prismjs/components/prism-cue.min.js' as string),
    'cypher': () => import('prismjs/components/prism-cypher.min.js' as string),
    'd': () => import('prismjs/components/prism-d.min.js' as string),
    'dart': () => import('prismjs/components/prism-dart.min.js' as string),
    'dataweave': () => import('prismjs/components/prism-dataweave.min.js' as string),
    'dax': () => import('prismjs/components/prism-dax.min.js' as string),
    'dhall': () => import('prismjs/components/prism-dhall.min.js' as string),
    'diff': () => import('prismjs/components/prism-diff.min.js' as string),
    'django': () => import('prismjs/components/prism-django.min.js' as string),
    'dns-zone-file': () => import('prismjs/components/prism-dns-zone-file.min.js' as string),
    'docker': () => import('prismjs/components/prism-docker.min.js' as string),
    'dot': () => import('prismjs/components/prism-dot.min.js' as string),
    'ebnf': () => import('prismjs/components/prism-ebnf.min.js' as string),
    'editorconfig': () => import('prismjs/components/prism-editorconfig.min.js' as string),
    'eiffel': () => import('prismjs/components/prism-eiffel.min.js' as string),
    'ejs': () => import('prismjs/components/prism-ejs.min.js' as string),
    'elixir': () => import('prismjs/components/prism-elixir.min.js' as string),
    'elm': () => import('prismjs/components/prism-elm.min.js' as string),
    'erb': () => import('prismjs/components/prism-erb.min.js' as string),
    'erlang': () => import('prismjs/components/prism-erlang.min.js' as string),
    'etlua': () => import('prismjs/components/prism-etlua.min.js' as string),
    'excel-formula': () => import('prismjs/components/prism-excel-formula.min.js' as string),
    'factor': () => import('prismjs/components/prism-factor.min.js' as string),
    'false': () => import('prismjs/components/prism-false.min.js' as string),
    'firestore-security-rules': () => import('prismjs/components/prism-firestore-security-rules.min.js' as string),
    'flow': () => import('prismjs/components/prism-flow.min.js' as string),
    'fortran': () => import('prismjs/components/prism-fortran.min.js' as string),
    'fsharp': () => import('prismjs/components/prism-fsharp.min.js' as string),
    'ftl': () => import('prismjs/components/prism-ftl.min.js' as string),
    'gap': () => import('prismjs/components/prism-gap.min.js' as string),
    'gcode': () => import('prismjs/components/prism-gcode.min.js' as string),
    'gdscript': () => import('prismjs/components/prism-gdscript.min.js' as string),
    'gedcom': () => import('prismjs/components/prism-gedcom.min.js' as string),
    'gettext': () => import('prismjs/components/prism-gettext.min.js' as string),
    'gherkin': () => import('prismjs/components/prism-gherkin.min.js' as string),
    'git': () => import('prismjs/components/prism-git.min.js' as string),
    'glsl': () => import('prismjs/components/prism-glsl.min.js' as string),
    'gml': () => import('prismjs/components/prism-gml.min.js' as string),
    'gn': () => import('prismjs/components/prism-gn.min.js' as string),
    'go-module': () => import('prismjs/components/prism-go-module.min.js' as string),
    'go': () => import('prismjs/components/prism-go.min.js' as string),
    'gradle': () => import('prismjs/components/prism-gradle.min.js' as string),
    'graphql': () => import('prismjs/components/prism-graphql.min.js' as string),
    'groovy': () => import('prismjs/components/prism-groovy.min.js' as string),
    'haml': () => import('prismjs/components/prism-haml.min.js' as string),
    'handlebars': () => import('prismjs/components/prism-handlebars.min.js' as string),
    'haskell': () => import('prismjs/components/prism-haskell.min.js' as string),
    'haxe': () => import('prismjs/components/prism-haxe.min.js' as string),
    'hcl': () => import('prismjs/components/prism-hcl.min.js' as string),
    'hlsl': () => import('prismjs/components/prism-hlsl.min.js' as string),
    'hoon': () => import('prismjs/components/prism-hoon.min.js' as string),
    'hpkp': () => import('prismjs/components/prism-hpkp.min.js' as string),
    'hsts': () => import('prismjs/components/prism-hsts.min.js' as string),
    'http': () => import('prismjs/components/prism-http.min.js' as string),
    'ichigojam': () => import('prismjs/components/prism-ichigojam.min.js' as string),
    'icon': () => import('prismjs/components/prism-icon.min.js' as string),
    'icu-message-format': () => import('prismjs/components/prism-icu-message-format.min.js' as string),
    'idris': () => import('prismjs/components/prism-idris.min.js' as string),
    'iecst': () => import('prismjs/components/prism-iecst.min.js' as string),
    'ignore': () => import('prismjs/components/prism-ignore.min.js' as string),
    'inform7': () => import('prismjs/components/prism-inform7.min.js' as string),
    'ini': () => import('prismjs/components/prism-ini.min.js' as string),
    'io': () => import('prismjs/components/prism-io.min.js' as string),
    'j': () => import('prismjs/components/prism-j.min.js' as string),
    'java': () => import('prismjs/components/prism-java.min.js' as string),
    'javadoc': () => import('prismjs/components/prism-javadoc.min.js' as string),
    'javadoclike': () => import('prismjs/components/prism-javadoclike.min.js' as string),
    'javascript': () => import('prismjs/components/prism-javascript.min.js' as string),
    'javastacktrace': () => import('prismjs/components/prism-javastacktrace.min.js' as string),
    'jexl': () => import('prismjs/components/prism-jexl.min.js' as string),
    'jolie': () => import('prismjs/components/prism-jolie.min.js' as string),
    'jq': () => import('prismjs/components/prism-jq.min.js' as string),
    'js-extras': () => import('prismjs/components/prism-js-extras.min.js' as string),
    'js-templates': () => import('prismjs/components/prism-js-templates.min.js' as string),
    'jsdoc': () => import('prismjs/components/prism-jsdoc.min.js' as string),
    'json': () => import('prismjs/components/prism-json.min.js' as string),
    'json5': () => import('prismjs/components/prism-json5.min.js' as string),
    'jsonp': () => import('prismjs/components/prism-jsonp.min.js' as string),
    'jsstacktrace': () => import('prismjs/components/prism-jsstacktrace.min.js' as string),
    'jsx': () => import('prismjs/components/prism-jsx.min.js' as string),
    'julia': () => import('prismjs/components/prism-julia.min.js' as string),
    'keepalived': () => import('prismjs/components/prism-keepalived.min.js' as string),
    'keyman': () => import('prismjs/components/prism-keyman.min.js' as string),
    'kotlin': () => import('prismjs/components/prism-kotlin.min.js' as string),
    'kumir': () => import('prismjs/components/prism-kumir.min.js' as string),
    'kusto': () => import('prismjs/components/prism-kusto.min.js' as string),
    'latex': () => import('prismjs/components/prism-latex.min.js' as string),
    'latte': () => import('prismjs/components/prism-latte.min.js' as string),
    'less': () => import('prismjs/components/prism-less.min.js' as string),
    'lilypond': () => import('prismjs/components/prism-lilypond.min.js' as string),
    'linker-script': () => import('prismjs/components/prism-linker-script.min.js' as string),
    'liquid': () => import('prismjs/components/prism-liquid.min.js' as string),
    'lisp': () => import('prismjs/components/prism-lisp.min.js' as string),
    'livescript': () => import('prismjs/components/prism-livescript.min.js' as string),
    'llvm': () => import('prismjs/components/prism-llvm.min.js' as string),
    'log': () => import('prismjs/components/prism-log.min.js' as string),
    'lolcode': () => import('prismjs/components/prism-lolcode.min.js' as string),
    'lua': () => import('prismjs/components/prism-lua.min.js' as string),
    'magma': () => import('prismjs/components/prism-magma.min.js' as string),
    'makefile': () => import('prismjs/components/prism-makefile.min.js' as string),
    'markdown': () => import('prismjs/components/prism-markdown.min.js' as string),
    'markup-templating': () => import('prismjs/components/prism-markup-templating.min.js' as string),
    'markup': () => import('prismjs/components/prism-markup.min.js' as string),
    'mata': () => import('prismjs/components/prism-mata.min.js' as string),
    'matlab': () => import('prismjs/components/prism-matlab.min.js' as string),
    'maxscript': () => import('prismjs/components/prism-maxscript.min.js' as string),
    'mel': () => import('prismjs/components/prism-mel.min.js' as string),
    'mermaid': () => import('prismjs/components/prism-mermaid.min.js' as string),
    'metafont': () => import('prismjs/components/prism-metafont.min.js' as string),
    'mizar': () => import('prismjs/components/prism-mizar.min.js' as string),
    'mongodb': () => import('prismjs/components/prism-mongodb.min.js' as string),
    'monkey': () => import('prismjs/components/prism-monkey.min.js' as string),
    'moonscript': () => import('prismjs/components/prism-moonscript.min.js' as string),
    'n1ql': () => import('prismjs/components/prism-n1ql.min.js' as string),
    'n4js': () => import('prismjs/components/prism-n4js.min.js' as string),
    'nand2tetris-hdl': () => import('prismjs/components/prism-nand2tetris-hdl.min.js' as string),
    'naniscript': () => import('prismjs/components/prism-naniscript.min.js' as string),
    'nasm': () => import('prismjs/components/prism-nasm.min.js' as string),
    'neon': () => import('prismjs/components/prism-neon.min.js' as string),
    'nevod': () => import('prismjs/components/prism-nevod.min.js' as string),
    'nginx': () => import('prismjs/components/prism-nginx.min.js' as string),
    'nim': () => import('prismjs/components/prism-nim.min.js' as string),
    'nix': () => import('prismjs/components/prism-nix.min.js' as string),
    'nsis': () => import('prismjs/components/prism-nsis.min.js' as string),
    'objectivec': () => import('prismjs/components/prism-objectivec.min.js' as string),
    'ocaml': () => import('prismjs/components/prism-ocaml.min.js' as string),
    'odin': () => import('prismjs/components/prism-odin.min.js' as string),
    'opencl': () => import('prismjs/components/prism-opencl.min.js' as string),
    'openqasm': () => import('prismjs/components/prism-openqasm.min.js' as string),
    'oz': () => import('prismjs/components/prism-oz.min.js' as string),
    'parigp': () => import('prismjs/components/prism-parigp.min.js' as string),
    'parser': () => import('prismjs/components/prism-parser.min.js' as string),
    'pascal': () => import('prismjs/components/prism-pascal.min.js' as string),
    'pascaligo': () => import('prismjs/components/prism-pascaligo.min.js' as string),
    'pcaxis': () => import('prismjs/components/prism-pcaxis.min.js' as string),
    'peoplecode': () => import('prismjs/components/prism-peoplecode.min.js' as string),
    'perl': () => import('prismjs/components/prism-perl.min.js' as string),
    'php-extras': () => import('prismjs/components/prism-php-extras.min.js' as string),
    'php': () => import('prismjs/components/prism-php.min.js' as string),
    'phpdoc': () => import('prismjs/components/prism-phpdoc.min.js' as string),
    'plant-uml': () => import('prismjs/components/prism-plant-uml.min.js' as string),
    'plsql': () => import('prismjs/components/prism-plsql.min.js' as string),
    'powerquery': () => import('prismjs/components/prism-powerquery.min.js' as string),
    'powershell': () => import('prismjs/components/prism-powershell.min.js' as string),
    'processing': () => import('prismjs/components/prism-processing.min.js' as string),
    'prolog': () => import('prismjs/components/prism-prolog.min.js' as string),
    'promql': () => import('prismjs/components/prism-promql.min.js' as string),
    'properties': () => import('prismjs/components/prism-properties.min.js' as string),
    'protobuf': () => import('prismjs/components/prism-protobuf.min.js' as string),
    'psl': () => import('prismjs/components/prism-psl.min.js' as string),
    'pug': () => import('prismjs/components/prism-pug.min.js' as string),
    'puppet': () => import('prismjs/components/prism-puppet.min.js' as string),
    'pure': () => import('prismjs/components/prism-pure.min.js' as string),
    'purebasic': () => import('prismjs/components/prism-purebasic.min.js' as string),
    'purescript': () => import('prismjs/components/prism-purescript.min.js' as string),
    'python': () => import('prismjs/components/prism-python.min.js' as string),
    'q': () => import('prismjs/components/prism-q.min.js' as string),
    'qml': () => import('prismjs/components/prism-qml.min.js' as string),
    'qore': () => import('prismjs/components/prism-qore.min.js' as string),
    'qsharp': () => import('prismjs/components/prism-qsharp.min.js' as string),
    'r': () => import('prismjs/components/prism-r.min.js' as string),
    'racket': () => import('prismjs/components/prism-racket.min.js' as string),
    'reason': () => import('prismjs/components/prism-reason.min.js' as string),
    'regex': () => import('prismjs/components/prism-regex.min.js' as string),
    'rego': () => import('prismjs/components/prism-rego.min.js' as string),
    'renpy': () => import('prismjs/components/prism-renpy.min.js' as string),
    'rescript': () => import('prismjs/components/prism-rescript.min.js' as string),
    'rest': () => import('prismjs/components/prism-rest.min.js' as string),
    'rip': () => import('prismjs/components/prism-rip.min.js' as string),
    'roboconf': () => import('prismjs/components/prism-roboconf.min.js' as string),
    'robotframework': () => import('prismjs/components/prism-robotframework.min.js' as string),
    'ruby': () => import('prismjs/components/prism-ruby.min.js' as string),
    'rust': () => import('prismjs/components/prism-rust.min.js' as string),
    'sas': () => import('prismjs/components/prism-sas.min.js' as string),
    'sass': () => import('prismjs/components/prism-sass.min.js' as string),
    'scala': () => import('prismjs/components/prism-scala.min.js' as string),
    'scheme': () => import('prismjs/components/prism-scheme.min.js' as string),
    'scss': () => import('prismjs/components/prism-scss.min.js' as string),
    'shell-session': () => import('prismjs/components/prism-shell-session.min.js' as string),
    'smali': () => import('prismjs/components/prism-smali.min.js' as string),
    'smalltalk': () => import('prismjs/components/prism-smalltalk.min.js' as string),
    'smarty': () => import('prismjs/components/prism-smarty.min.js' as string),
    'sml': () => import('prismjs/components/prism-sml.min.js' as string),
    'solidity': () => import('prismjs/components/prism-solidity.min.js' as string),
    'solution-file': () => import('prismjs/components/prism-solution-file.min.js' as string),
    'soy': () => import('prismjs/components/prism-soy.min.js' as string),
    'sparql': () => import('prismjs/components/prism-sparql.min.js' as string),
    'splunk-spl': () => import('prismjs/components/prism-splunk-spl.min.js' as string),
    'sqf': () => import('prismjs/components/prism-sqf.min.js' as string),
    'sql': () => import('prismjs/components/prism-sql.min.js' as string),
    'squirrel': () => import('prismjs/components/prism-squirrel.min.js' as string),
    'stan': () => import('prismjs/components/prism-stan.min.js' as string),
    'stata': () => import('prismjs/components/prism-stata.min.js' as string),
    'stylus': () => import('prismjs/components/prism-stylus.min.js' as string),
    'supercollider': () => import('prismjs/components/prism-supercollider.min.js' as string),
    'swift': () => import('prismjs/components/prism-swift.min.js' as string),
    'systemd': () => import('prismjs/components/prism-systemd.min.js' as string),
    't4-cs': () => import('prismjs/components/prism-t4-cs.min.js' as string),
    't4-templating': () => import('prismjs/components/prism-t4-templating.min.js' as string),
    't4-vb': () => import('prismjs/components/prism-t4-vb.min.js' as string),
    'tap': () => import('prismjs/components/prism-tap.min.js' as string),
    'tcl': () => import('prismjs/components/prism-tcl.min.js' as string),
    'textile': () => import('prismjs/components/prism-textile.min.js' as string),
    'toml': () => import('prismjs/components/prism-toml.min.js' as string),
    'tremor': () => import('prismjs/components/prism-tremor.min.js' as string),
    'tsx': () => import('prismjs/components/prism-tsx.min.js' as string),
    'tt2': () => import('prismjs/components/prism-tt2.min.js' as string),
    'turtle': () => import('prismjs/components/prism-turtle.min.js' as string),
    'twig': () => import('prismjs/components/prism-twig.min.js' as string),
    'typescript': () => import('prismjs/components/prism-typescript.min.js' as string),
    'typoscript': () => import('prismjs/components/prism-typoscript.min.js' as string),
    'unrealscript': () => import('prismjs/components/prism-unrealscript.min.js' as string),
    'uorazor': () => import('prismjs/components/prism-uorazor.min.js' as string),
    'uri': () => import('prismjs/components/prism-uri.min.js' as string),
    'v': () => import('prismjs/components/prism-v.min.js' as string),
    'vala': () => import('prismjs/components/prism-vala.min.js' as string),
    'vbnet': () => import('prismjs/components/prism-vbnet.min.js' as string),
    'velocity': () => import('prismjs/components/prism-velocity.min.js' as string),
    'verilog': () => import('prismjs/components/prism-verilog.min.js' as string),
    'vhdl': () => import('prismjs/components/prism-vhdl.min.js' as string),
    'vim': () => import('prismjs/components/prism-vim.min.js' as string),
    'visual-basic': () => import('prismjs/components/prism-visual-basic.min.js' as string),
    'warpscript': () => import('prismjs/components/prism-warpscript.min.js' as string),
    'wasm': () => import('prismjs/components/prism-wasm.min.js' as string),
    'web-idl': () => import('prismjs/components/prism-web-idl.min.js' as string),
    'wgsl': () => import('prismjs/components/prism-wgsl.min.js' as string),
    'wiki': () => import('prismjs/components/prism-wiki.min.js' as string),
    'wolfram': () => import('prismjs/components/prism-wolfram.min.js' as string),
    'wren': () => import('prismjs/components/prism-wren.min.js' as string),
    'xeora': () => import('prismjs/components/prism-xeora.min.js' as string),
    'xml-doc': () => import('prismjs/components/prism-xml-doc.min.js' as string),
    'xojo': () => import('prismjs/components/prism-xojo.min.js' as string),
    'xquery': () => import('prismjs/components/prism-xquery.min.js' as string),
    'yaml': () => import('prismjs/components/prism-yaml.min.js' as string),
    'yang': () => import('prismjs/components/prism-yang.min.js' as string),
    'zig': () => import('prismjs/components/prism-zig.min.js' as string),
};

export default importers;
