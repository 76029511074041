import { Transforms } from 'slate';

export const removeUnusedProperties = function (
  editor,
  elem,
  path,
  supportedKeys
) {
  const element = { ...elem };
  const keys = ['type', 'children', 'key', 'depth', ...supportedKeys];
  const unsetKeys = [];

  Object.keys(element).forEach((key) => {
    if (!keys.includes(key)) unsetKeys.push(key);
  });

  if (unsetKeys.length > 0) {
    Transforms.unsetNodes(editor, unsetKeys, { at: path });

    return false;
  }
};
