import { Editor, Transforms } from 'slate';
import { BlockElement } from '../types';
import { findNode } from './findNode';

export const selectBlock = function (editor: Editor, key: string) {
  const [, path] = findNode(editor, {
    at: [],
    match: (node: BlockElement) => node.key === key,
  });

  Transforms.select(editor, path);
};
