import React from 'react';
import { colors, fonts, rgba } from '@meisterlabs/ui';
import { Icon, Text, View } from '@meisterlabs/knightrider';
import * as DocumentIcons from '@meisterlabs/svgs/FileFormats';

interface Props {
  iconName: string;
  name: string;
  extension: string;
  onClick: () => void;
}

const style = {
  root: {
    userSelect: 'none',
    flexGrow: 1,
    flexShrink: 1,
    height: 44,
    backgroundColor: rgba.black(0.05),
    borderRadius: 10,
    paddingLeft: 20,
    paddingRight: 20,
    alignItems: 'center',
    position: 'relative',
    cursor: 'pointer',
  },
  icon: {
    width: 24,
    height: 24,
  },
  info: {
    root: {
      flexGrow: 1,
      flexShrink: 1,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    name: {
      root: {
        flexGrow: 1,
        flexShrink: 1,
      },
      text: {
        ...fonts.m.medium,
        paddingRight: 10,
        paddingLeft: 10,
        flexShrink: 1,
        flexGrow: 1,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
    },
    extension: {
      ...fonts.s.bold,
      color: colors.grey500,
      textTransform: 'uppercase',
      whiteSpace: 'nowrap',
    },
  },
};

export const Meta: React.VFC<Props> = function ({
  iconName,
  name,
  extension,
  onClick,
}) {
  const FileIcon = DocumentIcons[iconName];

  return (
    <View style={style.root} onClick={onClick}>
      <Icon style={style.icon}>
        <FileIcon />
      </Icon>
      <View style={style.info.root}>
        <View style={style.info.name.root}>
          <Text style={style.info.name.text}>{name}</Text>
        </View>
        <View>
          <Text style={style.info.extension}>{extension}</Text>
        </View>
      </View>
    </View>
  );
};
