import { createContext } from 'react';

export interface DictionaryContextValue {
  enabled: boolean;
  words: Set<string>;
  addToDictionary: (word: string) => void;
  ignore: (word: string) => void;
}

export const DictionaryContext = createContext<DictionaryContextValue>({
  enabled: false,
  words: new Set(),
  addToDictionary: () => null,
  ignore: () => null,
});

DictionaryContext.displayName = 'DictionaryContext';
