import { fromHtml } from 'hast-util-from-html';

import * as SAST from '../sast';
import {
  createState,
  Options as HastToSastOptions,
} from '../transformers/hastToSast';
import {
  toSlate as toSlateDefault,
  Options as ToSlateOptions,
} from '../parsers/sastStringify';

export const toSlate = function (
  doc: string,
  options: HastToSastOptions & ToSlateOptions
) {
  const processor = function (tree) {
    const state = createState(options);

    return state.one(tree) as SAST.Root;
  };

  const hast = fromHtml(doc);
  const sast = processor(hast);

  return toSlateDefault(sast, options);
};
