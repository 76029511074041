import React from 'react';
import clsx from 'clsx';
import { LeafRenderProps, withLeafRenderer } from '@meisterlabs/slate-react';

import { LinkText } from '../types';
import {
  HAST,
  MDAST,
  SAST,
  withHtmlDeserializer,
  withHtmlSerializer,
  withMarkdownDeserializer,
  withMarkdownSerializer,
} from '@meisterlabs/slate-serializer';

export interface LinkRendererOptions {
  onOpenLink?: (url: string) => void;
}

const LinkLeaf: React.FC<LeafRenderProps<LinkText> & LinkRendererOptions> = ({
  attributes,
  leaf,
  children,
  onOpenLink,
}) => {
  // Explicitly set links should be rendered before decorator links
  const link = leaf.link ?? leaf.dynamicLink;
  const url = link?.startsWith('http') ? link : `//${link}`;

  const defaultOpenLink = () => window.open(url, '_blank');

  return (
    <a
      {...attributes}
      className={clsx('link', attributes.className)}
      href={url}
      target='_blank'
      rel='noopener noreferrer'
      onMouseDown={(event) => {
        // Don't open immediately when the user is clicking a non-primary mouse button or clicking control
        if (event.button !== 0 || event.ctrlKey) return;

        event.preventDefault();
        event.stopPropagation();

        if (onOpenLink) onOpenLink(url);
        else defaultOpenLink();
      }}
    >
      {children}
    </a>
  );
};

/**
 * Enable the inline rendering of Links.
 */
export const withLinkRenderer = function (options: LinkRendererOptions = {}) {
  withLeafRenderer<LinkText>(
    (leaf) => !!leaf.link || !!leaf.dynamicLink,
    (props) => <LinkLeaf {...props} {...options} />
  );
};

export const withInlineLinkSerialization = function () {
  withHtmlSerializer<SAST.Mark<string>>(
    (_, node) => SAST.isMark(node) && node.name === 'link',
    ({ multiple }, node) => {
      return {
        type: 'element',
        tagName: 'a',
        properties: {
          href: node.value,
        },
        children: multiple(node.children, node),
      };
    }
  );

  withHtmlDeserializer<HAST.Element, SAST.Mark<string>>(
    (_, node) => node.tagName === 'a' && !!node.properties.href,
    ({ multiple }, node) => {
      return {
        type: 'mark',
        name: 'link',
        value: node.properties.href as string,
        children: multiple(node.children, node),
      };
    }
  );

  withMarkdownSerializer<SAST.Mark<string>, MDAST.Link>(
    (_, node) => SAST.isMark(node) && node.name === 'link',
    ({ multiple }, node) => {
      return {
        type: 'link',
        url: node.value,
        children: multiple(node.children, node),
      };
    }
  );

  withMarkdownDeserializer<MDAST.Link, SAST.Mark<string>>(
    (_, node) => node.type === 'link',
    ({ multiple }, node) => {
      return {
        type: 'mark',
        name: 'link',
        value: node.url,
        children: multiple(node.children, node),
      };
    }
  );
};
