import { Literal, Parent } from 'unist';

export interface Text extends Literal {
  type: 'text';
  value: string;
}

export interface Mark<T = string | boolean> extends Parent {
  type: 'mark';
  name: string;
  value: T;
  children: TextContent[];
}

export interface TextContentMap {
  text: Text;
  mark: Mark<string | boolean>;
}

export interface ContentMap {
  text: Text;
  mark: Mark<string | boolean>;
  block: Block;
}

export type Content = ContentMap[keyof ContentMap];
export type TextContent = TextContentMap[keyof TextContentMap];

export interface Block extends Parent {
  type: string;
  properties: Record<string, unknown>;
  children: Content[];
}

export interface Root extends Parent {
  type: 'root';
  children: Content[];
}

export type Node = Root | Content;

export const isMark = function (node: Node): node is Mark {
  return node.type === 'mark';
};

export const isText = function (node: Node): node is Text {
  return node.type === 'text';
};

export const isBlock = function (node: Node): node is Block {
  return 'properties' in node;
};

export const isTextContent = function (node: Node): node is TextContent {
  return isText(node) || isMark(node);
};

export const string = function (node: Node): string {
  if (isText(node)) return node.value;
  if (isMark(node)) return node.children.map(string).join('');
  if (isBlock(node)) return node.children.map(string).join('');
  return '';
};
