import logo from '../assets/Codepen.png';
import { Parser } from '../types';
import findMatch from './utils/findMatch';

const re = new RegExp('^https://codepen.io/(.*?)/(pen|embed)/(.*)$');

const Embed: Parser = {
  logo,
  name: 'Codepen',
  parseUrl(string) {
    return findMatch({
      string,
      find: ({ clean }) => re.test(clean),
      transform({ clean }) {
        return {
          type: 'Codepen',
          url: clean,
          frameSrc: clean.replace('/pen/', '/embed/'),
          frameAspectRatio: 12 / 16,
          frameBorder: false,
        };
      },
    });
  },
};

export default Embed;
