import type { BlockRenderElementProps } from '@meisterlabs/slate-react';
import { Bookmark } from '@meisterlabs/slate-shared-components';
import React from 'react';

import * as Parsers from '../parsers';
import type { EmbedBlock } from '../types';

export type EmbedBookmarkProps = BlockRenderElementProps<EmbedBlock> & {
  onBookmark?: (href: string) => void;
};

export function EmbedBookmark(props: EmbedBookmarkProps) {
  const { onBookmark, element, children, attributes } = props;

  return (
    <div {...attributes} contentEditable={false} className='embed-bookmark'>
      <Bookmark
        href={element.embedUrl}
        text={'Link'}
        label={Parsers[element.embedType]?.name}
        onClick={onBookmark ?? (() => {})}
      />
      {children}
    </div>
  );
}
