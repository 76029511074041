import React from 'react';
import fuzzySort from 'fuzzysort';
import * as Popover from '@radix-ui/react-popover';
import { IconButton } from '@meisterlabs/ui';
import { StandaloneList, style as ListStyle } from '@meisterlabs/react-lists';
import { PopoverHeader } from '@meisterlabs/ui';
import { colors } from '@meisterlabs/colors';

import * as Parsers from '../parsers';
import style from './embedTypePicker.module.css';

interface Item {
  id: string;
  content: string;
  iconLeft: string;
}

const styles: Record<string, React.CSSProperties> = {
  list: ListStyle({
    itemMarginHorizontal: 10,
    headerMarginHorizontal: 10,
    paddingTop: 10,
    paddingBottom: 10,
    selectedColor: colors.sky,
  }),
};

const icons = Object.values(Parsers).reduce<Record<string, React.FC>>(function (
  acc,
  { name, logo }
) {
  acc[name] = () => <img style={{ width: '100%' }} src={logo} />;

  return acc;
}, {});

const items = Object.entries(Parsers).map(function ([id, { name }]) {
  return {
    id,
    content: name,
    iconLeft: name,
  };
});

interface PropsPopover {
  header: string;
  onSelectType: (type: string) => void;
  closePopover: () => void;
}

const EmbedTypePickerPopover: React.VFC<PropsPopover> = function ({
  closePopover,
  header,
  onSelectType,
}) {
  const [filter, setFilter] = React.useState('');

  const onSelect = (type: string) => {
    onSelectType(type);
    closePopover();
  };

  const onInputValueSettle = (filter: string) => {
    setFilter(filter);
  };

  const filteredItems = React.useMemo(() => {
    return fuzzySort
      .go<Item>(filter, items, { key: 'content', threshold: -1000, all: true })
      .map(({ obj }) => obj);
  }, [filter]);

  return (
    <div className={style.root}>
      <div className={style.header}>
        <PopoverHeader
          renderLeft={() => null}
          text={header}
          onClickIconRight={closePopover}
        />
      </div>
      <StandaloneList
        selectFirst
        items={filteredItems}
        onEnter={onSelect}
        onClickItem={onSelect}
        listProps={{
          maxItemsHeight: '100%',
          icons,
          style: styles.list,
          onEscape: closePopover,
          onInputValueSettle,
        }}
      />
    </div>
  );
};

type PropsButton = Omit<PropsPopover, 'closePopover'> & {
  readOnly: boolean;
  reFocus: () => void;
};

export const EmbedTypePickerPopoverButton: React.FC<PropsButton> = function (
  props
) {
  const { children, header, readOnly, onSelectType, reFocus } = props;

  const [open, setOpen] = React.useState(false);

  const onCloseAutoFocus = React.useCallback(
    function (event: Event) {
      event.preventDefault();
      reFocus();
    },
    [reFocus]
  );

  return (
    <Popover.Root open={!readOnly && open}>
      <Popover.Trigger
        style={{
          border: 'none',
          background: 'none',
          padding: 0,
        }}
      >
        <IconButton disabled={readOnly} onClick={() => setOpen(true)}>
          {children}
        </IconButton>
      </Popover.Trigger>
      <Popover.Portal>
        <Popover.Content
          collisionPadding={20}
          onCloseAutoFocus={onCloseAutoFocus}
          onPointerDownOutside={() => setOpen(false)}
        >
          <div className={style.popover}>
            <EmbedTypePickerPopover
              onSelectType={onSelectType}
              header={header}
              closePopover={() => setOpen(false)}
            />
          </div>
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
};
