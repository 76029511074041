import logo from '../assets/Vimeo.png';
import { Parser } from '../types';

const re =
  /(http|https)?:\/\/(www\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^/]*)\/videos\/|)(\d+)(?:|\/\?)/;

const Embed: Parser = {
  logo,
  name: 'Vimeo',
  parseUrl(url) {
    const videoId = url.match(re)?.[4];

    if (!videoId) return null;

    return {
      type: 'Vimeo',
      url,
      frameSrc: `https://player.vimeo.com/video/${videoId}?byline=0`,
      frameAspectRatio: 9 / 16,
      frameBorder: true,
    };
  },
};

export default Embed;
