import { Editor, Transforms } from 'slate';
import { BlockElement } from '../types';
import { findNode } from './findNode';

export const newBlockBefore = function (
  editor: Editor,
  block: BlockElement,
  afterKey: string,
  shouldSelect = true
) {
  const [, path] = findNode(editor, {
    at: [],
    match: (node: BlockElement) => node.key === afterKey,
  });

  Transforms.insertNodes(editor, block, {
    at: path,
    select: shouldSelect,
  });
};
