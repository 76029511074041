import { createNanoEvents } from 'nanoevents';

import { resolveLanguage } from '../utils/resolveLanguage';
import languageImporters from './languageImporters';

export const LanguageLoader = {
  loading: new Set(),
  loaded: new Set(),

  events: createNanoEvents(),

  load(lang: string) {
    const language = resolveLanguage(lang);

    if (!language) return;
    if (LanguageLoader.loaded.has(language)) return language;
    if (LanguageLoader.loading.has(language)) return;

    const importer = languageImporters[language.id];

    importer().then(function () {
      LanguageLoader.loading.delete(language);
      LanguageLoader.loaded.add(language);
      LanguageLoader.events.emit('load');
    });

    LanguageLoader.loading.add(language);
  },
};
