import { clsx } from 'clsx';
import React from 'react';
import spinner from '@meisterlabs/images/spinners/128.png';
import { LoadingContextConsumer } from '@meisterlabs/slate-react';

import style from './frame.module.css';

interface State {
  title: string;
  src: string;
  height?: number;
  aspectRatio?: number;
  useBorder: boolean;
}

const SANDBOX = [
  'allow-downloads',
  'allow-forms',
  'allow-popups',
  'allow-presentation',
  'allow-scripts',
  'allow-same-origin',
].join(' ');

const Spinner: React.FC<{ isLoading: boolean }> = function ({ isLoading }) {
  return (
    <div
      className={style.spinnerContainer}
      style={{ opacity: isLoading ? 1 : 0 }}
    >
      <img className={style.spinner} src={spinner} />
    </div>
  );
};

export const Frame: React.FC<State> = function ({
  aspectRatio,
  title,
  src,
  height,
  useBorder,
}) {
  const [isLoading, setIsLoading] = React.useState(true);
  const withBorder = useBorder || (isLoading && style.withBorder);

  return (
    <div
      className={clsx('frame-container', style.root, withBorder)}
      style={{
        paddingBottom: aspectRatio && `${aspectRatio * 100}%`,
        height: height && `${height}px`,
      }}
    >
      <LoadingContextConsumer isLoading={isLoading} />
      <Spinner isLoading={isLoading} />
      <iframe
        className={style.iframe}
        title={title}
        src={src}
        onLoad={() => setIsLoading(false)}
        allowFullScreen
        sandbox={SANDBOX}
        referrerPolicy='no-referrer'
      />
    </div>
  );
};
