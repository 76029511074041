import { Editor, createEditor as createBaseEditor } from 'slate';

import { EditorContext } from './EditorContext';

/**
 * Creates a new slate editor instance.
 */
export const createEditor = function (setupFn: () => void): Editor {
  const editor = createBaseEditor();

  const ctx = EditorContext.create({
    editor,
    onCreate: [],
    initialValue: null,
  });

  setupFn();

  ctx.onCreate.forEach((callback) => callback());

  if (ctx.initialValue) ctx.editor.children = ctx.initialValue;

  EditorContext.done();

  return ctx.editor;
};
