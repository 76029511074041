import { events } from '../../middlewares/withSpellCheck';
import { HunspellInstace } from '../../types';

export const updateDictionary = function (
  hunspell: HunspellInstace,
  words: Set<string>
) {
  if (!hunspell.caches.customDictionary)
    hunspell.caches.customDictionary = new Set();

  const toRemove = Array.from(hunspell.caches.customDictionary).filter(
    (x) => !words.has(x)
  );
  const toAdd = Array.from(words).filter(
    (x) => !hunspell.caches.customDictionary.has(x)
  );

  if (toRemove.length === 0 && toAdd.length === 0) return;

  hunspell.caches.customDictionary = words;

  toAdd.forEach((word) => {
    hunspell.caches.words.delete(word);
    hunspell.caches.suggestions.delete(word);
    hunspell.addWord(word);
  });

  toRemove.forEach((word) => {
    hunspell.removeWord(word);
  });

  events.emit('update', hunspell);
};
