import { Range } from '@meisterlabs/slate';
import { withOnPaste } from '@meisterlabs/slate-react';

import { toggleLink } from '../commands';
import { isValidLink } from '../utils/isValidLink';

/**
 * Adds a hotkey to toggle the link mark.
 * This will also need the plugin `withLinkRenderer` to work properly.
 */
export const withInlineLink = function () {
  withOnPaste((event, editor) => {
    // We need to trim in case the user copied a link with leading/trailing spaces
    const link = event.clipboardData.getData('text/plain').trim();
    const isCollapsed = Range.isCollapsed(editor.selection);

    const isLink = isValidLink(link);

    if (isLink) {
      event.preventDefault();

      if (isCollapsed) editor.insertText(link);
      else toggleLink(editor, link);

      return false;
    }
  });
};
