import * as MDAST from 'mdast';
import { fromMarkdown } from 'mdast-util-from-markdown';
import { gfm } from 'micromark-extension-gfm';
import { gfmFromMarkdown } from 'mdast-util-gfm';

import * as SAST from '../sast';
import {
  createState,
  Options as MdastToSastOptions,
} from '../transformers/mdastToSast';
import {
  toSlate as toSlateDefault,
  Options as StringifyOptions,
} from '../parsers/sastStringify';

export const toSlate = function (
  doc: string,
  options: MdastToSastOptions & StringifyOptions
) {
  const processor = function (tree: MDAST.Root) {
    const state = createState(options);

    return state.one(tree) as SAST.Root;
  };

  const mdast = fromMarkdown(doc, 'utf-8', {
    extensions: [gfm()],
    mdastExtensions: [gfmFromMarkdown()],
  });

  const sast = processor(mdast);

  return toSlateDefault(sast, options);
};
