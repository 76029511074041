import React from 'react';
import {
  BlockRenderElementProps,
  useReadOnly,
  useSlateStatic,
} from '@meisterlabs/slate-react';
import { fonts } from '@meisterlabs/fonts';
import { colors, rgba } from '@meisterlabs/colors';
import { Icon, Text, View } from '@meisterlabs/knightrider';
import { EmojiMindMeister } from '@meisterlabs/svgs/TintSmall';

import type { Options } from '../middlewares/withMindMeister';
import { EmptyMMBlock } from '../types';
import { showPicker } from '../utils/showPicker';

const styles = {
  root: (readOnly: boolean) => ({
    userSelect: 'none',
    flexGrow: 1,
    flexShrink: 1,
    height: 44,
    backgroundColor: rgba.black(0.05),
    borderRadius: 10,
    paddingLeft: 20,
    paddingRight: 20,
    alignItems: 'center',
    position: 'relative',
    cursor: readOnly ? 'default' : 'pointer',
  }),
  icon: {
    width: 24,
    height: 24,
    color: colors.grey500,
  },
  info: {
    root: {
      flexGrow: 1,
      flexShrink: 1,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    name: {
      root: {
        flexGrow: 1,
        flexShrink: 1,
      },
      text: {
        ...fonts.m.medium,
        color: colors.grey500,
        paddingRight: 10,
        paddingLeft: 10,
        flexShrink: 1,
        flexGrow: 1,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
    },
  },
};

type Props = Options & {
  placeholder: string;
};

export const Placeholder: React.FC<
  BlockRenderElementProps<EmptyMMBlock> & Props
> = function (props) {
  const {
    attributes,
    children,
    element,
    placeholder,
    appKey,
    pickerUrl,
    resourcesDomain,
    onSelectedMmMap,
  } = props;

  const editor = useSlateStatic();
  const readOnly = useReadOnly();

  const onClick = React.useCallback(() => {
    if (readOnly) return;

    showPicker({
      editor,
      key: element.key,
      appKey,
      pickerUrl,
      resourcesDomain,
      onSelectedMmMap,
    });
  }, [readOnly]);

  return (
    <div contentEditable={false} {...attributes}>
      <View onClick={onClick} style={styles.root(readOnly)}>
        <Icon style={styles.icon}>
          <EmojiMindMeister />
        </Icon>
        <View style={styles.info.root}>
          <View style={styles.info.name.root}>
            <Text style={styles.info.name.text}>{placeholder}</Text>
          </View>
        </View>
      </View>
      {children}
    </div>
  );
};
