import logo from '../assets/Cawemo.png';
import { Parser } from '../types';
import findMatch from './utils/findMatch';

const re = new RegExp('https?://cawemo.com/(?:share|embed)/(.*)$');

const Embed: Parser = {
  logo,
  name: 'Cawemo',
  parseUrl(string) {
    return findMatch({
      string,
      find: ({ clean }) => re.test(clean),
      transform({ clean }) {
        const shareId = clean.match(re)?.[1];

        if (!shareId) return null;

        return {
          type: 'Cawemo',
          url: clean,
          frameSrc: `https://cawemo.com/embed/${shareId}`,
          frameAspectRatio: 12 / 16,
          frameBorder: true,
        };
      },
    });
  },
};

export default Embed;
