import React, { forwardRef, useCallback, useEffect, useState } from 'react';
import {
  TransformWrapper,
  TransformComponent,
  ReactZoomPanPinchState,
  ReactZoomPanPinchRef,
} from 'react-zoom-pan-pinch';
import { parser } from '@meisterlabs/slate';
import { LoadingContextConsumer } from '@meisterlabs/slate-react';

import { Header } from './Header';
import { MMBlock } from '../types';

export interface Zoom {
  scale: number;
  x: number;
  y: number;
}

interface Props {
  element: MMBlock;
  src: string;
  readOnly: boolean;
  isHovered: boolean;
  isResizing: boolean;
  isFullScreen: boolean;
  updateZoom: (zoom: Zoom) => void;
}

export const SimpleImage: React.FC<{ src: string }> = function ({ src }) {
  const [isLoading, setIsLoading] = useState(true);

  return (
    <>
      <LoadingContextConsumer isLoading={isLoading} />
      <img
        src={src}
        onLoad={() => setIsLoading(false)}
        style={{
          width: '100%',
        }}
      />
    </>
  );
};

export const Image = forwardRef<HTMLImageElement, Props>(
  function ImageWithRef(props, imageRef) {
    const {
      element,
      readOnly,
      isHovered,
      isResizing,
      isFullScreen,
      src,
      updateZoom,
    } = props;

    const [showSaveButton, setShowSaveButton] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const scale = parser.parseToFloat(element.scale);
    const x = parser.parseToFloat(element.x);
    const y = parser.parseToFloat(element.y);

    const onSave = ({
      positionX,
      positionY,
      scale,
    }: ReactZoomPanPinchState) => {
      updateZoom({
        scale,
        x: positionX,
        y: positionY,
      });
    };

    const updateShowSaveButton = (zoom: Zoom) => {
      const tolerance = 0.1;

      const isSame =
        Math.abs(scale - zoom.scale) < tolerance &&
        Math.abs(x - zoom.x) < tolerance &&
        Math.abs(y - zoom.y) < tolerance;

      if (isSame) setShowSaveButton(false);
      else setShowSaveButton(true);
    };

    const onTransformed = useCallback(
      (ref: ReactZoomPanPinchRef) => {
        updateShowSaveButton({
          scale: ref.state.scale,
          x: ref.state.positionX,
          y: ref.state.positionY,
        });
      },
      [element, setShowSaveButton]
    );

    useEffect(() => {
      updateShowSaveButton({
        scale: scale ?? 1,
        x: x ?? 0,
        y: y ?? 0,
      });
    }, [scale, x, y]);

    const showHeader = isFullScreen || (isHovered && !readOnly && !isResizing);

    return (
      <TransformWrapper
        initialScale={scale ?? 1}
        initialPositionX={x ?? 0}
        initialPositionY={y ?? 0}
        onTransformed={onTransformed}
        limitToBounds={false}
        minScale={0.1}
      >
        {({ zoomIn, zoomOut, instance }) => {
          return (
            <>
              <LoadingContextConsumer isLoading={isLoading} />
              <TransformComponent
                wrapperStyle={{
                  width: '100%',
                  height: '100%',
                }}
                contentStyle={{
                  width: '100%',
                  height: '100%',
                }}
              >
                <img
                  ref={imageRef}
                  src={src}
                  onLoad={() => setIsLoading(false)}
                  style={{
                    width: '100%',
                  }}
                />
              </TransformComponent>
              <Header
                showHeader={showHeader}
                showSaveButton={showSaveButton}
                onClickZoomIn={() => zoomIn(0.5)}
                onClickZoomOut={() => zoomOut(0.5)}
                onClickSave={() => onSave(instance.getContext().state)}
              />
            </>
          );
        }}
      </TransformWrapper>
    );
  }
);
