import { Serializers } from './types';

export interface SerializerEditorContextState {
  serializers: Serializers;
}

export class SerializerEditorContext {
  static current: SerializerEditorContextState | null = null;

  static create(
    context: SerializerEditorContextState
  ): SerializerEditorContextState {
    SerializerEditorContext.current = context;

    return context;
  }

  static get(): SerializerEditorContextState {
    if (!SerializerEditorContext.current) {
      throw new Error('EditorContext not initialized');
    }

    return SerializerEditorContext.current;
  }

  static done() {
    SerializerEditorContext.current = null;
  }
}
