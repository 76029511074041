import Prism from 'prismjs';
import { getRangeFromOffsetsInTextList, Node, Text } from '@meisterlabs/slate';
import {
  withDecorator,
  withForceUpdater,
  withLeafClassNames,
} from '@meisterlabs/slate-react';

import { LanguageLoader } from '../helpers';
import '../prism.css';
import { CodeBlock } from '../types';

export const withSyntaxHighlighter = function () {
  const decoratorCache = new WeakMap();

  withLeafClassNames<{ prism: string } & Text>(function (leaf) {
    return leaf.prism && `token ${leaf.prism}`;
  });

  withForceUpdater(function (forceUpdate) {
    LanguageLoader.events.on('load', forceUpdate);
  });

  withDecorator<CodeBlock, { prism: string }>(function ([node, path]) {
    if (node.type !== 'code') return;
    if (decoratorCache.has(node)) return decoratorCache.get(node);

    const language = LanguageLoader.load(node.language);

    if (!language) return;

    const grammar = Prism.languages[language.id];

    // NOTE: Grammar does not load for `xml-doc` (and maybe others).
    // Until we figure out the root cause, simply disable syntax highlighting.
    if (grammar === undefined) return;

    const text = Node.string(node as Node);
    const tokens = Prism.tokenize(text, grammar);

    let offset = 0;

    const result = tokens.flatMap(function (token) {
      const start = offset;
      const end = start + token.length;
      offset = end;

      if (typeof token === 'string') return [];

      const range = getRangeFromOffsetsInTextList(node, path, start, end);

      if (!range || !range.anchor || !range.focus) return [];

      return [
        {
          ...range,
          prism: token.type,
        },
      ];
    });

    decoratorCache.set(node, result);

    return result;
  });
};
