import React from 'react';
import { withElementRenderer, withIsVoid } from '@meisterlabs/slate-react';

import { TocBlock, isTocBlock } from '../types';
import { Block } from '../components/Block';

export interface Options {
  emptyMessage?: string;
  maxLevel?: number;
  onClickItem?: (props: { key: string }) => void;
}

/**
 * This middleware adds the table of content block.
 */
export const withToc = function (options: Options = {}) {
  const {
    emptyMessage = 'This is a placeholder. Use the `emptyMessage` prop of `withToc`.',
    maxLevel = Infinity,
    onClickItem = () => undefined,
  } = options;

  withIsVoid((element) => isTocBlock(element));

  withElementRenderer<TocBlock>(
    (element) => isTocBlock(element),
    (props) => (
      <Block
        {...props}
        emptyMessage={emptyMessage}
        maxLevel={maxLevel}
        onClickItem={onClickItem}
      />
    )
  );
};
