import { EditorContext } from '@meisterlabs/slate';

import { Provider } from '../Provider';
import { ProviderOptions } from '../types';

export const withYjsSyncProvider = function (options?: ProviderOptions) {
  const ctx = EditorContext.get();

  if (!ctx.yDoc)
    throw new Error('withYjsSyncProvider requires withY middleware');

  return new Provider(ctx.yDoc, options);
};
