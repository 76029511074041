import { find } from 'linkifyjs';

export const isValidLink = function (text: string) {
  const result = find(text);

  if (!result || result.length !== 1) return false;

  const isUrl = result[0].type === 'url';
  const isExactMatch = result[0].start === 0 && result[0].end === text.length;

  return isUrl && isExactMatch;
};
