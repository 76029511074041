import {
  withInlineStyle,
  withInlineStyleSerialization,
} from './withInlineStyle';

/**
 * Enable inline style strikethrough with a shortcut.
 */
export const withInlineStrikethrough = function (shortcut: string) {
  withInlineStyle(shortcut, 'strikethrough');
};

export const withInlineStrikethroughSerialization = function () {
  withInlineStyleSerialization('strikethrough', {
    html: {
      tag: 's',
      alias: ['strike', 'del'],
    },
    markdown: {
      type: 'delete',
    },
  });
};
