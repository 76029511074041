import * as MDAST from 'mdast';

import {
  Handlers as DefaultHandlers,
  StateOptions,
  createState as createDefaultState,
} from './default';
import * as Sast from '../sast';
import { MdastNode } from '../types';

export type Handlers = DefaultHandlers<MdastNode, Sast.Node>;

const defaultHandlers: Handlers = [
  [
    (_, node) => node.type === 'root',
    ({ multiple }, node) => {
      if (node.type !== 'root') return;

      return {
        type: 'root',
        children: multiple(node.children, node),
      } as Sast.Root;
    },
  ],
  [
    (_, node) => node.type === 'text',
    (_, node: MDAST.Text) => {
      return {
        type: 'text',
        value: node.value,
      };
    },
  ],
];

export type Options = Omit<StateOptions<MdastNode, Sast.Node>, 'getHandleKey'>;

export const createState = function (options: Options = {}) {
  const handlers = [...options.handlers, ...defaultHandlers];

  return createDefaultState<MdastNode, Sast.Node>({ handlers });
};
