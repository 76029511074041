import React, { useMemo, useRef, useState } from 'react';
import { parser } from '@meisterlabs/slate';
import { useReadOnly, useSlateStatic } from '@meisterlabs/slate-react';
import { rgba } from '@meisterlabs/colors';
import { View } from '@meisterlabs/knightrider';
import { LazyFile, Resizer } from '@meisterlabs/slate-shared-components';

import { Footer } from './Footer';
import { MMBlock } from '../types';
import { updateMindmeister } from '../commands';
import { Image, SimpleImage, Zoom } from './Image';
import { getImageUrl, getOpeningUrl } from '../utils/urls';

const styles = {
  root: {
    width: '100%',
    position: 'relative',
    useSelect: 'none',
  },
  success: {
    width: '100%',
    cursor: 'default',
    userSelect: 'none',
    flexShrink: 1,
    borderRadius: 10,
    overflow: 'hidden',
    position: 'relative',
    transition: 'box-shadow 200ms ease-in-out',
  },
};

interface Props {
  footerMetaText: string;
  resourcesDomain: string;
  element: MMBlock;
  fetchFile: (url: string) => Promise<Blob>;
  isFullScreen: boolean;
  setIsFullScreen: (isFullScreen: boolean) => void;
  isMMPaid: boolean;
  onRepositionedMmMap?: (scale: number, x: number, y: number) => void;
}

export const MindMap: React.VFC<Props> = function (props) {
  const {
    fetchFile,
    footerMetaText,
    resourcesDomain,
    element,
    isFullScreen,
    setIsFullScreen,
    isMMPaid,
    onRepositionedMmMap,
  } = props;

  const editor = useSlateStatic();
  const readOnly = useReadOnly();

  const [isHovered, setIsHovered] = useState(false);
  const rootRef = useRef<HTMLDivElement>(null);

  const onMouseOver = () => setIsHovered(true);
  const onMouseLeave = () => setIsHovered(false);

  const onResize = function (height) {
    updateMindmeister(editor, element.key, {
      heightDelta: parser.parseToString(height),
    });
  };

  const onClickFullscreen = function () {
    setIsFullScreen(!isFullScreen);
  };

  const onSaveZoom = function (zoom: Zoom) {
    onRepositionedMmMap?.(zoom.scale, zoom.x, zoom.y);

    updateMindmeister(editor, element.key, {
      scale: parser.parseToString(zoom.scale),
      x: parser.parseToString(zoom.x),
      y: parser.parseToString(zoom.y),
    });
  };

  const imageUrl = getImageUrl(resourcesDomain, element, isMMPaid);
  const openingUrl = getOpeningUrl(resourcesDomain, element);

  const height = useMemo(
    () => parser.parseToFloat(element.heightDelta) ?? 150,
    [element.heightDelta]
  );

  return (
    <Resizer.Vertical
      minHeight={150}
      initialHeightDelta={height}
      setNewHeight={onResize}
    >
      {function ({ isResizing, naturalSizeRefCallback, height }) {
        const showBorder = isHovered || isResizing;

        return (
          <View
            style={{
              ...styles.root,
              height: isFullScreen ? '100%' : null,
            }}
            onElement={(node) => {
              rootRef.current = node;
            }}
            onMouseOver={onMouseOver}
            onMouseLeave={onMouseLeave}
          >
            <LazyFile url={imageUrl} fetchFile={fetchFile}>
              {(url) => {
                return (
                  <View
                    style={{
                      ...styles.success,
                      height: isFullScreen ? '100%' : height,
                      boxShadow: showBorder
                        ? `0 0 0 1px ${rgba.grey700(0.1)}`
                        : `0 0 0 1px ${rgba.white(0.1)}`,
                    }}
                  >
                    {isMMPaid ? (
                      <Image
                        element={element}
                        src={url}
                        readOnly={readOnly}
                        isResizing={isResizing}
                        isFullScreen={isFullScreen}
                        isHovered={isHovered}
                        updateZoom={onSaveZoom}
                        ref={naturalSizeRefCallback}
                      />
                    ) : (
                      <SimpleImage src={url} />
                    )}

                    <Footer
                      metaText={footerMetaText}
                      isFullScreen={isFullScreen}
                      isHovered={isHovered}
                      title={element.mapTitle}
                      url={openingUrl}
                      onClickFullScreen={onClickFullscreen}
                    />
                  </View>
                );
              }}
            </LazyFile>
          </View>
        );
      }}
    </Resizer.Vertical>
  );
};
