export const getSuggestions = function (hunspell, word) {
  const result = hunspell.caches.suggestions.get(word);

  if (result != null) return result;

  const suggestions = hunspell.suggest(word);

  hunspell.caches.suggestions.set(word, suggestions);

  return suggestions;
};

getSuggestions.warmup = function (hunspell, word) {
  setTimeout(function () {
    getSuggestions(hunspell, word);
  });
};
