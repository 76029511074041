import { Transforms } from 'slate';
import { ReactEditor } from 'slate-react';
import { BlockElement, convertBlock } from '@meisterlabs/slate';

export interface ConversionBlock {
  type: string;
  blockProperties?: object;
}

export interface ConvertElementToBlockProp {
  editor: ReactEditor;
  block: ConversionBlock;
  element: BlockElement;
  deleteText?: boolean;
}

export const convertElementToBlock = function (
  props: ConvertElementToBlockProp
) {
  const { editor, block, element, deleteText = false } = props;

  if (deleteText) {
    const path = ReactEditor.findPath(editor, element);
    const textPath = [...path, 0];

    Transforms.delete(editor, {
      at: textPath,
      unit: 'block',
    });
  }

  convertBlock(editor, element.key, {
    ...(block.blockProperties ?? {}),
    type: block.type,
  });
};
