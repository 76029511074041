import React from 'react';
import {
  BlockRenderElementProps,
  ReactEditor,
  useSlateSelector,
} from '@meisterlabs/slate-react';

import type { WithOrderedListOptions } from '../middlewares/withOrderedList';
import { OrderedListItemBlock } from '../types';
import { ListItem } from './ListItem';
import { getOrderedNumber } from '../utils/getOrderedNumber';

export const OrderedListItem: React.FC<
  BlockRenderElementProps<OrderedListItemBlock> & WithOrderedListOptions
> = function (props) {
  const {
    attributes,
    children,
    element,
    blocksWithDepth,
    blocksThatAlwaysContinue,
  } = props;

  const indexString = useSlateSelector(
    function (editor) {
      const currentPath = ReactEditor.findPath(editor as ReactEditor, element);

      // TODO: Maybe this could be further optimized by always storing what the previous element calculated?
      const index = getOrderedNumber(editor, currentPath, {
        blocksWithDepth,
        blocksThatAlwaysContinue,
      });

      return index.join('.');
    },
    (prevIndex, nextIndex) => prevIndex === nextIndex
  );

  return (
    <ListItem attributes={attributes} marker={`${indexString}.`}>
      {children}
    </ListItem>
  );
};
