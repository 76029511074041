import logo from '../assets/Giphy.png';
import { Parser } from '../types';
import findMatch from './utils/findMatch';

const re = new RegExp(
  '^https?://(media.)?giphy.com/(media|embed)/(.*?)(/giphy.gif)?$'
);

const Embed: Parser = {
  logo,
  name: 'Giphy',
  parseUrl(string) {
    return findMatch({
      string,
      find: ({ clean }) => re.test(clean),
      transform({ clean }) {
        const contentID = clean.match(re)?.[3];

        if (!contentID) return null;

        return {
          type: 'Giphy',
          url: clean,
          frameSrc: `https://giphy.com/embed/${contentID}`,
          frameAspectRatio: 9 / 16,
          frameBorder: true,
        };
      },
    });
  },
};

export default Embed;
