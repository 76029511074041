import React from 'react';
import { RenderLeafProps } from 'slate-react';

interface Props extends RenderLeafProps {
  tagName: string;
}

export const DefaultLeaf: React.FC<Props> = function ({
  tagName,
  attributes,
  children,
}) {
  return React.createElement(tagName, attributes, children);
};
