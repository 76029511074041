import { Editor, Path, Text } from '@meisterlabs/slate';
import { ReactEditor, withLeafClassNames } from '@meisterlabs/slate-react';

/**
 * Adds classes `has-previous-bordered` and `has-next-bordered` to the text node
 * if the previous or next node is a mark with one of the given attributes.
 */
export const withBorderedMarks = function (markAttributes: string[]) {
  withLeafClassNames<Text>(function (leaf, text, editor) {
    const final = [];

    const hasAnyMark = markAttributes.some((attribute) => leaf[attribute]);

    // If the leaf doesn't have any of the given marks, we don't need to add any class.
    if (!hasAnyMark) return final;

    const path = ReactEditor.findPath(editor, text);
    const previous = Editor.before(editor, path);
    const next = Editor.after(editor, path);

    const isPreviousSibling = previous && Path.isSibling(path, previous.path);
    const isNextSibling = next && Path.isSibling(path, next.path);

    if (isPreviousSibling) {
      const previousEntry = Editor.leaf(editor, previous);

      if (previousEntry) {
        const [previousLeaf] = previousEntry;
        const isPreviousBorderedEntity = markAttributes.some(
          (attribute) => previousLeaf[attribute]
        );

        if (isPreviousBorderedEntity) final.push('has-previous-bordered');
      }
    }

    if (isNextSibling) {
      const nextEntry = Editor.leaf(editor, next);

      if (nextEntry) {
        const [nextLeaf] = nextEntry;
        const isNextBorderedEntity = markAttributes.some(
          (attribute) => nextLeaf[attribute]
        );

        if (isNextBorderedEntity) final.push('has-next-bordered');
      }
    }

    return final;
  });
};
