import logo from '../assets/YouTube.png';
import { Parser } from '../types';

const re =
  /^(?:https?:\/\/)?(?:www\.)?youtu\.?be(?:\.com)?\/?(?:watch|embed)?(?:\/|\?)([a-zA-Z0-9_-]{11})?.*$/i;

const Embed: Parser = {
  logo,
  name: 'YouTube',
  parseUrl(url) {
    const match = url.match(re);

    if (!match) return null;

    const parsedURL = new URL(url);
    const params = new URLSearchParams(parsedURL.search);

    const videoId = params.get('v') ?? match[1];
    const startTime = params.get('t') ?? params.get('start');

    if (!videoId) return null;

    const frameSrc = `https://www.youtube.com/embed/${videoId}?modestbranding=1${startTime ? `&start=${startTime}` : ''}`;

    return {
      type: 'YouTube',
      url,
      frameSrc,
      frameAspectRatio: 9 / 16,
      frameBorder: true,
    };
  },
};

export default Embed;
