import React from 'react';
import { BlockRenderElementProps } from '@meisterlabs/slate-react';

import { CodeBlock } from '../types';
import { LanguagePopoverButton } from './LanguagePopoverButton';
import type { Options } from '../middlewares';

export const Code: React.FC<BlockRenderElementProps<CodeBlock> & Options> =
  function (props) {
    const {
      attributes,
      children,
      element,
      noneItemText,
      popoverHeaderText,
      onLanguageChanged,
    } = props;

    return (
      <div {...attributes}>
        <LanguagePopoverButton
          blockKey={element.key}
          language={element.language}
          noneItemText={noneItemText}
          popoverHeaderText={popoverHeaderText}
          onLanguageChanged={onLanguageChanged}
        />
        <code>{children}</code>
      </div>
    );
  };
