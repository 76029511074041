import { createContext } from 'react';

export type PageItem = {
  id: string;
  title: string;
  color: string;
  icon: React.ReactNode;
  url: string;
};

export type LopContextValue = {
  items: PageItem[];
};

export const LopContext = createContext<LopContextValue>({
  items: [],
});

LopContext.displayName = 'LopContext';
