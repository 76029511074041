import logo from '../assets/Miro.png';
import { Parser } from '../types';

const re = /^https:\/\/miro.com\/app\/board\/(.*)$/;

const Embed: Parser = {
  logo,
  name: 'Miro',
  parseUrl(url) {
    const boardId = url.match(re)?.[1];

    if (!boardId) return null;

    return {
      type: 'Miro',
      url,
      frameSrc: `https://miro.com/app/embed/${boardId}`,
      frameAspectRatio: 12 / 16,
      frameBorder: false,
    };
  },
};

export default Embed;
