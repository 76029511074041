import React, { useMemo, useState } from 'react';
import Color from 'tinycolor2';
import { colors } from '@meisterlabs/colors';

const setAlpha = function (color: string, alpha: number) {
  return Color(color).setAlpha(alpha).toRgbString();
};

function Style(props: { color: string; isHovered: boolean }) {
  const { color, isHovered } = props;
  return {
    root: {
      display: 'flex',
      flexDirection: 'row' as const,
      alignItems: 'center',
      marginBottom: 12,
    },
    icon: {
      color,
      width: 32,
      height: 32,
      flexShrink: 0,
      padding: 6,
      boxSizing: 'border-box' as const,
      borderRadius: 8,
      backgroundColor: setAlpha(color, 0.1),
    },
    name: {
      marginLeft: 12,
      borderBottomWidth: 1,
      borderBottomStyle: 'solid' as const,
      borderColor: isHovered ? colors.grey700 : 'transparent',
      color: colors.grey700,
      textDecoration: 'none',
    },
  };
}

export type LopItemProps = {
  title: string;
  color: string;
  icon: React.ReactNode;
  url: string;
  onClick: () => void;
};

export default function LopItem(props: LopItemProps) {
  const { title, color, url, icon, onClick } = props;

  const [isHovered, setIsHovered] = useState(false);

  const style = useMemo(() => {
    return Style({ color, isHovered });
  }, [color, isHovered]);

  // TODO: Accessibility fixes.
  // All the effects and click handlers should be on the <a> tag.
  return (
    <div
      style={style.root}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={onClick}
    >
      <div style={style.icon}>{icon}</div>
      <a
        rel='noopener noreferrer'
        title={title}
        href={url}
        style={style.name}
        onClick={(e) => {
          if (e.metaKey) e.stopPropagation();
          else e.preventDefault();
        }}
      >
        {title}
      </a>
    </div>
  );
}
