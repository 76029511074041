import { EditorContext } from '../EditorContext';

/**
 * Adds a callback to the editor's onCreate event.
 */
export const withOnCreate = function (callback: () => void) {
  const ctx = EditorContext.get();

  ctx.onCreate.push(callback);
};
