import { useContext, useEffect, useRef } from 'react';

import LoadingContext from './LoadingContext';

export const LoadingContextConsumer = function ({
  isLoading,
}: {
  isLoading: boolean;
}) {
  const ref = useRef(false);
  const token = useRef(null);

  const { onLoadStart, onLoadEnd } = useContext(LoadingContext);

  useEffect(function () {
    if (ref.current === isLoading) return;

    ref.current = isLoading;

    if (isLoading) {
      token.current = onLoadStart();
    }

    if (!isLoading) {
      token.current = onLoadEnd(token.current);
    }
  });

  useEffect(() => {
    return () => onLoadEnd(token.current);
  }, []);

  return null;
};
