import React, { useCallback, useEffect, useRef, useState } from 'react';
import Redux from '@meisterlabs/redux';
import { Num } from '@meisterlabs/common';
import { AnimatedView, Icon, Text, View } from '@meisterlabs/knightrider';
import { Resize } from '@meisterlabs/svgs/Tint';
import { colors, fonts, rgba } from '@meisterlabs/ui';
import { Pause, Start } from '@meisterlabs/svgs/Tint';
import RCSlider from 'rc-slider';
import { Resizer } from '@meisterlabs/slate-shared-components';
import { LoadingContextConsumer } from '@meisterlabs/slate-react';

import 'rc-slider/assets/index.css';

interface VideoProps {
  name?: string;
  contentType?: string;
  extension?: string;
  url?: string;
  width?: string | null;
  minWidth?: number;
  readOnly: boolean;
  onClick?: () => void;
  onResize?: (width: string) => void;
}

// TODO: Remove Redux from this file
const styles = Redux.Style.mergeStyles({
  root: Redux.Rules.stretch().cursor('default').userSelect('none'),
  wrapper: Redux.Rules.flexShrink(1)
    .borderRadius(10)
    .overflow('hidden')
    .position('relative')
    // "height: auto" effect for safari
    .alignItems('center')
    .boxShadow({
      spread: 1,
      color: (R) =>
        R.option('showBorder') ? rgba.grey700(0.1) : rgba.white(0.1),
    })
    .boxShadowAnimation({
      duration: 200,
      easing: Redux.Easings.sinussoidalInOut,
    })
    .width((R) => R.option('width')),
  video: Redux.Rules.maxWidth('100%').cursor('pointer'),
  'controls.root': Redux.Rules.height(44)
    .position('absolute')
    .bottom(0)
    .left(0)
    .right(0)
    .alignItems('center')
    .paddingHorizontal(15)
    .backgroundColor(colors.grey100)
    // Doesn't work in presentation mode otherwise.
    .backfaceVisibility('hidden')
    .translateAnimation({
      duration: 200,
      easing: Redux.Easings.sinussoidalInOut,
    })
    .chain(function (R) {
      return R.option('isFooterVisible') ? R.translateY(0) : R.translateY(44);
    }),
  'controls.spacer': Redux.Rules.width(15),
  'controls.playPauseIcon': Redux.Rules.size(24).cursor('pointer'),
  'controls.bigPlayButtonWrapper': Redux.Rules.backgroundColor(rgba.white(0.8))
    .justifyContent('center')
    .alignItems('center')
    .size(100)
    .borderRadius('100%')
    .position('absolute')
    .marginLeft('auto')
    .marginRight('auto')
    .pointerEvents('none')
    .left(0)
    .right(0)
    .boxShadow([
      { v: 1, blur: 2, color: rgba.black(0.01) },
      { v: 2, blur: 8, color: rgba.black(0.05) },
    ]),
  'controls.bigPlayButton': Redux.Rules.size(80).cursor('pointer'),
  'controls.fullScreenIcon': Redux.Rules.size(24)
    .cursor('pointer')
    .color(colors.grey500),
  'controls.title': Redux.Rules.font(fonts.m.regular).flexGrow(1).truncate(),
  'controls.meta': Redux.Rules.font(fonts.s.bold)
    .color(colors.grey500)
    .paddingLeft(15)
    .textTransform('uppercase')
    .whiteSpace('nowrap'),
  'controls.slider.wrapper': Redux.Rules.flexGrow(1),
  'controls.slider.slider': Redux.Rules,
  'controls.slider.rail': Redux.Rules.height(6)
    .borderRadius(3)
    .backgroundColor(rgba.black(0.1)),
  'controls.slider.track': Redux.Rules.height(6)
    .borderRadius(3)
    .backgroundColor(rgba.black(0.2)),
  'controls.slider.handle': Redux.Rules.size(14)
    .backgroundColor('white')
    .borderWidth(0)
    .boxShadow([
      { v: 2, blur: 5, spread: -1, color: rgba.black(0.07) },
      { spread: 1, color: rgba.black(0.07) },
    ]),
  'footer.resizeHandler': Redux.Rules.position('absolute')
    .bottom(4)
    .right(4)
    .cursor('ew-resize'),
  'footer.resizeIcon': Redux.Rules.size(12).color(rgba.black(0.2)),
});

interface FooterProps {
  currentTime: number;
  name: string;
  extension: string;
  isPlaying: boolean;
  style: ReturnType<typeof styles.toJS>;
  readOnly: boolean;
  duration: () => number;
  onClickPlayPause: () => void;
  onSliderChange: (value: number) => void;
  timeLeft: () => string;
}

const Footer = function (props: FooterProps) {
  const {
    currentTime,
    name,
    duration,
    extension,
    isPlaying,
    readOnly = false,
    onClickPlayPause,
    onSliderChange,
    timeLeft,
    style,
  } = props;

  return (
    <AnimatedView style={style.controls.root}>
      <Icon style={style.controls.playPauseIcon} onClick={onClickPlayPause}>
        {!isPlaying && <Start />}
        {isPlaying && <Pause />}
      </Icon>
      <View style={style.controls.spacer} />
      {!isPlaying && <Text style={style.controls.title}>{name}</Text>}
      {isPlaying && (
        <View style={style.controls.slider.wrapper}>
          <RCSlider
            min={0}
            max={duration()}
            step={1}
            value={currentTime}
            onChange={onSliderChange}
            style={style.controls.slider.slider.toJS()}
            trackStyle={style.controls.slider.track.toJS()}
            railStyle={style.controls.slider.rail.toJS()}
            handleStyle={style.controls.slider.handle.toJS()}
          />
        </View>
      )}
      <View style={style.controls.spacer} />
      {!isPlaying && (
        <View>
          <Text style={style.controls.meta}>{extension}</Text>
        </View>
      )}
      {isPlaying && <Text style={style.controls.meta}>{`-${timeLeft()}`}</Text>}
      {!readOnly && (
        <View style={style.footer.resizeHandler}>
          <Resizer.Handle>
            <Icon style={style.footer.resizeIcon}>
              <Resize />
            </Icon>
          </Resizer.Handle>
        </View>
      )}
    </AnimatedView>
  );
};

const Video: React.FC<VideoProps> = (props) => {
  const {
    name = '',
    contentType = '',
    extension = '',
    url = '',
    width: initialWidth = null,
    minWidth = 150,
    onResize = () => null,
    readOnly = false,
  } = props;

  const [currentTime, setCurrentTime] = useState<number>(0);
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [canPlay, setCanPlay] = useState<boolean>(false);
  const videoRef = useRef<HTMLVideoElement | null>(null);

  const handleCanPlay = () => setCanPlay(true);

  const updateCurrentTime = () => {
    if (videoRef.current) {
      setCurrentTime(videoRef.current.currentTime * 1000);
    }
  };

  const handleReachEnd = () => {
    setIsPlaying(false);

    if (videoRef.current) {
      videoRef.current.removeEventListener('timeupdate', updateCurrentTime);
    }
  };

  const duration = () => {
    return videoRef.current ? Math.ceil(videoRef.current.duration) * 1000 : 0;
  };

  const timeLeft = () => {
    return Num.of(duration())
      .subtract(currentTime)
      .toDurationStr({ compactHours: true })
      .get();
  };

  const onMouseOver = () => setIsHovered(true);
  const onMouseLeave = () => setIsHovered(false);

  const onClickPlayPause = useCallback(() => {
    if (isPlaying) {
      videoRef.current.pause();
      setIsPlaying(false);
      videoRef.current.removeEventListener('timeupdate', updateCurrentTime);
    } else {
      videoRef.current.play();
      setIsPlaying(true);
      videoRef.current.addEventListener('timeupdate', updateCurrentTime);
    }
  }, [isPlaying, setIsPlaying]);

  const onSliderChange = (value) => {
    setCurrentTime(value);
    videoRef.current.currentTime = value / 1000;
  };

  useEffect(() => {
    const videoElement = videoRef.current;

    if (videoElement) {
      videoElement.addEventListener('canplay', handleCanPlay);
      videoElement.addEventListener('ended', handleReachEnd);
    }

    return () => {
      if (videoElement) {
        videoElement.removeEventListener('canplay', handleCanPlay);
        videoElement.removeEventListener('ended', handleReachEnd);
        videoElement.removeEventListener('timeupdate', updateCurrentTime);
      }
    };
  }, []);

  return (
    <Resizer.Horizontal
      initialWidth={initialWidth}
      minWidth={minWidth}
      setNewWidth={onResize}
    >
      {function ({
        isResizing,
        width,
        rootRefCallback,
        naturalWidthRefCallback,
        targetRefCallback,
      }) {
        const style = styles.toJS({
          isFooterVisible: isHovered && !isResizing,
          showBorder: isHovered || isResizing,
        });

        return (
          <View
            style={style.root}
            onElement={rootRefCallback}
            onMouseOver={onMouseOver}
            onMouseLeave={onMouseLeave}
          >
            <LoadingContextConsumer isLoading={!canPlay} />
            <AnimatedView
              style={style.wrapper.mergeOptions({
                width,
              })}
              onElement={targetRefCallback}
            >
              <video
                onClick={onClickPlayPause}
                style={style.video.toJS()}
                ref={(node) => {
                  videoRef.current = node;
                  naturalWidthRefCallback(node);
                }}
              >
                <source src={url} type={contentType} />
              </video>
              {!isPlaying && canPlay && (
                <View style={style.controls.bigPlayButtonWrapper}>
                  <Icon style={style.controls.bigPlayButton}>
                    <Start />
                  </Icon>
                </View>
              )}
              <Footer
                currentTime={currentTime}
                extension={extension}
                name={name}
                isPlaying={isPlaying}
                style={style}
                duration={duration}
                onClickPlayPause={onClickPlayPause}
                onSliderChange={onSliderChange}
                timeLeft={timeLeft}
                readOnly={readOnly}
              />
            </AnimatedView>
          </View>
        );
      }}
    </Resizer.Horizontal>
  );
};

export default Video;
