import { createContext } from 'react';

export interface ResizeContextState {
  handleResize: (props: { x: number; y: number }) => void;
  handleResizeStart: () => void;
  handleResizeStop: () => void;
}

export const ResizeContext = createContext<ResizeContextState>({
  handleResize: () => null,
  handleResizeStart: () => null,
  handleResizeStop: () => null,
});

ResizeContext.displayName = 'ResizeContext';
