import React, { useMemo, useState } from 'react';
import * as Popover from '@radix-ui/react-popover';
import { useReadOnly } from '@meisterlabs/slate-react';
import { colors } from '@meisterlabs/colors';
import { IconButton } from '@meisterlabs/ui';

import {
  IconSelectorContentProps,
  IconSelectorContext,
  IconSelectorPopoverContent,
  ListItem,
} from './IconSelectorPopoverContent';

interface PropsPopover extends Omit<IconSelectorContentProps, 'close'> {
  iconList: ListItem[];
  colorList: ListItem[];
  iconColor: string;
}

const style = {
  popover: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    flexShrink: 1,
    minWidth: 300,
    backgroundColor: colors.white,
    borderRadius: 15,
    boxShadow:
      '0 10px 30px -10px rgba(0, 0, 0, 0.15), 0 0 1px 0 rgba(0, 0, 0, 0.07)',
  },
  icon: IconButton.defaultProps.style
    .mapMergeOptions(function ({ iconColor }) {
      return {
        textColor: iconColor,
        backgroundColor: 'transparent',
      };
    })
    .mapStyle('wrapper', function (Rules) {
      return Rules.when(
        (Rules) => Rules.option('disabled'),
        function (Rules) {
          return Rules.cursor('default').opacity(1);
        }
      );
    }),
} as const;

export const IconSelectorPopoverButton: React.FC<PropsPopover> = function (
  props
) {
  const {
    children,
    colorSet,
    iconSet,
    iconList,
    colorList,
    iconPickerHeader,
    iconColor,
    onSelectColor,
    onSelectIcon,
  } = props;

  const readOnly = useReadOnly();
  const [open, setOpen] = useState(false);

  const iconStyle = useMemo(() => {
    return style.icon.mergeOptions({
      disabled: readOnly,
      iconColor,
      isOpen: open,
    });
  }, [iconColor, readOnly, open]);

  return (
    <IconSelectorContext.Provider value={{ iconList, colorList }}>
      <Popover.Root open={!readOnly && open}>
        <Popover.Trigger
          style={{
            border: 'none',
            background: 'none',
            padding: 0,
          }}
        >
          <IconButton
            onClick={() => setOpen(true)}
            disabled={readOnly}
            style={iconStyle}
          >
            {children}
          </IconButton>
        </Popover.Trigger>
        <Popover.Portal>
          <Popover.Content
            collisionPadding={20}
            onPointerDownOutside={() => setOpen(false)}
          >
            <div style={style.popover}>
              <IconSelectorPopoverContent
                close={() => setOpen(false)}
                colorSet={colorSet}
                iconSet={iconSet}
                iconPickerHeader={iconPickerHeader}
                onSelectColor={onSelectColor}
                onSelectIcon={onSelectIcon}
              />
            </div>
          </Popover.Content>
        </Popover.Portal>
      </Popover.Root>
    </IconSelectorContext.Provider>
  );
};
