import {
  BlockElement,
  Editor,
  Path,
  Range,
  Transforms,
  isBlockElement,
} from '@meisterlabs/slate';
import { withDeleteBackward } from '@meisterlabs/slate-react';

/**
 * Middleware to append the content of the current block to the previous block
 * when the current block is deleted through a backwards deletion and the previous block is empty.
 * This helps for example when having an empty icon block that it isn't deleted
 * but the content of the current block is appended to it.
 */
export const withAppendToPreviousBlockOnDelete = () => {
  withDeleteBackward(function (editor) {
    const { selection } = editor;

    if (!selection) return;
    if (Range.isExpanded(selection)) return;
    // Has to be at the beginning of the block
    if (selection.focus.offset !== 0 || Path.hasPrevious(selection.focus.path))
      return;

    const [, startPath] = Editor.node(editor, selection.anchor);
    const parentPath = Path.parent(startPath);

    const previousNodeEntry = Editor.previous<BlockElement>(editor, {
      at: parentPath,
      match: (n) => isBlockElement(n),
    });

    if (!previousNodeEntry) return;

    const [previousNode, previousPath] = previousNodeEntry;

    if (Editor.isEmpty(editor, previousNode)) {
      Editor.withoutNormalizing(editor, () => {
        // Since it is harder moving the children to the previous node, we just update the current note
        // with the type and data of the previous node
        Transforms.setNodes(
          editor,
          {
            ...previousNode,
          },
          { at: parentPath }
        );

        // And then remove the previous node
        Transforms.removeNodes(editor, { at: previousPath });
      });

      return false;
    }
  });
};
