import React, { useEffect } from 'react';
import { Transforms } from '@meisterlabs/slate';
import {
  BlockRenderElementProps,
  useSlateStatic,
} from '@meisterlabs/slate-react';

import * as Parsers from '../parsers';
import { Frame } from '../components/Frame';
import { EmbedBlock, Parser, type EmbedType } from '../types';

export const Embed: React.FC<BlockRenderElementProps<EmbedBlock>> = function ({
  attributes,
  children,
  element,
}) {
  const { embedType, embedUrl } = element;

  const editor = useSlateStatic();

  const parser = (Parsers as Record<EmbedType, Parser>)[embedType];
  const data = parser?.parseUrl(embedUrl);

  useEffect(() => {
    if (!parser || !data) {
      Transforms.setNodes<EmbedBlock>(
        editor,
        {
          embedType: undefined,
          embedUrl: undefined,
        },
        {
          at: [],
          match: (node) => (node as EmbedBlock).key === element.key,
        }
      );
    }
  }, [parser, data]);

  if (!data || !parser) return null;

  return (
    <div {...attributes} contentEditable={false}>
      {children}
      <Frame
        title={parser.name}
        src={data.frameSrc}
        height={data.frameHeight}
        aspectRatio={data.frameAspectRatio}
        useBorder={data.frameBorder}
      />
    </div>
  );
};
