import { Editor, Range } from '@meisterlabs/slate';
import { withHotKey } from '@meisterlabs/slate-react';

const deleteHotkey = 'shift?+backspace';

export const withDeleteFragmentWhenAllSelected = function () {
  withHotKey(deleteHotkey, function (event, editor) {
    const { selection } = editor;

    if (selection && Range.isExpanded(selection)) {
      event.preventDefault();

      Editor.deleteFragment(editor, { direction: 'backward' });

      return false;
    }

    return;
  });
};
