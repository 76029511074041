import { Path, Point, Text } from 'slate';

import { BlockElement } from '../types';

interface ReduceAccValue {
  anchor: Point | null;
  focus: Point | null;
  currentOffset: number;
}

/**
 * Get the Range from a start and offset inside a Slate TextList
 */
export const getRangeFromOffsetsInTextList = function (
  node: BlockElement,
  path: Path,
  startOffset: number,
  endOffset: number
) {
  if (!Text.isTextList(node.children)) return null;

  const { anchor, focus } = node.children.reduce<ReduceAccValue>(
    function (acc, textNode, index) {
      if (!!acc.anchor && !!acc.focus) return acc;

      const newOffset = acc.currentOffset + textNode.text.length;

      if (!acc.anchor && startOffset <= newOffset) {
        acc.anchor = {
          offset: startOffset - acc.currentOffset,
          path: [...path, index],
        };
      }

      if (!acc.focus && endOffset <= newOffset) {
        acc.focus = {
          offset: endOffset - acc.currentOffset,
          path: [...path, index],
        };
      }

      acc.currentOffset = newOffset;

      return acc;
    },
    {
      anchor: null,
      focus: null,
      currentOffset: 0,
    }
  );

  return {
    anchor,
    focus,
  };
};
