import React from 'react';
import { removeUnusedProperties } from '@meisterlabs/slate';
import {
  withElementNormalizer,
  withElementRenderer,
  withIsVoid,
} from '@meisterlabs/slate-react';

import { EmptyMMBlock, MMBlock, isEmptyMMBlock, isMMBlock } from '../types';
import { MindMeister } from '../components/MindMeister';
import { Placeholder } from '../components/Placeholder';

export interface Options {
  placeholder: string;
  appKey: string;
  resourcesDomain: string;
  pickerUrl: string;
  footerMetaText: string;
  isMMPaid: boolean;
  fetchFile: (url: string) => Promise<Blob>;
  onSelectedMmMap?: (mapId: string) => void;
  onRepositionedMmMap?: (scale: number, x: number, y: number) => void;
}

export const withMindMeister = function (options?: Options) {
  withElementRenderer<MMBlock>(
    (element) => isMMBlock(element),
    (prop) => <MindMeister {...prop} {...options} />
  );

  withElementRenderer<EmptyMMBlock>(
    (element) => isEmptyMMBlock(element),
    (props) => <Placeholder {...props} {...options} />
  );

  withElementNormalizer<MMBlock>(
    (element) => isMMBlock(element),
    (element, path, editor) =>
      removeUnusedProperties(editor, element, path, [
        'mapId',
        'mapRootId',
        'mapTitle',
        'scale',
        'x',
        'y',
        'heightDelta',
      ])
  );

  withIsVoid<MMBlock>((element) => isMMBlock(element));

  withIsVoid<MMBlock>((element) => isEmptyMMBlock(element));
};
