import React, { useEffect, useRef, useState } from 'react';
import { BlockElement, Transforms } from '@meisterlabs/slate';
import {
  BlockRenderElementProps,
  useReadOnly,
  useSlateStatic,
} from '@meisterlabs/slate-react';
import { Embed } from '@meisterlabs/svgs/Tint';

import * as Parsers from '../parsers';
import { parseUrl } from '../utils/parseUrl';
import { EmbedTypePickerPopoverButton } from './EmbedTypePickerPopover';
import { EmbedBlock, EmbedBlockEmpty, Parser, type EmbedType } from '../types';
import style from './configuration.module.css';
import { clsx } from 'clsx';

type Props = BlockRenderElementProps<EmbedBlockEmpty> & {
  placeholderForType: (type: string) => string;
  defaultPlaceholder: string;
  typePickerHeader: string;
  onEmbedContent?: (embedType: EmbedType, embedUrl?: string) => void;
};

export const Configuration: React.FC<Props> = function (props) {
  const {
    attributes,
    children,
    defaultPlaceholder,
    element,
    placeholderForType,
    typePickerHeader,
    onEmbedContent,
  } = props;

  const [url, setUrl] = useState<string>('');
  const [type, setType] = useState<EmbedType | null>(null);
  const editor = useSlateStatic();
  const readOnly = useReadOnly();
  const castedParsers = Parsers as Record<string, Parser>;
  const inputRef = useRef<HTMLInputElement>(null);

  const reFocus = () => {
    if (inputRef.current) inputRef.current.focus();
  };

  const onSubmit = () => {
    if (!type || !url) return;

    onEmbedContent?.(type, url);

    Transforms.setNodes<EmbedBlock>(
      editor,
      {
        embedType: type,
        embedUrl: url,
      },
      {
        at: [],
        match: (n: BlockElement) => n.key === element.key,
      }
    );
  };

  useEffect(() => onSubmit(), [type, url]);
  const onSelectType = (type: EmbedType) => setType(type);

  const updateEmbeding = (url: string) => {
    const type = parseUrl(url)?.type ?? null;

    setType(type);
    setUrl(url);
  };

  const onChangeUrl = (event: React.ChangeEvent<HTMLInputElement>) => {
    const url = event.target.value.trim();

    updateEmbeding(url);
  };

  const onKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') onSubmit();
  };

  const onPaste = (event: React.ClipboardEvent) => {
    event.preventDefault();

    const url = event.clipboardData.getData('text');

    updateEmbeding(url);
  };

  const icon =
    type && castedParsers[type] ? (
      <img className={style.image} src={castedParsers[type].logo} />
    ) : (
      <Embed />
    );

  const placeholder = type
    ? placeholderForType(castedParsers[type].name)
    : defaultPlaceholder;

  return (
    <div
      {...attributes}
      contentEditable={false}
      className={clsx(attributes.className, style.root)}
    >
      <div className={style.icon}>
        <EmbedTypePickerPopoverButton
          onSelectType={onSelectType}
          header={typePickerHeader}
          readOnly={readOnly}
          reFocus={reFocus}
        >
          {icon}
        </EmbedTypePickerPopoverButton>
      </div>
      <div className={style.inputRoot}>
        <input
          ref={inputRef}
          readOnly={readOnly}
          value={url}
          onChange={onChangeUrl}
          onPaste={onPaste}
          onBlur={onSubmit}
          onKeyDown={onKeyDown}
          placeholder={placeholder}
          className={style.input}
        />
      </div>
      {children}
    </div>
  );
};
