import { Text } from 'slate';

import { BlockElement } from '../types';

export const collectTextOffsets = function (node: BlockElement) {
  let offset = 0;

  if (!Text.isTextList(node.children)) return null;

  return node.children.map(function (textNode, index) {
    const length = textNode.text.length;

    offset += length;

    const result = {
      index,
      length,
      offset,
    };

    return result;
  });
};
