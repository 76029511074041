import {
  HAST,
  SAST,
  withHtmlDeserializer,
  withHtmlSerializer,
  withMarkdownSerializer,
} from '@meisterlabs/slate-serializer';

interface EmbedBlock extends SAST.Block {
  type: 'embed';
  properties: {
    embedUrl: string;
    embedType: string;
  };
}

export const withEmbedSerializers = function () {
  withHtmlSerializer<EmbedBlock>(
    (_, node) => node.type === 'embed',
    ({ multiple }, node) => {
      return {
        type: 'element',
        tagName: 'div',
        properties: {
          dataEmbedBlock: 'true',
          dataUrl: node.properties.embedUrl,
          dataType: node.properties.embedType,
        },
        children: multiple<SAST.Content, HAST.Element>(node.children, node),
      };
    }
  );

  withHtmlDeserializer<HAST.Element>(
    (_, node) =>
      node.tagName === 'div' && node.properties?.dataEmbedBlock === 'true',
    (_, node) => {
      return {
        type: 'embed',
        properties: {
          embedType: node.properties.dataType,
          embedUrl: node.properties.dataUrl,
        },
        children: [{ type: 'text', value: '' }],
      };
    }
  );

  // Since markdown doesn't support embeds natively, we serialize them as links
  // however, this means that we can't deserialize them back to embeds since they would be links
  withMarkdownSerializer<EmbedBlock>(
    (_, node) => node.type === 'embed',
    (_, node) => {
      return {
        type: 'link',
        url: node.properties.embedUrl,
        title: node.properties.embedType,
        children: [{ type: 'text', value: '' }],
      };
    }
  );
};
