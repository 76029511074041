import React, { useEffect, useState } from 'react';
import { createNanoEvents } from 'nanoevents';
import {
  EditableContext,
  ReactEditor,
  withEditableWrapper,
} from '@meisterlabs/slate-react';

import '../style.css';
import { SupportedLanguages } from '../types';
import { SpellcheckRoot } from '../components/SpellcheckRoot';

export type setLanguage = (language: string) => void;

export interface Options {
  addToDictionaryText: string;
  ignoreText: string;
  onSelectedSpellcheckSuggestion?: (
    suggestion: string,
    word: string,
    language: SupportedLanguages
  ) => void;
}

export const events = createNanoEvents();

export type SpellcheckEditor = ReactEditor & {
  spellcheck: {
    language?: SupportedLanguages;
    setLanguage?: (language: SupportedLanguages) => void;
  };
};

export const withSpellCheck = function (options: Options) {
  const { editor } = EditableContext.get();

  (editor as SpellcheckEditor).spellcheck = {
    language: null,
    setLanguage: (language: SupportedLanguages) => {
      (editor as SpellcheckEditor).spellcheck.language = language;

      events.emit('update');
    },
  };

  withEditableWrapper((props) => {
    const [language, setLanguage] = useState<SupportedLanguages>(
      (editor as SpellcheckEditor).spellcheck.language
    );

    useEffect(() => {
      const unbind = events.on('update', () => {
        setLanguage((editor as SpellcheckEditor).spellcheck.language);
      });

      return () => unbind();
    }, []);

    return <SpellcheckRoot {...props} {...options} language={language} />;
  });
};
