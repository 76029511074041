import { useEffect, useRef } from 'react';

const startScrolling = (element: Element, speed: number, distance: number) => {
  const interval = setInterval(() => element.scrollBy(0, distance), speed);

  return () => clearInterval(interval);
};

// Since we don't know which container is actually scrolling the editor, we need to find it
// Taken from here: https://github.com/olahol/scrollparent.js/blob/master/scrollparent.js#L13
const getScrollParent = (node): Element => {
  const regex = /(auto|scroll)/;
  const parents = (_node, ps) => {
    if (_node.parentNode === null) {
      return ps;
    }
    return parents(_node.parentNode, ps.concat([_node]));
  };

  const style = (_node, prop) =>
    getComputedStyle(_node, null).getPropertyValue(prop);
  const overflow = (_node) =>
    style(_node, 'overflow') +
    style(_node, 'overflow-y') +
    style(_node, 'overflow-x');
  const scroll = (_node) => regex.test(overflow(_node));

  const scrollParent = (_node) => {
    if (!(_node instanceof HTMLElement || _node instanceof SVGElement)) {
      return;
    }

    const ps = parents(_node.parentNode, []);

    for (let i = 0; i < ps.length; i += 1) {
      if (scroll(ps[i])) {
        return ps[i];
      }
    }

    return document.scrollingElement || document.documentElement;
  };

  return scrollParent(node);
};

export const useAutoScroller = function (clientOffset) {
  const intervalRef = useRef(null);

  useEffect(() => {
    if (!clientOffset) return;

    // If the clientOffset is at the very top or bottom of the editor rect auto scroll
    const editor = document.querySelector(
      '[data-slate-editor][role="textbox"]'
    );
    const scrollSpeed = 10;
    const scrollDistance = 10;

    const scrollParent = getScrollParent(editor);

    const topBound = scrollParent.clientTop + 200;
    const bottomBound =
      scrollParent.clientTop + scrollParent.clientHeight - 200;

    if (intervalRef.current) {
      intervalRef.current();
      intervalRef.current = null;
    }

    if (clientOffset.y < topBound) {
      intervalRef.current = startScrolling(
        scrollParent,
        scrollSpeed,
        -scrollDistance
      );
    } else if (clientOffset.y > bottomBound) {
      intervalRef.current = startScrolling(
        scrollParent,
        scrollSpeed,
        scrollDistance
      );
    }

    return () => {
      if (!intervalRef.current) return;

      intervalRef.current();
      intervalRef.current = null;
    };
  }, [clientOffset]);
};
