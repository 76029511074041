import { languages } from './languages';
import { hasLanguage } from './hasLanguage';
import { SupportedLanguages } from '../../types';

const cache = new Map();

export const loadForLanguage = function (language: SupportedLanguages) {
  if (!hasLanguage(language)) {
    throw new Error(`Unsupported language "${language}.`);
  }

  if (cache.has(language)) {
    return cache.get(language);
  }

  const promise = languages[language]();

  cache.set(language, promise);

  return promise;
};
