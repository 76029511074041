import {
  HAST,
  MDAST,
  SAST,
  withHtmlDeserializer,
  withHtmlSerializer,
  withMarkdownDeserializer,
  withMarkdownSerializer,
} from '@meisterlabs/slate-serializer';

interface ParagraphBlock extends SAST.Block {
  type: 'paragraph';
}

export const withParagraphSerializers = function () {
  withHtmlSerializer<ParagraphBlock>(
    (_, node) => node.type === 'paragraph',
    ({ multiple }, node) => {
      return {
        type: 'element',
        tagName: 'p',
        children: multiple<SAST.Content, HAST.Element>(node.children, node),
      };
    }
  );

  withHtmlDeserializer<HAST.Element>(
    (_, node) => node.tagName === 'p',
    ({ multiple }, node) => {
      return {
        type: 'paragraph',
        properties: {},
        children: multiple<HAST.Content, SAST.Content>(node.children, node),
      };
    }
  );

  withMarkdownSerializer<ParagraphBlock>(
    (_, node) => node.type === 'paragraph',
    ({ multiple }, node) => {
      return {
        type: 'paragraph',
        children: multiple<SAST.Content, MDAST.Text>(node.children, node),
      };
    }
  );

  withMarkdownDeserializer<MDAST.Paragraph, SAST.Node, MDAST.Content>(
    (_, node) => node.type === 'paragraph',
    ({ multiple }, node, parent) => {
      if (parent?.type === 'blockquote') return multiple(node.children, node);

      return {
        type: 'paragraph',
        properties: {},
        children: multiple<MDAST.Content, SAST.Content>(node.children, node),
      };
    }
  );
};
