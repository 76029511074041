import {
  withInlineStyle,
  withInlineStyleSerialization,
} from './withInlineStyle';

/**
 * Enable inline style italic with a shortcut.
 */
export const withInlineItalic = function (shortcut: string) {
  withInlineStyle(shortcut, 'italic');
};

export const withInlineItalicSerialization = function () {
  withInlineStyleSerialization('italic', {
    html: {
      tag: 'em',
      alias: ['i'],
    },
    markdown: {
      type: 'emphasis',
    },
  });
};
