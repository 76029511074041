import type { Node, Text } from 'slate';
import type { BlockElement } from '@meisterlabs/slate';

import { isHotKey } from './utils';
import { EditableContext } from './EditableContext';

import type {
  BlockRenderElementProps,
  ForceUpdateFunction,
  DecoratorFunction,
  DeleteBackwardFunction,
  ElementAttributeMiddleware,
  ElementChecker,
  ElementClassMiddleware,
  ElementNormalizer,
  ElementNormalizerChecker,
  InsertBreakFunction,
  InsertDataFunction,
  InsertTextFunction,
  LeafAttributeMiddleware,
  LeafChecker,
  LeafClassMiddleware,
  LeafRenderProps,
  LeafTagMiddleware,
  OnKeyDownFunction,
  ElementWrapper,
  OnDropFunction,
  LeafWrapper,
  OnClipboardFunction,
  OnReadyFunction,
  DeleteFragmentFunction,
} from './types';

/**
 * This middleware allows you to compose the `decorate` prop, which can be used to add additional attributes to leaf nodes without having them be part of the actual state (autolinkifier, syntax highlighting, etc.).
 * @see {@link https://github.com/MeisterLabs/meisternote-slate/blob/master/packages/slate-react/docs/withDecorator.md}
 */
export const withDecorator = function <
  InputNode extends Node,
  OutputAttributes,
>(decorator: DecoratorFunction<InputNode, OutputAttributes>) {
  const ctx = EditableContext.get();

  ctx.decorators.push(decorator);
};

/**
 * This middleware provides a function that when called force re-renders the whole editor.
 * @see {@link https://github.com/MeisterLabs/meisternote-slate/blob/master/packages/slate-react/docs/withForceUpdater.md}
 */
export const withForceUpdater = function (
  forceFn: (fn: ForceUpdateFunction) => void
) {
  const ctx = EditableContext.get();

  forceFn(ctx.forceUpdate);
};

/**
 * This middleware allows you to have a last say about the `{...attributes}` that will get spread onto the root of each element component.
 * @see {@link https://github.com/MeisterLabs/meisternote-slate/blob/master/packages/slate-react/docs/withElementAttributes.md}
 */
export const withElementAttributes = function <T extends BlockElement>(
  fn: ElementAttributeMiddleware<T>
) {
  const ctx = EditableContext.get();

  ctx.elementAttributes.push(fn);
};

/**
 * This middleware allows you to customize the `className` prop of element components.
 * @see {@link https://github.com/MeisterLabs/meisternote-slate/blob/master/packages/slate-react/docs/withElementClassNames.md}
 */
export const withElementClassNames = function <T extends BlockElement>(
  fn: ElementClassMiddleware<T>
) {
  const ctx = EditableContext.get();

  ctx.elementClassNames.push(fn);
};

/**
 * This middleware allows you to define the component that should be used for each element.
 * @see {@link https://github.com/MeisterLabs/meisternote-slate/blob/master/packages/slate-react/docs/withElementRenderer.md}
 */
export const withElementRenderer = function <T extends BlockElement>(
  check: ElementChecker<T>,
  Element: React.FC<BlockRenderElementProps<T>>
) {
  const ctx = EditableContext.get();

  ctx.elementRenderers.push([check, Element]);
};

/**
 * This middleware allows you to have a last say about the `{...attributes}` that will get spread onto the root of each leaf component.
 * @see {@link https://github.com/MeisterLabs/meisternote-slate/blob/master/packages/slate-react/docs/withLeafAttributes.md}
 */
export const withLeafAttributes = function <T extends Text>(
  fn: LeafAttributeMiddleware<T>
) {
  const ctx = EditableContext.get();

  ctx.leafAttributes.push(fn);
};

/**
 * This middleware allows you to customize the `className` prop of leaf components.
 * @see {@link https://github.com/MeisterLabs/meisternote-slate/blob/master/packages/slate-react/docs/withLeafClassNames.md}
 */
export const withLeafClassNames = function <T extends Text>(
  fn: LeafClassMiddleware<T>
) {
  const ctx = EditableContext.get();

  ctx.leafClassNames.push(fn);
};

/**
 * This middleware allows you to define the component that should be used for each element.
 * @see {@link https://github.com/MeisterLabs/meisternote-slate/blob/master/packages/slate-react/docs/withLeafRenderer.md}
 */
export const withLeafRenderer = function <T extends Text>(
  check: LeafChecker<T>,
  Leaf: React.FC<LeafRenderProps<T>>
) {
  const ctx = EditableContext.get();

  ctx.leafRenderers.push([check, Leaf]);
};

/**
 * This middleware allows you to customize the `tagName` prop of leaf components.
 * @see {@link https://github.com/MeisterLabs/meisternote-slate/blob/master/packages/slate-react/docs/withLeafTagName.md}
 */
export const withLeafTagName = function (fn: LeafTagMiddleware) {
  const ctx = EditableContext.get();

  ctx.leafTagNames.push(fn);
};

/**
 * This middleware allows you to add wrappers for all leafs.
 * @see {@link https://github.com/MeisterLabs/meisternote-slate/blob/master/packages/slate-react/docs/withLeafWrapper.md}
 */
export const withLeafWrapper = function <T extends Text>(
  LeafWrapper: LeafWrapper<T>
) {
  const ctx = EditableContext.get();

  ctx.leafWrappers.push(LeafWrapper);
};

/**
 * This middleware allows you compose `keydown` event handlers.
 * @see {@link https://github.com/MeisterLabs/meisternote-slate/blob/master/packages/slate-react/docs/withOnKeyDown.md}
 */
export const withOnKeyDown = function (fn: OnKeyDownFunction) {
  const ctx = EditableContext.get();

  ctx.onKeyDowns.push(fn);
};

/**
 * This middleware combines the `withOnKeyDown` middleware with the isHotKey utils for convenience, otherwise the same rules apply.
 * @see {@link https://github.com/MeisterLabs/meisternote-slate/blob/master/packages/slate-react/docs/withHotKey.md}
 */
export const withHotKey = function (
  hotKey: string,
  callback: OnKeyDownFunction
) {
  withOnKeyDown(function (event, editor) {
    if (!isHotKey(hotKey, event)) return;
    return callback(event, editor);
  });
};

/**
 * This middleware allows you compose `onDrop` event handlers.
 * @see {@link https://github.com/MeisterLabs/meisternote-slate/blob/master/packages/slate-react/docs/withOnDrop.md}
 */
export const withOnDrop = function (fn: OnDropFunction) {
  const ctx = EditableContext.get();

  ctx.onDrops.push(fn);
};

/**
 * This middleware allows you compose `onPaste` event handlers.
 * @see {@link https://github.com/MeisterLabs/meisternote-slate/blob/master/packages/slate-react/docs/withOnDrop.md}
 */
export const withOnPaste = function (fn: OnClipboardFunction) {
  const ctx = EditableContext.get();

  ctx.onPastes.push(fn);
};

/**
 * This middleware allows you compose `onCopy` event handlers.
 * @see {@link https://github.com/MeisterLabs/meisternote-slate/blob/master/packages/slate-react/docs/withOnCopy.md}
 */
export const withOnCopy = function (fn: OnClipboardFunction) {
  const ctx = EditableContext.get();

  ctx.onCopies.push(fn);
};

/**
 * This middleware allows you compose `onCut` event handlers.
 * @see {@link https://github.com/MeisterLabs/meisternote-slate/blob/master/packages/slate-react/docs/withOnCut.md}
 */
export const withOnCut = function (fn: OnClipboardFunction) {
  const ctx = EditableContext.get();

  ctx.onCuts.push(fn);
};

/**
 * Allows you to set the editor to readonly mode.
 * @see {@link https://github.com/MeisterLabs/meisternote-slate/blob/master/packages/slate-react/docs/withOnKeyDown.md}
 */
export const withReadOnly = function (readOnly: boolean) {
  const ctx = EditableContext.get();

  ctx.readOnly = readOnly;
};

/**
 * This middleware allows you to customize the default leaf component that should be used.
 * @see {@link https://github.com/MeisterLabs/meisternote-slate/blob/master/packages/slate-react/docs/withDefaultLeaf.md}
 */
export const withDefaultLeaf = function (DefaultLeaf: React.FC) {
  const ctx = EditableContext.get();

  ctx.DefaultLeaf = DefaultLeaf;
};

/**
 * This middleware allows you to customize the default element component that should be used.
 * @see {@link https://github.com/MeisterLabs/meisternote-slate/blob/master/packages/slate-react/docs/withDefaultElement.md}
 */
export const withDefaultElement = function (DefaultElement: React.FC) {
  const ctx = EditableContext.get();

  ctx.DefaultElement = DefaultElement;
};

/**
 * This middleware allows you to define normalization for a specific element.
 * @see {@link https://github.com/MeisterLabs/meisternote-slate/blob/master/packages/slate-react/docs/withElementNormalizer.md}
 */
export const withElementNormalizer = function <T extends BlockElement>(
  check: ElementNormalizerChecker<T>,
  normalizer: ElementNormalizer<T>
) {
  const ctx = EditableContext.get();

  ctx.elementNormalizers.push([check, normalizer]);
};

/**
 * This middleware allows you to define a component that should be rendered inside the Slate context but above the `Editable` component.
 * @see {@link https://github.com/MeisterLabs/meisternote-slate/blob/master/packages/slate-react/docs/withTopElement.md}
 */
export const withTopElement = function (TopElement: React.FC<unknown>) {
  const ctx = EditableContext.get();

  ctx.topElements.push(TopElement);
};

/**
 * This middleware allows you to define elements as being void.
 * @see {@link https://github.com/MeisterLabs/meisternote-slate/blob/master/packages/slate-react/docs/withIsVoid.md}
 */
export const withIsVoid = function <T extends BlockElement>(
  fn: ElementChecker<T>
) {
  const ctx = EditableContext.get();

  ctx.isVoids.push(fn);
};

/**
 * This middleware allows you to define elements as being inline.
 * @see {@link https://github.com/MeisterLabs/meisternote-slate/blob/master/packages/slate-react/docs/withIsInline.md}
 */
export const withIsInline = function <T extends BlockElement>(
  fn: ElementChecker<T>
) {
  const ctx = EditableContext.get();

  ctx.isInlines.push(fn);
};

/**
 * This middleware allows you to add wrappers for all elements.
 * @see {@link https://github.com/MeisterLabs/meisternote-slate/blob/master/packages/slate-react/docs/withElementWrapper.md}
 */
export const withElementWrapper = function (ElementWrapper: ElementWrapper) {
  const ctx = EditableContext.get();

  ctx.elementWrappers.push(ElementWrapper);
};

/**
 * This middleware allows you to add functions that run on the insertText API from slate
 * @see {@link https://github.com/MeisterLabs/meisternote-slate/blob/master/packages/slate-react/docs/withInsertText.md}
 */
export const withInsertText = function (
  insertTextFunction: InsertTextFunction
) {
  const ctx = EditableContext.get();

  ctx.insertTexts.push(insertTextFunction);
};

/**
 * This middleware allows you to add functions that run on the insertBreak API from slate
 * @see {@link https://github.com/MeisterLabs/meisternote-slate/blob/master/packages/slate-react/docs/withInsertBreak.md}
 */
export const withInsertBreak = function (
  insertBreakFunction: InsertBreakFunction
) {
  const ctx = EditableContext.get();

  ctx.insertBreaks.push(insertBreakFunction);
};

/**
 * This middleware allows you to add functions that run on the deleteBackwards API from slate
 * @see {@link https://github.com/MeisterLabs/meisternote-slate/blob/master/packages/slate-react/docs/withDeleteBackward.md}
 */
export const withDeleteBackward = function (
  deleteBackwardFunction: DeleteBackwardFunction
) {
  const ctx = EditableContext.get();

  ctx.deleteBackwards.push(deleteBackwardFunction);
};

/**
 * This middleware allows you to add functions that run on the deleteFragment API from slate
 * @see {@link https://github.com/MeisterLabs/meisternote-slate/blob/master/packages/slate-react/docs/withDeleteFragment.md}
 * @deprecated patch editor.deleteFragment instead
 */
export const withDeleteFragment = function (
  deleteFragmentFunction: DeleteFragmentFunction
) {
  const ctx = EditableContext.get();

  ctx.deleteFragments.push(deleteFragmentFunction);
};

/**
 * This middleware allows you to add functions that run on the insertData API from slate
 * @see {@link https://github.com/MeisterLabs/meisternote-slate/blob/master/packages/slate-react/docs/withInsertData.md}
 */
export const withInsertData = function (
  insertDataFunction: InsertDataFunction
) {
  const ctx = EditableContext.get();

  ctx.insertDatas.push(insertDataFunction);
};

/**
 * This middleware allows you to add wrappers around slates Editable component.
 * @see {@link https://github.com/MeisterLabs/meisternote-slate/blob/master/packages/slate-react/docs/withEditableWrapper.md}
 */
export const withEditableWrapper = function (EditableWrapper: React.FC) {
  const ctx = EditableContext.get();

  ctx.editableWrappers.push(EditableWrapper);
};

/**
 * This middleware allows you to listen when the editor is completely rendered.
 * @see {@link https://github.com/MeisterLabs/meisternote-slate/blob/master/packages/slate-react/docs/withOnReady.md}
 */
export const withOnReady = function (onReady: OnReadyFunction) {
  const ctx = EditableContext.get();

  ctx.onReady.push(onReady);
};
