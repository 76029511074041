import React from 'react';
import { removeUnusedProperties } from '@meisterlabs/slate';
import {
  withElementRenderer,
  withIsVoid,
  withElementNormalizer,
} from '@meisterlabs/slate-react';

import { Configuration } from '../components/Configuration';
import { Embed } from '../components/Embed';
import { EmbedBookmark } from '../components/EmbedBookmark';
import type { EmbedBlock, EmbedBlockEmpty, EmbedType } from '../types';
import { isEmbedBlock } from '../types';

export interface Options {
  /**
   * The set of allowed embed types to render `<iframe />`.
   * If not provided, all embed types are allowed.
   */
  allowList?: Set<EmbedType>;

  /**
   * The callback to call when a embed bookmark is clicked.
   */
  onBookmark?: (href: string) => void;

  placeholderForType: (type: EmbedType) => string;
  defaultPlaceholder: string;
  typePickerHeader: string;
  onEmbedContent?: (embedType: EmbedType, embedUrl?: string) => void;
}

/**
 * This middleware adds the icon block.
 */
export const withEmbed = function (options: Options) {
  const { allowList, onBookmark } = options;

  // If allowList exists, then we render the embeds with iframe for the allowed types.
  // Others are rendered as bookmarks.
  if (allowList) {
    withElementRenderer<EmbedBlock>(
      (element: EmbedBlock) =>
        isEmbedBlock(element) &&
        !!element.embedType &&
        !!element.embedUrl &&
        allowList.has(element.embedType),
      Embed
    );

    withElementRenderer<EmbedBlock>(
      (element: EmbedBlock) =>
        isEmbedBlock(element) &&
        !!element.embedType &&
        !!element.embedUrl &&
        !allowList.has(element.embedType),
      (props) => <EmbedBookmark {...props} onBookmark={onBookmark} />
    );
  } else {
    withElementRenderer<EmbedBlock>(
      (element: EmbedBlock) =>
        isEmbedBlock(element) && !!element.embedType && !!element.embedUrl,
      Embed
    );
  }

  withElementRenderer<EmbedBlockEmpty>(
    (element: EmbedBlockEmpty) =>
      isEmbedBlock(element) && (!element.embedType || !element.embedUrl),
    (props) => <Configuration {...props} {...options} />
  );

  withIsVoid<EmbedBlock>((element) => isEmbedBlock(element));

  withElementNormalizer<EmbedBlock>(
    (element) => isEmbedBlock(element),
    (element, path, editor) =>
      removeUnusedProperties(editor, element, path, ['embedType', 'embedUrl'])
  );
};
