import * as HAST from 'hast';
import { toHtml as toHtmlDefault } from 'hast-util-to-html';

import * as SAST from '../sast';
import { createState, Options } from '../transformers/sastToHast';
import sastParse from '../parsers/sastParse';

export const toHtml = function (doc: string, options: Options) {
  const processor = function (tree: SAST.Root) {
    const state = createState(options);

    return state.one(tree) as HAST.Root;
  };

  const sast = sastParse(doc);
  const hast = processor(sast);

  return toHtmlDefault(hast);
};
