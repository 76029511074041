import React, { useCallback, useContext, useEffect, useRef } from 'react';
import { useSlateStatic } from '@meisterlabs/slate-react';

import { useRafQueue } from '../../hooks/useRafQueue';
import { ResizeContext } from './Context';

export const Handle: React.FC = function ({ children }) {
  const editor = useSlateStatic();
  const start = useRef({ x: null, y: null });
  const resizeContext = useContext(ResizeContext);
  const rafQueue = useRafQueue(resizeContext.handleResize);

  useEffect(() => {
    return () => rafQueue.cancel();
  }, [rafQueue]);

  const onMouseDown = useCallback(
    (e) => {
      if (e.button !== 0) return;

      // We need to deselect the editor to prevent the selection from
      // from getting dragged and added to a random node
      editor.deselect();

      e.preventDefault();
      e.stopPropagation();

      start.current = {
        x: e.pageX,
        y: e.pageY,
      };

      document.addEventListener('mousemove', onMouseMove);
      document.addEventListener('mouseup', onMouseUp);

      resizeContext.handleResizeStart();
    },
    [rafQueue, resizeContext.handleResizeStart]
  );

  const onMouseMove = useCallback(
    (e) => {
      const x = e.pageX - start.current.x;
      const y = e.pageY - start.current.y;

      start.current = {
        x: e.pageX,
        y: e.pageY,
      };

      rafQueue.request({ x, y });
    },
    [rafQueue]
  );

  const onMouseUp = useCallback(() => {
    rafQueue.cancel();

    document.removeEventListener('mousemove', onMouseMove);
    document.removeEventListener('mouseup', onMouseUp);

    resizeContext.handleResizeStop();
  }, [rafQueue, resizeContext.handleResizeStart]);

  return <div onMouseDown={onMouseDown}>{children}</div>;
};
