import { Buffer } from 'buffer';

const load = function ({ aff, dic }) {
  return Promise.all([aff, dic]).then(function ([aff, dic]) {
    return {
      aff: Buffer.from(aff.default),
      dic: Buffer.from(dic.default),
    };
  });
};

export const languages = {
  // English
  en: () =>
    load({
      aff: import('dictionary-en/index.aff?raw' as string),
      dic: import('dictionary-en/index.dic?raw' as string),
    }),
  // German
  de: () =>
    load({
      aff: import('dictionary-de/index.aff?raw' as string),
      dic: import('dictionary-de/index.dic?raw' as string),
    }),
  // French
  fr: () =>
    load({
      aff: import('dictionary-fr/index.aff?raw' as string),
      dic: import('dictionary-fr/index.dic?raw' as string),
    }),
  // Spanish
  es: () =>
    load({
      aff: import('dictionary-es/index.aff?raw' as string),
      dic: import('dictionary-es/index.dic?raw' as string),
    }),
  // Italian
  it: () =>
    load({
      aff: import('dictionary-it/index.aff?raw' as string),
      dic: import('dictionary-it/index.dic?raw' as string),
    }),
  // Portuguese
  pt: () =>
    load({
      aff: import('dictionary-pt/index.aff?raw' as string),
      dic: import('dictionary-pt/index.dic?raw' as string),
    }),
  // Polish
  pl: () =>
    load({
      aff: import('dictionary-pl/index.aff?raw' as string),
      dic: import('dictionary-pl/index.dic?raw' as string),
    }),
  // Romanian
  ro: () =>
    load({
      aff: import('dictionary-ro/index.aff?raw' as string),
      dic: import('dictionary-ro/index.dic?raw' as string),
    }),
  // Czech
  cs: () =>
    load({
      aff: import('dictionary-cs/index.aff?raw' as string),
      dic: import('dictionary-cs/index.dic?raw' as string),
    }),
  // Slovak
  sk: () =>
    load({
      aff: import('dictionary-sk/index.aff?raw' as string),
      dic: import('dictionary-sk/index.dic?raw' as string),
    }),
};
