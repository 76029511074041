import React from 'react';
import { BlockRenderElementProps } from '@meisterlabs/slate-react';
import * as Portal from '@radix-ui/react-portal';

import { MMBlock } from '../types';
import { MindMap } from './MindMap';

type Props = BlockRenderElementProps<MMBlock> & {
  footerMetaText: string;
  resourcesDomain: string;
  isMMPaid: boolean;
  fetchFile: (url: string) => Promise<Blob>;
  onRepositionedMmMap?: (scale: number, x: number, y: number) => void;
};

export const MindMeister: React.FC<Props> = function (props) {
  const {
    attributes,
    children,
    element,
    footerMetaText,
    resourcesDomain,
    isMMPaid,
    fetchFile,
    onRepositionedMmMap,
  } = props;

  const [isFullScreen, setIsFullScreen] = React.useState(false);

  return (
    <div contentEditable={false} {...attributes}>
      {isFullScreen && (
        <Portal.Root container={document.body}>
          <div
            style={{
              position: 'fixed',
              top: 0,
              left: 0,
              width: '100vw',
              height: '100vh',
              background: 'white',
              zIndex: 1000,
            }}
          >
            <MindMap
              element={element}
              footerMetaText={footerMetaText}
              resourcesDomain={resourcesDomain}
              fetchFile={fetchFile}
              isFullScreen={isFullScreen}
              setIsFullScreen={setIsFullScreen}
              isMMPaid={isMMPaid}
              onRepositionedMmMap={onRepositionedMmMap}
            />
          </div>
        </Portal.Root>
      )}
      {!isFullScreen && (
        <MindMap
          element={element}
          footerMetaText={footerMetaText}
          resourcesDomain={resourcesDomain}
          fetchFile={fetchFile}
          setIsFullScreen={setIsFullScreen}
          isFullScreen={isFullScreen}
          isMMPaid={isMMPaid}
          onRepositionedMmMap={onRepositionedMmMap}
        />
      )}
      {children}
    </div>
  );
};
