import {
  HAST,
  MDAST,
  SAST,
  withHtmlDeserializer,
  withHtmlSerializer,
  withMarkdownDeserializer,
  withMarkdownSerializer,
} from '@meisterlabs/slate-serializer';

interface HeaderBlock extends SAST.Block {
  type: 'header';
  properties: {
    level: string;
  };
}

export const withHeaderSerializers = function () {
  withHtmlSerializer<HeaderBlock>(
    (_, node) => node.type === 'header',
    ({ multiple }, node) => {
      return {
        type: 'element',
        tagName: `h${node.properties.level}`,
        children: multiple<SAST.Content, HAST.Element>(node.children, node),
      };
    }
  );

  withHtmlDeserializer<HAST.Element>(
    (_, node) => /^h[1-6]$/.test(node.tagName),
    ({ multiple }, node) => {
      return {
        type: 'header',
        properties: {
          level: node.tagName.slice(1),
        },
        children: multiple<HAST.ElementContent, SAST.TextContent>(
          node.children,
          node
        ),
      };
    }
  );

  withMarkdownSerializer<HeaderBlock>(
    (_, node) => node.type === 'header',
    ({ multiple }, node) => {
      return {
        type: 'heading',
        depth: parseInt(node.properties.level) as 1 | 2 | 3 | 4 | 5 | 6,
        children: multiple<SAST.Content, MDAST.PhrasingContent>(
          node.children,
          node
        ),
      };
    }
  );

  withMarkdownDeserializer<MDAST.Heading>(
    (_, node) => node.type === 'heading',
    ({ multiple }, node) => {
      return {
        type: 'header',
        properties: {
          level: node.depth.toString(),
        },
        children: multiple<MDAST.Content, SAST.TextContent>(
          node.children,
          node
        ),
      };
    }
  );
};
