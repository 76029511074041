import type { Descendant } from 'slate';
import { EditorContext } from '../EditorContext';

/**
 * Adds an initial value to the editor.
 */
export const withInitialValue = function (initialValue: Descendant[]) {
  const ctx = EditorContext.get();

  ctx.initialValue = initialValue;
};
