import * as MDAST from 'mdast';
import {
  toMarkdown as toMarkdownDefault,
  defaultHandlers as defaultToMarkdownHandlers,
} from 'mdast-util-to-markdown';
import { gfmStrikethroughToMarkdown } from 'mdast-util-gfm-strikethrough';
import { gfmTaskListItemToMarkdown } from 'mdast-util-gfm-task-list-item';

import * as SAST from '../sast';
import { createState, Options } from '../transformers/sastToMdast';
import sastParse from '../parsers/sastParse';

/**
 * Since the MDAST specs don't really support having a indentend list before a none indented list
 *
 * So it would instead of doing:
 * ```markdown
 *   - item 1
 *
 * - item 2
 * ```
 *
 * it would do
 *
 * ```markdown
 * - - item 1
 *
 * item 2
 * ```
 *
 * To circumvent this we need to create a custom handler for the list element and set the depth directly on the list element
 *
 */
const customListHandler = function (list, parent, context, safeOptions) {
  const depth = list.depth ?? 0;
  const prefix = ' '.repeat(depth * 4);

  // Serialize the children of the listItem, typically paragraphs or other lists
  const children = defaultToMarkdownHandlers[list.type](
    list,
    parent,
    context,
    safeOptions
  );

  return prefix + children;
};

export const toMarkdown = function (doc: string, options: Options) {
  const processor = function (tree: SAST.Root) {
    const state = createState(options);

    return state.one(tree) as MDAST.Root;
  };

  const sast = sastParse(doc);
  const mdast = processor(sast);

  const markdown = toMarkdownDefault(mdast, {
    emphasis: '_',
    rule: '-',
    bullet: '-',
    listItemIndent: 'one',
    handlers: {
      list: customListHandler,
      // We need to to use a custom handler for the text element since the default handler will
      // encode some characters like the `_` or `\` which we don't want
      // It does that since it wants to prevent conflicts with markdown syntax
      text: (node) => node.value,
    },
    join: [
      /**
       * Since by default adjacent list element are joined together via a comment, we need to create a custom join function
       * that will join the list items together with just one empty line
       */
      () => 1,
    ],
    extensions: [
      // We need to use these extra since, gfm autolink breaks links copying
      // Retest with newest version though when possible
      gfmStrikethroughToMarkdown,
      gfmTaskListItemToMarkdown,
    ],
  });

  // mdast-util-to-markdown adds a space entity for some spaces in the document
  // we need to replace them with actual spaces
  return markdown.replaceAll('&#x20;', ' ');
};
