import { Editor, Transforms } from 'slate';
import { BlockElement } from '../types';

export const convertBlock = function <T extends BlockElement>(
  editor: Editor,
  key: string,
  block: Partial<T>
) {
  Transforms.setNodes(editor, block, {
    at: [],
    match: (node: BlockElement) => node.key === key,
  });
};
