import {
  withLeafAttributes,
  withLeafClassNames,
} from '@meisterlabs/slate-react';

import { HighlightedText } from '../types';
import {
  HAST,
  SAST,
  withHtmlDeserializer,
  withHtmlSerializer,
} from '@meisterlabs/slate-serializer';

export interface InlineHighlightOptions {
  colors: Record<string, string>;
}

export interface InlineHighlightSerializerOptions {
  defaultColor: string;
}

/**
 * Enable inline style highlights with a shortcut.
 */
export const withInlineHighlight = function (options: InlineHighlightOptions) {
  withLeafClassNames<HighlightedText>(function (leaf) {
    const final = [];

    if (leaf.highlight) final.push('highlight');

    return final;
  });

  withLeafAttributes<HighlightedText>(function (attributes, leaf) {
    if (typeof attributes.style !== 'object') attributes.style = {};

    if (leaf.highlight && options.colors[leaf.highlight]) {
      (attributes.style as React.CSSProperties).backgroundColor =
        options.colors[leaf.highlight];
    }

    return attributes;
  });
};

export const withInlineHighlightSerialization = function (
  options: InlineHighlightSerializerOptions
) {
  withHtmlSerializer<SAST.Mark>(
    (_, node) => SAST.isMark(node) && node.name === 'highlight',
    ({ multiple }, node) => {
      return {
        type: 'element',
        tagName: 'mark',
        properties: {
          dataColor: node.value,
        },
        children: multiple(node.children, node),
      };
    }
  );

  withHtmlDeserializer<HAST.Element, SAST.Mark>(
    (_, node) => node.tagName === 'mark' && !!node.properties?.dataColor,
    ({ multiple }, node) => {
      const colorName = (node.properties?.dataColor ??
        options.defaultColor) as string;

      return {
        type: 'mark',
        name: 'highlight',
        value: colorName,
        children: multiple(node.children, node),
      };
    }
  );
};
