import React from 'react';
import { GridHelpers } from '@meisterlabs/react-grid';
import { fonts } from '@meisterlabs/fonts';
import {
  BlockRenderElementProps,
  useSlateStatic,
} from '@meisterlabs/slate-react';
import { parser, Transforms } from '@meisterlabs/slate';
import { Color } from '@meisterlabs/common';

import type { Options } from '../middlewares/withIcon';
import { IconSelectorPopoverButton } from './IconSelectorPopover';
import buildCompositeId from '../utils/buildCompositeId';
import { IconBlock } from '../types';

const styles = {
  root: {
    display: 'flex',
    paddingTop: 15,
    paddingBottom: 15,
    paddingRight: 20,
    borderRadius: 10,
    width: 'auto',
    transition: `background-color 300ms cubic-bezier(0.165, 0.840, 0.440, 1.000)`,
  },
  iconWrapper: {
    display: 'flex',
    marginLeft: 10,
    height: fonts.m.regular.lineHeight,
    alignItems: 'center',
    userSelect: 'none',
  },
} as const;

const Icon: React.FC<BlockRenderElementProps<IconBlock> & Options> = function (
  props
) {
  const {
    children,
    attributes,
    element,
    icons,
    colors,
    iconPickerHeader,
    defaultProps,
  } = props;

  const editor = useSlateStatic();

  const currentIconId =
    parser.parseToInt(element.iconId) ?? defaultProps.iconId;
  const currentColorId =
    parser.parseToInt(element.colorId) ?? defaultProps.colorId;

  const onSelectColor = (compositeIdColor) => {
    const color = JSON.parse(compositeIdColor);

    Transforms.setNodes<IconBlock>(
      editor,
      {
        colorId: parser.parseToString(color.id),
        colorType: color.type,
      },
      {
        at: [],
        match: (node: IconBlock) =>
          node.type === 'icon' && node.key === element.key,
      }
    );
  };

  const onSelectIcon = (compositeIdIcon) => {
    const icon = JSON.parse(compositeIdIcon);

    Transforms.setNodes<IconBlock>(
      editor,
      {
        iconId: parser.parseToString(icon.id),
        iconType: icon.type,
      },
      {
        at: [],
        match: (node: IconBlock) =>
          node.type === 'icon' && node.key === element.key,
      }
    );
  };

  const iconList = icons.map(function (icon) {
    return {
      id: buildCompositeId(icon),
      type: GridHelpers.GRID_TYPES.ICON,
      isSelected: icon.type === element.iconType && icon.id === currentIconId,
    };
  });

  const colorList = colors.map(function (color) {
    return {
      id: buildCompositeId(color),
      type:
        color.id === 0
          ? GridHelpers.GRID_TYPES.CIRCLE_PARTIAL
          : GridHelpers.GRID_TYPES.CIRCLE,
      isSelected:
        color.type === element.colorType && color.id === currentColorId,
    };
  });

  const iconSet = React.useMemo(() => {
    return icons.reduce(function (acc, icon) {
      const compositeId = buildCompositeId(icon);
      const Icon = icon.component;

      acc[compositeId] = <Icon />;

      return acc;
    }, {});
  }, [icons]);

  const colorSet = React.useMemo(() => {
    return colors.reduce(function (acc, color) {
      const compositeId = buildCompositeId(color);

      acc[compositeId] = color.value;

      return acc;
    }, {});
  }, [colors]);

  const selectedIcon = icons.find(
    (icon) => icon.type === element.iconType && icon.id === currentIconId
  );
  const selectedColor = colors.find(
    (color) => color.type === element.colorType && color.id === currentColorId
  );
  const backgroundColor = Color(selectedColor.value)
    .setAlpha(selectedColor.opacity)
    .toRgbString();

  return (
    <div
      {...attributes}
      style={{
        ...(attributes.style ?? {}),
        ...styles.root,
        backgroundColor,
      }}
    >
      <div style={styles.iconWrapper} contentEditable={false}>
        <IconSelectorPopoverButton
          colorSet={colorSet}
          iconSet={iconSet}
          iconList={iconList}
          colorList={colorList}
          iconColor={selectedColor.value}
          iconPickerHeader={iconPickerHeader}
          onSelectColor={onSelectColor}
          onSelectIcon={onSelectIcon}
        >
          <selectedIcon.component />
        </IconSelectorPopoverButton>
      </div>
      <div
        style={{ display: 'inline-block', minWidth: '1px', marginLeft: '6px' }}
      >
        {children}
      </div>
    </div>
  );
};

export default Icon;
