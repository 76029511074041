import logo from '../assets/GoogleDrive.png';
import { Parser } from '../types';

const re = new RegExp(
  '^https?://drive.google.com/file/d/(.*)/(preview|view).?usp=sharing.*$'
);

const Embed: Parser = {
  logo,
  name: 'Google Drive',
  parseUrl(url) {
    const match = url.match(re);

    if (!match) return null;

    const frameSrc = url.replace('/view', '/preview');

    return {
      type: 'GoogleDrive',
      url,
      frameSrc,
      frameAspectRatio: 9 / 16,
      frameBorder: true,
    };
  },
};

export default Embed;
