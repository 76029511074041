import { removeUnusedProperties } from '@meisterlabs/slate';
import {
  withElementNormalizer,
  withElementRenderer,
} from '@meisterlabs/slate-react';

import { UnorderedListItem } from '../components/UnorderedList';
import { UnorderedListItemBlock, isUnorderedListItemBlock } from '../types';

/**
 * This middleware adds the Block of an unordered list.
 */
export const withUnorderedList = function () {
  withElementRenderer<UnorderedListItemBlock>(
    (element) => isUnorderedListItemBlock(element),
    UnorderedListItem
  );

  withElementNormalizer<UnorderedListItemBlock>(
    (element) => isUnorderedListItemBlock(element),
    (element, path, editor) =>
      removeUnusedProperties(editor, element, path, ['listType'])
  );
};
