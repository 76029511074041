import { Node } from 'slate';

import { BlockElement } from '../types';

/**
 * Check if a Node is a BlockElement
 */
export const isBlockElement = function (node: Node): node is BlockElement {
  return !!(node as BlockElement).key;
};
