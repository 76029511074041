import React, { useMemo } from 'react';
import { BlockElement } from '@meisterlabs/slate';
import {
  BlockRenderElementProps,
  ReactEditor,
  useSlateSelector,
  withElementWrapper,
} from '@meisterlabs/slate-react';

export interface WithBlockSpacingOptions {
  getPadding: (
    prev: BlockElement | undefined,
    next: BlockElement | undefined
  ) => number;
}

type BlockSpacerProps = BlockRenderElementProps<BlockElement> &
  WithBlockSpacingOptions;

const BlockSpacer: React.FC<BlockSpacerProps> = function ({
  element,
  children,
  getPadding,
}) {
  const { prev, next } = useSlateSelector(
    function (editor) {
      // This will break for nested blocks, however, for now this is more performant
      const path = ReactEditor.findPath(editor as ReactEditor, element);
      const [currentIndex] = path;

      const prev =
        currentIndex > 0 ? editor.children[currentIndex - 1] : undefined;
      const next =
        currentIndex < editor.children.length - 1
          ? editor.children[currentIndex + 1]
          : undefined;

      return {
        prev: prev as BlockElement,
        next: next as BlockElement,
      };
    },
    function ({ prev: prevA, next: nextA }, { prev: prevB, next: nextB }) {
      if (prevA !== prevB || nextA !== nextB) return false;
      return true;
    }
  );

  const { paddingTop, paddingBottom } = useMemo(() => {
    const paddingTop = getPadding(prev, element);
    const paddingBottom = getPadding(element, next);

    return {
      paddingTop: paddingTop / 2,
      paddingBottom: paddingBottom / 2,
    };
  }, [getPadding, element, next, prev]);

  return <div style={{ paddingTop, paddingBottom }}>{children}</div>;
};

export const withBlockSpacing = function (options: WithBlockSpacingOptions) {
  withElementWrapper((props) => <BlockSpacer {...props} {...options} />);
};
