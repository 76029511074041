import * as MDAST from 'mdast';

import {
  Handlers as DefaultHandlers,
  StateOptions,
  createState as createDefaultState,
} from './default';
import * as Sast from '../sast';
import { MdastNode } from '../types';

export type Handlers = DefaultHandlers<Sast.Node, MdastNode>;

const defaultHandlers: Handlers = [
  [
    (_, node) => node.type === 'root',
    ({ multiple }, node: Sast.Root) => {
      const children = multiple(node.children, node);

      if (children.some((child) => child.type === 'root')) {
        throw new Error('Root nodes cannot have root nodes as children');
      }

      return {
        type: 'root',
        children,
      } as MDAST.Root;
    },
  ],
  [
    (_, node) => node.type === 'text',
    (_, node: MDAST.Text) => {
      return {
        type: 'text',
        value: node.value,
      };
    },
  ],
];

export type Options = Omit<StateOptions<Sast.Node, MdastNode>, 'getHandleKey'>;

export const createState = function (options: Options = {}) {
  const handlers = [...options.handlers, ...defaultHandlers];

  return createDefaultState<Sast.Node, MdastNode>({ handlers });
};
