import React, { useCallback, useEffect, useMemo, useRef } from 'react';

import LoadingContext from './LoadingContext';
import { OnReadyFunction } from '../../types';

export interface LoadingContextProviderProps {
  onReady: Array<OnReadyFunction>;
}

let id = 0;

export const LoadingContextProvider: React.FC<LoadingContextProviderProps> =
  function ({ children, onReady }) {
    const tokens = useRef(new Set<string>());
    const timeout = useRef(null);

    const onLoadEndTick = useCallback(() => {
      if (tokens.current.size) return;

      onReady.forEach((fn) => fn());
    }, []);

    const onLoadStart = useCallback(() => {
      const token = String(id++);

      tokens.current.add(token);

      clearTimeout(timeout.current);

      return token;
    }, []);

    const onLoadEnd = useCallback((token: string) => {
      if (!tokens.current.has(token)) return;

      tokens.current.delete(token);

      clearTimeout(timeout.current);
      timeout.current = setTimeout(onLoadEndTick, 0);
    }, []);

    useEffect(() => {
      clearTimeout(timeout.current);
      timeout.current = setTimeout(onLoadEndTick, 0);

      return () => clearTimeout(timeout.current);
    }, []);

    const value = useMemo(
      () => ({ onLoadStart, onLoadEnd }),
      [onLoadStart, onLoadEnd]
    );

    return (
      <LoadingContext.Provider value={value}>
        {children}
      </LoadingContext.Provider>
    );
  };
