/**
 * This file contains functions to parse values to a specific type. Even though these are basically one liners,
 * they are used from here so that this comment is more visible.
 *
 * These parsers are needed instead of just using integers, boolean, etc. directly, because Yjs and Yrs
 * currently don't support any other type than strings.
 *
 * Booleans are parsed as follows:
 * - '1' is true
 * - '0' is false
 *
 * Integers are parsed using parseInt with base 10.
 */

export const parseToInt = function (value: string): number | undefined {
  const parsed = parseInt(value, 10);
  if (isNaN(parsed)) return undefined;
  return parsed;
};

export const parseToFloat = function (value: string): number | undefined {
  const parsed = parseFloat(value);
  if (isNaN(parsed)) return undefined;
  return parsed;
};

export const parseToBoolean = function (value?: string): boolean | undefined {
  switch (value) {
    case '1':
      return true;
    case '0':
      return false;
    default:
      return undefined;
  }
};

export const parseToString = function (
  value: number | boolean | string | undefined
): string | undefined {
  if (typeof value === 'number') return `${value}`;
  if (typeof value === 'boolean') return value ? '1' : '0';
  if (typeof value === 'string') return value;
  return undefined;
};
