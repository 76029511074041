import React, { useCallback } from 'react';
import { colors, fonts, rgba } from '@meisterlabs/ui';
import { Icon, Text, View } from '@meisterlabs/knightrider';
import { Media } from '@meisterlabs/svgs/Tint';
import { DropzoneView, FileUpload } from '@meisterlabs/react-file-upload';

interface Props {
  placeholderText: string;
  readOnly: boolean;
  onSelectFile: (file: File) => void;
}

const style = {
  root: (readOnly) => ({
    userSelect: 'none',
    flexGrow: 1,
    flexShrink: 1,
    height: 44,
    backgroundColor: rgba.black(0.05),
    borderRadius: 10,
    paddingLeft: 20,
    paddingRight: 20,
    alignItems: 'center',
    position: 'relative',
    cursor: readOnly ? 'default' : 'pointer',
  }),
  icon: {
    width: 24,
    height: 24,
    color: colors.grey500,
  },
  info: {
    root: {
      flexGrow: 1,
      flexShrink: 1,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    name: {
      root: {
        flexGrow: 1,
        flexShrink: 1,
      },
      text: {
        ...fonts.m.medium,
        color: colors.grey500,
        paddingRight: 10,
        paddingLeft: 10,
        flexShrink: 1,
        flexGrow: 1,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
    },
  },
};

export const Placeholder: React.VFC<Props> = function (props) {
  const { onSelectFile, readOnly, placeholderText } = props;

  const onChoseFile = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files[0];

      if (file) onSelectFile(file);
    },
    [onSelectFile]
  );

  const onDropFile = useCallback(
    (event: React.DragEvent) => {
      const file = event.dataTransfer.files[0];

      if (file) onSelectFile(file);
    },
    [onSelectFile]
  );

  return (
    <DropzoneView onDrop={onDropFile} style={style.root(readOnly)}>
      <Icon style={style.icon}>
        <Media />
      </Icon>
      <View style={style.info.root}>
        <View style={style.info.name.root}>
          <Text style={style.info.name.text}>{placeholderText}</Text>
        </View>
      </View>
      {!readOnly && <FileUpload multiple={false} onChange={onChoseFile} />}
    </DropzoneView>
  );
};
