import React, { useMemo, useState } from 'react';
import * as Popover from '@radix-ui/react-popover';
import { useReadOnly } from '@meisterlabs/slate-react';
import { colors } from '@meisterlabs/colors';
import { IconTextButton } from '@meisterlabs/ui';

import {
  LanguageContentProps,
  LanguagePopoverContent,
} from './LanguagePopoverContent';
import { languages } from '../utils';

const style = {
  popover: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    flexShrink: 1,
    minWidth: 300,
    backgroundColor: colors.white,
    borderRadius: 15,
    boxShadow:
      '0 10px 30px -10px rgba(0, 0, 0, 0.15), 0 0 1px 0 rgba(0, 0, 0, 0.07)',
  },
  trigger: {
    border: 'none',
    background: 'none',
    padding: 0,
  },
  languagePicker: {
    root: {
      position: 'absolute',
      zIndex: 1,
      top: 5,
      right: 5,
      userSelect: 'none',
    },
    Button: IconTextButton.defaultProps.style.small,
  },
} as const;

type LanguagePopoverButtonProps = Omit<LanguageContentProps, 'close'>;

export const LanguagePopoverButton: React.VFC<LanguagePopoverButtonProps> =
  function (props) {
    const {
      blockKey,
      language,
      noneItemText,
      popoverHeaderText,
      onLanguageChanged,
    } = props;

    const readOnly = useReadOnly();
    const [open, setOpen] = useState(false);

    const languageTitle = useMemo(() => {
      const lang = languages.find(
        (l) => l.id === language || l.aliases.has(language)
      );

      return lang ? lang.title : noneItemText;
    }, [language]);

    return (
      <div
        contentEditable={false}
        style={style.languagePicker.root as React.CSSProperties}
      >
        <Popover.Root open={!readOnly && open}>
          <Popover.Trigger style={style.trigger}>
            <IconTextButton
              onClick={() => setOpen(true)}
              text={languageTitle}
              style={style.languagePicker.Button}
              disabled={readOnly}
            />
          </Popover.Trigger>
          <Popover.Portal>
            <Popover.Content
              collisionPadding={20}
              onPointerDownOutside={() => setOpen(false)}
            >
              <div style={style.popover}>
                <LanguagePopoverContent
                  close={() => setOpen(false)}
                  blockKey={blockKey}
                  language={language}
                  noneItemText={noneItemText}
                  popoverHeaderText={popoverHeaderText}
                  onLanguageChanged={onLanguageChanged}
                />
              </div>
            </Popover.Content>
          </Popover.Portal>
        </Popover.Root>
      </div>
    );
  };
