import React from 'react';
import { rgba } from '@meisterlabs/ui';
import { Icon, View } from '@meisterlabs/knightrider';
import { MediaBroken } from '@meisterlabs/svgs/Tint';

const style = {
  root: {
    width: '100%',
    height: 200,
    borderRadius: 10,
    overflow: 'hidden',
    position: 'relative',
    backgroundColor: rgba.black(0.05),
    alignItems: 'center',
    justifyContent: 'center',
  },
  icon: {
    width: 48,
    height: 48,
    color: rgba.black(0.2),
  },
};

export const Broken: React.VFC = function () {
  return (
    <View style={style.root}>
      <Icon style={style.icon}>
        <MediaBroken />
      </Icon>
    </View>
  );
};
