import { clsx } from 'clsx';
import React from 'react';
import { colors } from '@meisterlabs/colors';
import { Transforms, parser } from '@meisterlabs/slate';
import {
  BlockRenderElementProps,
  ReactEditor,
  useReadOnly,
  useSlateStatic,
} from '@meisterlabs/slate-react';
import { Checkbox, styles } from '@meisterlabs/ui';

import { ChecklistItemBlock } from '../types';
import { ListItem } from './ListItem';
import style from './style.module.css';
import type { WithCheckListOptions } from '../middlewares';

const checkboxStyle = styles.Checkbox.mergeOptions({
  size: 18,
  disabled: false,
})
  .mapStyle('full', (R) =>
    R.borderColor(colors.blue).borderWidth(2).borderRadius('50%')
  )
  .mapStyle('empty', (R) =>
    R.borderColor(colors.blue).borderWidth(2).borderRadius('50%')
  );

interface CheckboxState {
  checked: boolean;
  onToggle: () => void;
}

const CheckboxMarker: React.FC<CheckboxState> = function ({
  checked,
  onToggle,
}) {
  return (
    <Checkbox
      checked={checked ?? false}
      onMouseDown={onToggle}
      style={checkboxStyle}
    />
  );
};

export const ChecklistItem: React.FC<
  BlockRenderElementProps<ChecklistItemBlock> & WithCheckListOptions
> = function (props) {
  const { attributes, children, element, onChecklistToggled } = props;

  const editor = useSlateStatic();
  const readOnly = useReadOnly();

  const checked = parser.parseToBoolean(element.checked);

  const onToggle = () => {
    // Do not allow toggling if the editor is read-only.
    if (readOnly) {
      return;
    }

    onChecklistToggled?.(!checked);

    const path = ReactEditor.findPath(editor as ReactEditor, element);

    Transforms.setNodes<ChecklistItemBlock>(
      editor,
      { checked: parser.parseToString(!checked) },
      { at: path }
    );
  };

  return (
    <ListItem
      attributes={attributes}
      className={clsx(checked && style.checked, attributes.className)}
      marker={<CheckboxMarker onToggle={onToggle} checked={checked} />}
    >
      {children}
    </ListItem>
  );
};
