import React from 'react';
import { View } from '@meisterlabs/knightrider';
import { IconButton } from '@meisterlabs/ui';
import { colors } from '@meisterlabs/colors';
import { Check, Minus, Plus } from '@meisterlabs/svgs/Tint';

const styles = {
  root: {
    display: 'flex',
    position: 'absolute',
    height: 34,
    top: 10,
    left: 10,
    transition: 'transform 200ms ease-in-out',
    transform: 'translateY(-44px)',
    gap: 10,
  },
  zoomButtons: {
    display: 'flex',
    height: 34,
    paddingLeft: 10,
    paddingRight: 10,
    backgroundColor: colors.white,
    borderRadius: 22,
    alignItems: 'center',
    boxShadow:
      'rgba(0, 0, 0, 0.2) 0px 0.5px 3px -1px, rgba(0, 0, 0, 0.15) 0px 5px 30px -10px',
  },
  saveButton: {
    display: 'flex',
    height: 34,
    width: 34,
    backgroundColor: colors.white,
    borderRadius: 22,
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow:
      'rgba(0, 0, 0, 0.2) 0px 0.5px 3px -1px, rgba(0, 0, 0, 0.15) 0px 5px 30px -10px',
  },
  button: IconButton.defaultProps.style.small,
};

interface Props {
  showSaveButton: boolean;
  showHeader: boolean;
  onClickZoomOut: () => void;
  onClickZoomIn: () => void;
  onClickSave: () => void;
}

export const Header: React.VFC<Props> = function (props) {
  const {
    onClickZoomOut,
    onClickZoomIn,
    onClickSave,
    showHeader,
    showSaveButton,
  } = props;

  return (
    <View
      style={{
        ...styles.root,
        transform: showHeader ? 'translateY(0)' : 'translateY(-44px)',
        pointerEvents: showHeader ? 'all' : 'none',
      }}
    >
      <View style={styles.zoomButtons}>
        <IconButton onMouseDown={onClickZoomOut} style={styles.button}>
          <Minus />
        </IconButton>
        <IconButton onMouseDown={onClickZoomIn} style={styles.button}>
          <Plus />
        </IconButton>
      </View>
      <View
        style={{
          ...styles.saveButton,
          opacity: showSaveButton ? 1 : 0,
          pointerEvents: showHeader ? 'all' : 'none',
        }}
      >
        <IconButton onMouseDown={onClickSave} style={styles.button}>
          <Check />
        </IconButton>
      </View>
    </View>
  );
};
