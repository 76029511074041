import { Editor } from '@meisterlabs/slate';

import { FileBlock } from '../types';

export const files = function* (editor: Editor) {
  const nodes = Editor.nodes(editor, {
    at: [],
    match: (n: FileBlock) => n.type === 'file',
  });

  for (const [node] of nodes) {
    const fileBlock = node as FileBlock;

    yield {
      key: fileBlock.fileKey,
      name: fileBlock.fileName,
      contentType: fileBlock.fileContentType,
    };
  }
};
