import logo from '../assets/GoogleSheets.png';
import { Parser } from '../types';

const re = new RegExp('^https?://docs.google.com/spreadsheets/d/(.*)$');

const Embed: Parser = {
  logo,
  name: 'Google Sheets',
  parseUrl(url) {
    const match = url.match(re);

    if (!match) return null;

    const frameSrc = url.replace('/edit', '/preview');

    return {
      type: 'GoogleSheets',
      url,
      frameSrc,
      frameAspectRatio: 12 / 16,
      frameBorder: true,
    };
  },
};

export default Embed;
