import React from 'react';
import {
  BlockElement,
  getRangeFromOffsetsInTextList,
  Node,
  Text,
} from '@meisterlabs/slate';
import {
  LeafRenderProps,
  withDecorator,
  withLeafClassNames,
  withLeafRenderer,
} from '@meisterlabs/slate-react';

type HexColorLeaf = Text & {
  hexcolor: string;
};

const ColorLeaf: React.FC<LeafRenderProps<HexColorLeaf>> = function ({
  attributes,
  children,
  leaf,
}) {
  const colorStyle = { '--hex-color': leaf.hexcolor } as React.CSSProperties;

  return (
    <span {...attributes}>
      <span contentEditable={false} style={{ userSelect: 'none' }}>
        <span className='hexcolor-container' style={colorStyle}>
          <span className='hexcolor-color' />
        </span>
      </span>
      <span>{children}</span>
    </span>
  );
};

/**
 * This middleware adds an inline Decorator hexColor which shows the color next to a hex color value with a `#`.
 */
export const withHexColor = function () {
  const decoratorCache = new WeakMap();

  withLeafRenderer<HexColorLeaf>((leaf) => !!leaf.hexcolor, ColorLeaf);

  withLeafClassNames<HexColorLeaf>(function (leaf) {
    if (!leaf.hexcolor) return [];

    return ['hexcolor'];
  });

  withDecorator<BlockElement, { hexcolor: string }>(function (
    [node, path],
    editor
  ) {
    if (decoratorCache.has(node)) return decoratorCache.get(node);
    if (editor.isVoid(node)) return;

    const text = Node.string(node as Node);

    if (!text.includes('#')) return;

    let offset = 0;

    const result = text
      .split(' ')
      .flatMap(function (word, index, array) {
        const isColor = !!word.match(/^#([a-f0-9]{3,4}|[a-f0-9]{6})$/i);

        const token = {
          word,
          type: isColor ? 'color' : 'text',
        };

        return array.length - 1 !== index
          ? [token, { word: ' ', type: 'text' }]
          : [token];
      })
      .flatMap(function ({ word, type }) {
        const start = offset;
        const end = start + word.length;
        offset = end;

        if (type === 'text') return [];

        const range = getRangeFromOffsetsInTextList(node, path, start, end);

        if (!range || !range.anchor || !range.focus) return [];

        // We only want to show the color preview if it is together in one leaf
        if (
          range.anchor.path[range.anchor.path.length - 1] !==
          range.focus.path[range.focus.path.length - 1]
        ) {
          return [];
        }

        return [
          {
            ...range,
            hexcolor: word,
          },
        ];
      });

    decoratorCache.set(node, result);

    return result;
  });
};
