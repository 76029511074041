import {
  Editor,
  EditorContext,
  Node,
  Path,
  Range,
  generateKey,
  isBlockElement,
} from '@meisterlabs/slate';

import { SerializerEditorContext } from '../SerializerEditorContext';
import { SerializationType, SerializerEditor } from '../types';

// Processors
import { toHtml } from '../processors/slateToHtml';
import { toMarkdown } from '../processors/slateToMarkdown';
import { toSlate as htmlToSlate } from '../processors/htmlToSlate';
import { toSlate as markdownToSlate } from '../processors/markdownToSlate';

export const withSerializerEditor = function (setupFn: () => void) {
  const { editor } = EditorContext.get();

  const ctx = {
    serializers: {
      sastToHast: [],
      sastToMdast: [],
      hastToSast: [],
      mdastToSast: [],
    },
  };

  SerializerEditorContext.create(ctx);

  setupFn();
  SerializerEditorContext.done();

  const { serializers } = ctx;

  (editor as SerializerEditor).serialize = (type, selection) => {
    if (!selection) {
      selection = editor.selection ?? Editor.range(editor, []);
    }

    const [start, end] = Range.edges(selection);
    let nodes = Node.fragment(editor, selection);

    // The logic here is to achieve a better user experience for serializing/copying blocks
    //
    // We don't want to add format to blocks that we only selected partially or if we only select one block
    // Since this would lead to strange behaviour when users copy just the text of a block to somewhere else
    // We use the paragraph block as the default block type since it should work nicely in Markdown and HTML
    nodes = nodes.map((node, index) => {
      if (index > 0) return node;
      if (!isBlockElement(node)) return node;

      if (start.offset > 0 || Path.equals(start.path, end.path)) {
        return {
          children: node.children,
          key: node.key,
          type: 'paragraph',
        };
      }

      return node;
    });

    switch (type) {
      case SerializationType.Html:
        return toHtml(JSON.stringify(nodes), {
          handlers: serializers.sastToHast,
        });
      case SerializationType.Markdown:
        return toMarkdown(JSON.stringify(nodes), {
          handlers: serializers.sastToMdast,
        });
    }
  };

  (editor as SerializerEditor).deserialize = (type, data) => {
    switch (type) {
      case SerializationType.Html:
        return htmlToSlate(data, {
          handlers: serializers.hastToSast,
          generateKey,
        });
      case SerializationType.Markdown:
        return markdownToSlate(data, {
          handlers: serializers.mdastToSast,
          generateKey,
        });
    }
  };
};
