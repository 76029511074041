import { Editor, Transforms } from '@meisterlabs/slate';

import { MMBlock } from '../types';

export const updateMindmeister = function (
  editor: Editor,
  key: string,
  file: Partial<MMBlock>
) {
  Transforms.setNodes<MMBlock>(editor, file, {
    at: [],
    match: (n: MMBlock) => n.type === 'mindmeister' && n.key === key,
  });
};
