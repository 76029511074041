import type { Editor, Descendant } from 'slate';
import type { Doc } from 'yjs';

export interface EditorContextState {
  editor: Editor;
  onCreate: Array<() => void>;
  initialValue: Descendant[] | null;
  yDoc?: Doc;
}

export class EditorContext {
  static current: EditorContextState | null = null;

  static create(context: EditorContextState): EditorContextState {
    EditorContext.current = context;

    return context;
  }

  static get(): EditorContextState {
    if (!EditorContext.current) {
      throw new Error('EditorContext not initialized');
    }

    return EditorContext.current;
  }

  static done() {
    EditorContext.current = null;
  }
}
