import React, { useMemo } from 'react';
import { StandaloneList, style as ListStyle } from '@meisterlabs/react-lists';
import { colors } from '@meisterlabs/colors';

import { HunspellInstace } from '../types';
import { getSuggestions } from '../utils/Hunspell';

const styles = {
  popover: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    flexShrink: 1,
    minWidth: 280,
    backgroundColor: colors.white,
    borderRadius: 15,
    boxShadow:
      '0 10px 30px -10px rgba(0, 0, 0, 0.15), 0 0 1px 0 rgba(0, 0, 0, 0.07)',
    maxHeight: 'var(--radix-popover-content-available-height)',
  },
  list: ListStyle({
    itemMarginHorizontal: 10,
    headerMarginHorizontal: 10,
    paddingTop: 10,
    paddingBottom: 10,
    selectedColor: colors.sky,
  }),
} as const;

interface Props {
  hunspell: HunspellInstace;
  word?: string;
  addToDictionaryText: string;
  ignoreText: string;
  onAddToDictionary: () => void;
  onIgnore: () => void;
  onSelectText: (text: string) => void;
}

export const SpellcheckContent: React.VFC<Props> = function (props) {
  const {
    hunspell,
    word,
    addToDictionaryText,
    ignoreText,
    onAddToDictionary,
    onIgnore,
    onSelectText,
  } = props;

  const onSelect = function ({
    event,
    item,
  }: {
    event: React.MouseEvent;
    item: { id: string };
  }) {
    event.preventDefault();
    event.stopPropagation();

    switch (item.id) {
      case 'add':
        return onAddToDictionary();
      case 'ignore':
        return onIgnore();
      default:
        return onSelectText(item.id);
    }
  };

  const suggestions = useMemo(() => {
    if (!word) return [];

    return getSuggestions(hunspell, word).map((suggestion) => ({
      id: suggestion,
      content: suggestion,
      type: 'item',
    }));
  }, [word]);

  const items = useMemo(() => {
    return [
      ...suggestions,
      {
        type: 'line',
      },
      {
        id: 'add',
        content: addToDictionaryText,
        type: 'item',
        centeredMiddle: true,
        centeredTextColor: colors.blue,
      },
      {
        id: 'ignore',
        content: ignoreText,
        type: 'item',
        centeredMiddle: true,
      },
    ];
  }, [suggestions]);

  return (
    <div style={styles.popover}>
      <StandaloneList
        selectFirst
        items={items}
        onEnter={onSelect}
        /* Has to be onMouseDown to prevent the editor from losing focus */
        onMouseDownItem={onSelect}
        listProps={{
          maxItemsHeight: '100%',
          disableInput: true,
          style: styles.list,
        }}
      />
    </div>
  );
};
