import { Languages } from 'prismjs';
import components from 'prismjs/components';

const EXCLUDED_IDS = new Set([
  'markup',
  'clike',
  'brainfuck',
  'js-extras',
  'ignore',
  'properties',
]);

const getAliases = function (language: Languages): Set<string> {
  if (Array.isArray(language.alias)) return new Set(language.alias);
  if (typeof language.alias === 'string') return new Set([language.alias]);
  return new Set();
};

export const languages = Object.entries(components.languages)
  .flatMap(function ([id, language]) {
    if (!language.title) return [];
    if (EXCLUDED_IDS.has(id)) return [];

    return [
      {
        id,
        aliases: getAliases(language),
        title: language.title,
      },
    ];
  })
  .sort((a, b) => a.title.localeCompare(b.title));
