import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import {
  BlockRenderElementProps,
  useReadOnly,
  useSlateStatic,
} from '@meisterlabs/slate-react';
import {
  AttachmentStore,
  ATTACHMENT_SOURCE,
} from '@meisterlabs/react-attachment-viewer';

import type { Options } from '../middlewares/withFile';
import { Image, Meta, Placeholder, Spinner, Video } from './PreviewTypes';
import { updateFile } from '../commands';
import { AddFileSource, FileBlock } from '../types';
import { FileContext } from './FileContext';

export const FileComponent: React.FC<
  BlockRenderElementProps<FileBlock> & Options
> = function (props) {
  const {
    attributes,
    children,
    element,
    placeholderText,
    fetchFile,
    addFile,
    onClickPreview,
    onFileResized,
  } = props;

  const editor = useSlateStatic();
  const readOnly = useReadOnly();
  const context = useContext(FileContext);
  const file = useMemo(
    () => context.files[element.key],
    [context.files, element.fileKey]
  );

  const onSelectFile = useCallback(
    (file: File) => {
      addFile(element.key, file, AddFileSource.BLOCK_PLACEHOLDER).then(
        (file) => {
          updateFile(editor, element.key, {
            fileContentType: file.contentType,
            fileKey: file.id,
            fileName: file.fileName,
          });
        }
      );
    },
    [addFile, element.key]
  );

  const onResize = useCallback(
    (width: string) => {
      onFileResized?.(parseInt(width));

      updateFile(editor, element.key, { width });
    },
    [element.key]
  );

  const onClick = () => {
    onClickPreview?.(element);
  };

  const attachment = useMemo(() => {
    if (element.fileKey && !file) {
      // Need an AttachmentsStore object to render the spinner in case we need to download
      return AttachmentStore.of({
        id: element.fileKey,
        filename: element.fileName,
        contentType: element.fileContentType,
        source: ATTACHMENT_SOURCE.LOCAL,
      });
    }

    if (!file) return null;

    return AttachmentStore.of({
      id: file.id,
      filename: file.fileName,
      contentType: file.contentType,
      embedUrl: file.previewUrl,
      source: ATTACHMENT_SOURCE.LOCAL,
    });
  }, [element.fileKey, file]);

  const previewType = useMemo(() => {
    if (!attachment) return 'Placeholder';
    if ((attachment.isImage() || attachment.isVideo()) && !file)
      return 'Spinner';
    if (attachment.isImage()) return 'Image';
    if (file && file.isUploading) return 'Spinner';
    if (attachment.isVideo()) return 'Video';
    return 'Meta';
  }, [attachment, file]);

  useEffect(() => {
    if (file || !element.fileKey) return;

    fetchFile(element);
  }, [element, file]);

  return (
    <div {...attributes} contentEditable={false}>
      <div
        style={{
          width: '100%',
          position: 'relative',
        }}
      >
        {previewType === 'Spinner' && <Spinner />}
        {previewType === 'Placeholder' && (
          <Placeholder
            placeholderText={placeholderText}
            readOnly={readOnly}
            onSelectFile={onSelectFile}
          />
        )}
        {previewType === 'Image' && (
          <Image
            name={attachment?.state?.filename}
            extension={attachment?.getExtension()}
            url={attachment?.state?.embedUrl}
            width={element.width}
            readOnly={readOnly}
            onResize={onResize}
            onClick={onClick}
          />
        )}
        {previewType === 'Video' && (
          <Video
            name={attachment?.state?.filename}
            extension={attachment?.getExtension()}
            url={attachment?.state?.embedUrl}
            width={element.width}
            readOnly={readOnly}
            onResize={onResize}
            onClick={onClick}
          />
        )}
        {previewType === 'Meta' && (
          <Meta
            iconName={attachment?.getIconName()}
            name={attachment?.state?.filename}
            extension={attachment?.getExtension()}
            onClick={onClick}
          />
        )}
      </div>
      {children}
    </div>
  );
};
