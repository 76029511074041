import {
  BlockElement,
  Editor,
  Node,
  Path,
  Range,
  Transforms,
  isBlockElement,
} from '@meisterlabs/slate';
import { withOnKeyDown } from '@meisterlabs/slate-react';

import { isCodeBlock } from '../types';

const TAB = '  ';
const NEW_LINE = '\n';

const getCodeBlock = (editor: Editor) => {
  return Editor.nodes<BlockElement>(editor, {
    match: (n) => isBlockElement(n) && isCodeBlock(n),
  }).next().value;
};

const getRows = (text: string, start: number, end: number) => {
  let index = 0;
  let currentStartIndex = 0;
  const rows = [];
  const maxIndex = text.length;

  while (index <= text.length) {
    if (text[index] === NEW_LINE || index === maxIndex) {
      if (index >= start) {
        rows.push({
          text: text.substring(currentStartIndex, index),
          index: currentStartIndex,
        });
      }

      if (index >= end) break;

      currentStartIndex = index + 1;
    }

    index++;
  }

  return rows;
};

const insert = (editor: Editor, offset: number) => {
  const at = {
    path: editor.selection.focus.path,
    offset,
  };

  editor.insertText(TAB, { at });

  return;
};

const remove = (editor: Editor, offset: number, text: string) => {
  if (!text.startsWith(TAB)) return false;

  const at = {
    path: editor.selection.focus.path,
    offset,
  };

  Transforms.delete(editor, {
    at,
    unit: 'character',
    distance: TAB.length,
  });

  return true;
};

/**
 * Middleware that adds support for typing inside code blocks.
 * It adds support for the following behaviours:
 * - Pressing the `Enter` key inside a code block will insert a new line.
 * - Pressing the `Tab` key inside a code block will insert a tab character.
 * - Pressing the `Tab` key inside a code block with a selection will insert a tab character at the start of each selected line.
 * - Pressing the `Shift + Tab` key inside a code block with a selection will remove a tab character from the start of each selected line.
 */
export const withCodeTypingBehavior = function () {
  withOnKeyDown((event, editor) => {
    if (event.key !== 'Enter') return;

    const blockEntry = getCodeBlock(editor);

    if (!blockEntry) return;

    const [block] = blockEntry;

    // Get tabs from the start of the line
    const [{ text }] = getRows(
      Node.string(block),
      editor.selection.focus.offset,
      editor.selection.focus.offset
    );

    let count = 0;
    let currentText = text;

    while (currentText.startsWith(TAB)) {
      count++;
      currentText = currentText.substring(TAB.length);
    }

    event.preventDefault();
    event.stopPropagation();

    editor.insertText(NEW_LINE + TAB.repeat(count));

    return false;
  });

  withOnKeyDown((event, editor) => {
    if (event.key !== 'Tab') return;
    if (event.shiftKey) return;
    if (!Range.isCollapsed(editor.selection)) return;

    const blockEntry = getCodeBlock(editor);

    if (!blockEntry) return;

    event.preventDefault();
    event.stopPropagation();

    Editor.insertText(editor, TAB);

    return false;
  });

  withOnKeyDown((event, editor) => {
    if (event.key !== 'Tab') return;
    if (event.shiftKey) return;
    if (Range.isCollapsed(editor.selection)) return;
    if (!Path.equals(editor.selection.focus.path, editor.selection.anchor.path))
      return;

    const blockEntry = getCodeBlock(editor);

    if (!blockEntry) return;

    const [block] = blockEntry;

    const rows = getRows(
      Node.string(block),
      Range.start(editor.selection).offset,
      Range.end(editor.selection).offset
    );

    event.preventDefault();
    event.stopPropagation();

    rows.reduce((extraOffset, { index }) => {
      insert(editor, index + extraOffset);

      // Add 1 to the extra offset to account for the added tab
      return extraOffset + TAB.length;
    }, 0);

    return false;
  });

  withOnKeyDown((event, editor) => {
    if (event.key !== 'Tab') return;
    if (!event.shiftKey) return;
    if (!Path.equals(editor.selection.focus.path, editor.selection.anchor.path))
      return;

    const blockEntry = getCodeBlock(editor);

    if (!blockEntry) return;

    const [block] = blockEntry;

    const rows = getRows(
      Node.string(block),
      Range.start(editor.selection).offset,
      Range.end(editor.selection).offset
    );

    event.preventDefault();
    event.stopPropagation();

    rows.reduce((extraOffset, { index, text }) => {
      if (!remove(editor, index + extraOffset, text)) return extraOffset;

      // Subtract 1 from the extra offset to account for the removed tab
      return extraOffset - TAB.length;
    }, 0);

    return false;
  });
};
