import React from 'react';
import { parser, removeUnusedProperties } from '@meisterlabs/slate';
import {
  withElementClassNames,
  withElementNormalizer,
  withElementRenderer,
} from '@meisterlabs/slate-react';

import { ChecklistItem } from '../components/Checklist';
import { ChecklistItemBlock, isChecklistItemBlock } from '../types';

export interface WithCheckListOptions {
  onChecklistToggled?: (checked: boolean) => void;
}

/**
 * This middleware adds the Checkbox list.
 * The checked items can be further styled in css with the class `checked`.
 */
export const withCheckList = function (options: WithCheckListOptions = {}) {
  withElementRenderer<ChecklistItemBlock>(
    (element) => isChecklistItemBlock(element),
    (props) => <ChecklistItem {...props} {...options} />
  );

  withElementClassNames<ChecklistItemBlock>(function (element) {
    if (element.listType !== 'check') return;
    if (parser.parseToBoolean(element.checked)) return ['checked', 'listType'];
  });

  withElementNormalizer<ChecklistItemBlock>(
    (element) => isChecklistItemBlock(element),
    (element, path, editor) =>
      removeUnusedProperties(editor, element, path, ['checked', 'listType'])
  );
};
