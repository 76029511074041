let promise;

export const loadPicker = function (pickerUrl: string) {
  if (promise) return promise;

  const script = document.createElement('script');

  promise = new Promise(function (resolve) {
    script.setAttribute('src', pickerUrl);
    script.setAttribute('async', 'true');
    script.onload = resolve;

    document.body.appendChild(script);
  });

  return promise;
};
