import { Editor, Transforms } from 'slate';

import { BlockElement } from '../types';

/**
 * Delete a block by its key
 */
export const deleteBlock = function (editor: Editor, key: string) {
  Transforms.removeNodes(editor, {
    at: [],
    match: (node: BlockElement) => node.key === key,
  });
};
