import { parser } from '@meisterlabs/slate';

import {
  HAST,
  MDAST,
  SAST,
  withHtmlDeserializer,
  withHtmlSerializer,
  withMarkdownDeserializer,
  withMarkdownSerializer,
} from '@meisterlabs/slate-serializer';

interface ListItemBlock extends SAST.Block {
  type: 'list-item';
  properties: {
    listType: 'unordered' | 'ordered' | 'check';
    depth?: string;
    checked?: string;
  };
}

const listTypeToTag = {
  unordered: 'ul',
  check: 'ul',
  ordered: 'ol',
};

const tagToListType = {
  ul: 'unordered',
  ol: 'ordered',
};

const createHtmlListElement = function (
  tag: string,
  depth: number,
  listItem: HAST.Element
) {
  let result = listItem;

  for (let i = 0; i < depth + 1; i++) {
    result = {
      type: 'element',
      tagName: tag,
      children: [result],
    };
  }

  return result;
};

/**
 * List deserialization is complex, be sure you understand how they look before doing anything here.
 * However, I tried to give some examples on top of the deserializers.
 */
export const withListSerializers = function () {
  withHtmlSerializer<ListItemBlock>(
    (_, node) => node.type === 'list-item',
    ({ multiple }, node) => {
      const currentTag = listTypeToTag[node.properties.listType];
      const currentDepth = parser.parseToInt(node.properties.depth ?? '0');

      const children = multiple<SAST.Content, HAST.Element>(
        node.children,
        node
      );

      if (node.properties.listType === 'check') {
        const isChecked = parser.parseToBoolean(node.properties.checked);

        children.unshift({
          type: 'element',
          tagName: 'input',
          properties: {
            type: 'checkbox',
            ...(isChecked ? { checked: true } : {}),
          },
          children: [],
        });
      }

      const listItem = {
        type: 'element',
        tagName: 'li',
        children,
      } as HAST.Element;

      const list = createHtmlListElement(currentTag, currentDepth, listItem);

      return list;
    }
  );

  /**
   * HTML lists are build the following way
   * - ul
   *  - ol
   *   - li
   *   - li
   *  - ul
   *   - li
   *
   * Basically, one ul/ol per depth and at the end a li
   */
  withHtmlDeserializer<HAST.Element>(
    (_, node) => node.tagName === 'ul' || node.tagName === 'ol',
    ({ multiple }, node) => {
      return multiple<HAST.ElementContent, SAST.Content>(
        node.children,
        node
      ).flatMap((child) => {
        if (!SAST.isBlock(child) || child.type !== 'list-item') return child;

        const listItem = child as ListItemBlock;

        const hasDepth = 'depth' in listItem.properties;
        const depth = hasDepth
          ? parser.parseToInt(listItem.properties.depth) + 1
          : 0;

        return {
          ...listItem,
          properties: {
            ...listItem.properties,
            depth: parser.parseToString(depth),
            listType:
              listItem.properties.listType ?? tagToListType[node.tagName],
          },
        };
      });
    }
  );

  withHtmlDeserializer<HAST.Element>(
    (_, node) => node.tagName === 'li',
    ({ multiple }, node) => {
      const children = node.children;

      if (children.length === 0) {
        return {
          type: 'list-item',
          properties: {},
          children: [{ type: 'text', value: '' }],
        };
      }

      const firstChild = node.children[0] as HAST.Element;

      const isCheckbox =
        firstChild.type === 'element' &&
        firstChild.tagName === 'input' &&
        firstChild.properties?.type === 'checkbox';

      if (isCheckbox) children.shift();

      const extraProps =
        isCheckbox && firstChild.properties?.checked ? { checked: '1' } : {};

      return {
        type: 'list-item',
        properties: {
          listType: isCheckbox ? 'check' : null,
          ...extraProps,
        },
        children: multiple<HAST.ElementContent, SAST.Content>(children, node),
      };
    }
  );

  withMarkdownSerializer<ListItemBlock>(
    (_, node) => node.type === 'list-item',
    ({ multiple }, node) => {
      const currentDepth = parser.parseToInt(node.properties.depth ?? '0');
      const isChecked = node.properties.listType === 'check';
      const isOrdered = node.properties.listType === 'ordered';

      return {
        type: 'list',
        ordered: isOrdered,
        depth: currentDepth,
        children: [
          {
            type: 'listItem',
            checked: isChecked
              ? parser.parseToBoolean(node.properties.checked ?? '0')
              : null,
            children: [
              {
                type: 'paragraph',
                children: multiple(node.children, node),
              },
            ],
          },
        ],
      } as MDAST.List & { depth: number };
    }
  );

  withMarkdownDeserializer<MDAST.Content, ListItemBlock>(
    (_, node, parent) => node.type !== 'list' && parent?.type === 'listItem',
    ({ multiple }, node) => {
      return {
        type: 'list-item',
        properties: {
          listType: 'unordered',
        },
        // It is possible somebody tries to paste some blocks like thematicBreak, in this case we use an empty text
        children:
          'children' in node
            ? multiple((node as MDAST.Parent).children, node)
            : [{ type: 'text', value: '' }],
      };
    }
  );

  /**
   * Markdown lists are build the following way:
   * - list
   *  - listItem
   *   - paragraph
   *  - listItem
   *   - list
   *    - listItem
   *     - paragraph
   *    - listItem
   *     - etc.
   *
   * So basically per depth we have one
   * - list
   *  - listItem
   *
   * And at the end a paragraph, this is quite hard to translate independentely to the slate format of
   * - list-item
   *  - text
   *
   * If we run into more problems it might make sense just writing the whole deserializer inside this one withMarkdownDeserializer
   * for example just catching the outer most markdown list, and then handling everything inside here.
   * However, for now this seems to be working quite nicely. (Famous last words)
   */
  withMarkdownDeserializer<MDAST.List>(
    (_, node) => node.type === 'list',
    ({ one, multiple }, node) => {
      return node.children.flatMap((innerNode) => {
        if (innerNode.type !== 'listItem') return one(innerNode, node);

        return multiple(innerNode.children, innerNode).flatMap(
          function (listItem) {
            if (!SAST.isBlock(listItem) || listItem.type !== 'list-item')
              return listItem;

            const hasDepth =
              'depth' in listItem.properties &&
              typeof listItem.properties.depth == 'string';
            const depth = hasDepth
              ? parser.parseToInt(listItem.properties.depth as string) + 1
              : 0;

            const slateNode = {
              ...listItem,
              properties: {
                // The order of calling here is very important,
                // Because we need to take the listType of the deepest listItem
                listType: node.ordered ? 'ordered' : 'unordered',
                ...listItem.properties,
                depth: parser.parseToString(depth),
              },
            } as ListItemBlock;

            if (innerNode.checked != null) {
              slateNode.properties = {
                ...slateNode.properties,
                checked: parser.parseToString(innerNode.checked),
                listType: 'check',
              };
            }

            return slateNode;
          }
        );
      }) as ListItemBlock[];
    }
  );
};
