import logo from '../assets/GoogleDocs.png';
import { Parser } from '../types';

const re = new RegExp('^https?://docs.google.com/document/(.*)$');

const Embed: Parser = {
  logo,
  name: 'Google Docs',
  parseUrl(url) {
    const match = url.match(re);

    if (!match) return null;

    const frameSrc = url.replace('/edit', '/preview');

    return {
      type: 'GoogleDocs',
      url,
      frameSrc,
      frameAspectRatio: 1.414 / 1,
      frameBorder: true,
    };
  },
};

export default Embed;
