import {
  BlockRenderElementProps,
  useSlateWithV,
} from '@meisterlabs/slate-react';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { TocBlock } from '../types';
import Toc from './Toc';
import type { Options } from '../middlewares/withToc';
import getToc, { Item } from '../utils/getToc';

export const Block: React.FC<BlockRenderElementProps<TocBlock> & Options> =
  function (props) {
    const { attributes, children, maxLevel, emptyMessage, onClickItem } = props;
    const { editor, v } = useSlateWithV();

    const timer = useRef<NodeJS.Timeout>(null);
    const [items, setItems] = useState<Array<Item>>([]);

    const updateItems = useCallback(() => {
      const items = getToc(editor, { maxLevel });

      setItems(items);
    }, [editor]);

    // Debounce the update of the items to avoid unnecessary updates and rerenders.
    useEffect(() => {
      if (timer.current) clearTimeout(timer.current);
      timer.current = setTimeout(updateItems, 500);

      return () => {
        if (timer.current) clearTimeout(timer.current);
      };
    }, [v, updateItems]);

    useEffect(() => {
      updateItems();
    }, []);

    return (
      <div
        {...attributes}
        style={attributes.style ?? {}}
        contentEditable={false}
      >
        <Toc
          items={items}
          emptyMessage={emptyMessage}
          onClickItem={onClickItem}
        />
        {children}
      </div>
    );
  };
