import React from 'react';
import { colors, rgba } from '@meisterlabs/colors';
import { fonts } from '@meisterlabs/fonts';
import { Icon, Text, View } from '@meisterlabs/knightrider';
import { Close, Fullscreen } from '@meisterlabs/svgs/TintSmall';
import { Icon as MindMeisterLogo } from '@meisterlabs/svgs/MindMeister';
import { Hyperlink } from '@meisterlabs/react-links';
import { Resizer } from '@meisterlabs/slate-shared-components';
import { Resize } from '@meisterlabs/svgs/Tint';

const styles = {
  root: {
    height: 44,
    position: 'absolute',
    width: '100%',
    bottom: 0,
    left: 0,
    right: 0,
    alignItems: 'center',
    paddingLeft: 15,
    paddingRight: 15,
    backgroundColor: colors.grey100,
    transition: 'transform 200ms ease-in-out',
    transform: 'translateY(44px)',
  },
  icon: {
    width: 24,
    height: 24,
  },
  title: {
    ...fonts.m.regular,
    flexGrow: 1,
    flexShrink: 1,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  meta: {
    ...fonts.s.bold,
    color: colors.grey500,
    paddingLeft: 15,
    textTransform: 'uppercase',
    whiteSpace: 'nowrap',
  },
  resizeHandler: {
    position: 'absolute',
    bottom: 4,
    right: 4,
    cursor: 'ns-resize',
  },
  spacer: {
    width: 15,
  },
  fullScreenIcon: {
    width: 16,
    height: 16,
    cursor: 'pointer',
    color: colors.grey500,
  },
  resizeIcon: {
    width: 12,
    height: 12,
    color: rgba.black(0.2),
  },
};

interface Props {
  metaText: string;
  isHovered: boolean;
  isFullScreen: boolean;
  title: string;
  url: string;
  onClickFullScreen: () => void;
}

export const Footer: React.VFC<Props> = function (props) {
  const { metaText, isHovered, isFullScreen, title, onClickFullScreen, url } =
    props;

  const showFooter = isHovered || isFullScreen;

  return (
    <View
      style={{
        ...styles.root,
        transform: showFooter ? 'translateY(0px)' : 'translateY(44px)',
      }}
    >
      <Icon style={styles.icon}>
        <MindMeisterLogo />
      </Icon>
      <View style={styles.spacer} />
      <Text style={styles.title}>
        <Hyperlink blank url={url}>
          {title}
        </Hyperlink>
      </Text>
      <View style={styles.spacer} />
      <View>
        <Text style={styles.meta}>{metaText}</Text>
      </View>
      <View style={styles.spacer} />
      <Icon style={styles.fullScreenIcon} onClick={onClickFullScreen}>
        {!isFullScreen && <Fullscreen />}
        {isFullScreen && <Close />}
      </Icon>
      {!isFullScreen && (
        <View style={styles.resizeHandler}>
          <Resizer.Handle>
            <Icon style={styles.resizeIcon}>
              <Resize />
            </Icon>
          </Resizer.Handle>
        </View>
      )}
    </View>
  );
};
