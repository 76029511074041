import logo from '../assets/Loom.png';
import { Parser } from '../types';
import findMatch from './utils/findMatch';

const re = /^https:\/\/(www\.)?(use)?loom.com\/(embed|share)\/(.*)$/;

const Embed: Parser = {
  logo,
  name: 'Loom',
  parseUrl(string) {
    return findMatch({
      string,
      find: ({ clean }) => re.test(clean),
      transform({ clean }) {
        return {
          type: 'Loom',
          url: clean,
          frameSrc: clean.replace('share', 'embed'),
          frameAspectRatio: 9 / 16,
          frameBorder: true,
        };
      },
    });
  },
};

export default Embed;
