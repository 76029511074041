import React from 'react';

import TocItem from './TocItem';

const styles = {
  root: {
    cursor: 'pointer',
    paddingTop: 6,
    flexDirection: 'column' as const,
    userSelect: 'none' as const,
  },
};

export type TocItem = {
  key: string;
  depth: number;
  name: string;
};

export type TocProps = {
  emptyMessage: string;
  items: TocItem[];
  onClickItem?: (opts: { key: string }) => void;
};

const Toc: React.VFC<TocProps> = function (props) {
  const { emptyMessage, items, onClickItem } = props;

  return (
    <div style={styles.root}>
      {!items.length && (
        <TocItem
          key='toc-placeholder'
          name={emptyMessage}
          depth={0}
          handleClick={() => null}
          blockKey=''
          isPlaceholder
        />
      )}
      {items &&
        items.map(({ key, name, depth }) => (
          <TocItem
            key={key}
            blockKey={key}
            name={name}
            depth={depth}
            handleClick={() => onClickItem?.({ key })}
          />
        ))}
    </div>
  );
};

const MemoizedToc = React.memo(Toc);

export default MemoizedToc;
