import {
  withInlineStyle,
  withInlineStyleSerialization,
} from './withInlineStyle';

/**
 * Enable inline style bold with a shortcut.
 */
export const withInlineBold = function (shortcut: string) {
  withInlineStyle(shortcut, 'bold');
};

export const withInlineBoldSerialization = function () {
  withInlineStyleSerialization('bold', {
    html: {
      tag: 'strong',
      alias: ['b'],
    },
    markdown: {
      type: 'strong',
    },
  });
};
