import React, { useContext, useMemo, useState } from 'react';
import { fonts } from '@meisterlabs/fonts';
import { colors } from '@meisterlabs/colors';

import { TocContext } from './TocContext';

const Style = (props: { depth: number; isHighlighted: boolean }) => ({
  root: {
    alignItems: 'center',
    paddingVertical: 4,
    borderLeftWidth: 3,
    borderLeftStyle: 'solid' as const,
    borderColor: props.isHighlighted ? colors.blue : colors.grey300,
    paddingLeft: 16 + props.depth * 16,
    paddingTop: 4,
    paddingBottom: 4,
  },
  name: {
    ...fonts.m.regular,
    stretch: true,
    truncate: true,
    color: props.isHighlighted ? colors.grey700 : colors.grey500,
    fontWeight: props.depth === 0 ? 'bold' : 'normal',
  },
});

type TocItemProps = {
  blockKey: string;
  name: string;
  depth: number;
  isPlaceholder?: boolean;
  handleClick: (arg0: { key: string }) => void;
};

const TocItem: React.VFC<TocItemProps> = function (props) {
  const { blockKey, name, depth, isPlaceholder = false, handleClick } = props;

  const context = useContext(TocContext);

  const isSelected = blockKey === context.highlightedBlockKey;
  const [isHovered, setIsHovered] = useState(false);

  const isHighlighted = isHovered || isSelected || isPlaceholder;

  const style = useMemo(() => {
    return Style({ depth, isHighlighted });
  }, [depth, isHovered, isHighlighted]);

  return (
    <div
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      style={style.root}
      onClick={() => handleClick({ key: blockKey })}
    >
      <span style={style.name}>{name}</span>
    </div>
  );
};

export default TocItem;
