import React, { useCallback, useRef, useState } from 'react';

import { ResizeContext } from './Context';

export interface VerticalChildrenProps {
  height: number;
  isResizing: boolean;
  naturalSizeRefCallback: React.RefCallback<
    HTMLImageElement | HTMLVideoElement
  >;
}

export interface VerticalProps {
  initialHeightDelta?: number;
  minHeight: number;
  setNewHeight: (size: number) => void;
  children: (props: VerticalChildrenProps) => JSX.Element;
}

export const Vertical = function (props: VerticalProps) {
  const { initialHeightDelta = 0, minHeight, children, setNewHeight } = props;

  const [height, setHeight] = useState<number>(initialHeightDelta);
  const [isResizing, setIsResizing] = useState(false);
  const heightRef = useRef(initialHeightDelta);
  const naturalHeightRef = useRef<HTMLImageElement | HTMLVideoElement | null>(
    null
  );

  const handleResize = useCallback(
    ({ y }) => {
      const maxHeight =
        (naturalHeightRef.current as HTMLImageElement)?.naturalHeight ??
        (naturalHeightRef.current as HTMLVideoElement)?.videoHeight ??
        Infinity;

      if (maxHeight === 0) return;

      const newHeight = Math.min(
        Math.max(heightRef.current + minHeight + y, minHeight),
        maxHeight
      );
      const deltaHeight = newHeight - minHeight;

      setHeight(deltaHeight);
      heightRef.current = deltaHeight;
    },
    [minHeight]
  );

  const handleResizeStart = () => {
    setIsResizing(true);
  };

  const handleResizeStop = () => {
    setIsResizing(false);
    setNewHeight(heightRef.current);
  };

  const naturalSizeRefCallback = useCallback((node) => {
    if (node !== null) {
      naturalHeightRef.current = node;
      handleResize({ y: 0 });
    }
  }, []);

  return (
    <ResizeContext.Provider
      value={{
        handleResize,
        handleResizeStart,
        handleResizeStop,
      }}
    >
      {children({
        isResizing,
        height: height + minHeight,
        naturalSizeRefCallback,
      })}
    </ResizeContext.Provider>
  );
};
