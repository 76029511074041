import React, { useRef } from 'react';
import { useDrop } from 'react-dnd';
import type { Identifier, XYCoord } from 'dnd-core';
import { NativeTypes } from 'react-dnd-html5-backend';
import { BlockElement } from '@meisterlabs/slate';
import { BlockRenderElementProps, useReadOnly } from '@meisterlabs/slate-react';
import { colors } from '@meisterlabs/colors';

const styles = {
  dropLineTop: {
    position: 'absolute',
    top: '-1px',
    left: '0',
    backgroundColor: colors.blue,
    height: '2px',
    width: '100%',
  },
  dropLineBottom: {
    position: 'absolute',
    bottom: '-1px',
    left: '0',
    backgroundColor: colors.blue,
    height: '2px',
    width: '100%',
  },
} as Record<string, React.CSSProperties>;

type DropPosition = 'top' | 'bottom';

type Item = Array<string> | File;

export type DropResult = {
  key: string;
  position: DropPosition;
};

export const Droppable: React.FC<BlockRenderElementProps<BlockElement>> =
  function ({ children, element }) {
    const ref = useRef<HTMLDivElement>(null);
    const [position, setPosition] = React.useState<DropPosition | null>(null);
    const readOnly = useReadOnly();

    const [{ handlerId, isOver }, drop] = useDrop<
      Item,
      DropResult,
      { handlerId: Identifier | null; isOver: boolean }
    >({
      accept: ['block', NativeTypes.FILE],
      canDrop: () => !readOnly,
      collect(monitor) {
        return {
          handlerId: monitor.getHandlerId(),
          isOver: monitor.isOver(),
        };
      },
      hover(item, monitor) {
        if (!ref.current) {
          setPosition(null);
          return;
        }

        // If the item is not over the editor, return
        if (!monitor.isOver()) {
          setPosition(null);
          return;
        }

        const dragKeys = Array.isArray(item) && item;
        const hoverKey = element.key;

        // Don't replace items with themselves
        if (dragKeys && dragKeys.includes(hoverKey)) {
          setPosition(null);
          return;
        }

        // Determine rectangle on screen
        const hoverBoundingRect = ref.current?.getBoundingClientRect();

        // Get vertical middle
        const hoverMiddleY =
          (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

        // Determine mouse position
        const clientOffset = monitor.getClientOffset();

        // Get pixels to the top
        const hoverClientY =
          (clientOffset as XYCoord).y - hoverBoundingRect.top;

        if (hoverClientY < hoverMiddleY) setPosition('top');
        if (hoverClientY > hoverMiddleY) setPosition('bottom');
      },
      drop: () => {
        return {
          key: element.key,
          position,
        };
      },
      options: {
        dropEffect: 'move',
      },
    });

    drop(ref);

    return (
      <div
        data-handler-id={handlerId}
        ref={ref}
        style={{
          position: 'relative',
        }}
      >
        {isOver && position === 'top' && <div style={styles.dropLineTop} />}
        {children}
        {isOver && position === 'bottom' && (
          <div style={styles.dropLineBottom} />
        )}
      </div>
    );
  };
