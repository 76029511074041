import { find as linkifyFind } from 'linkifyjs';
import qs from 'qs';

import { FrameData } from '../../types';

interface Link {
  type: string;
  value: string;
  isLink: boolean;
  href: string;
  start: number;
  end: number;
}

interface FindMatchProps {
  find: (props: ExtendedListingProps) => boolean;
  string: string;
  transform: (props: ExtendedListingProps) => FrameData | null;
}

type ExtendedListingProps = Link & {
  clean: string;
  url: URL;
};

const last: { string: string | null; result: ExtendedListingProps[] | null } = {
  string: null,
  result: null,
};

const findLinks = function (string: string) {
  if (last.string === string) return last.result;

  last.string = string;

  last.result = string
    // Separate links from eg. src='link'
    .split('"')
    .flatMap((string) => linkifyFind(string))
    .flatMap(function (link) {
      if (!link.href.match(/\?./)) return [link];

      // Find links in query params like foo.com?url=xxx
      const linksInParams = Object.values(qs.parse(link.href)).flatMap(
        (string) => {
          if (typeof string !== 'string') return null;

          return linkifyFind(string);
        }
      );

      return [link, ...linksInParams] as Array<Link>;
    })
    .map(function (link) {
      if (link === null) return;

      // The linkifyjs package recognizes strings like https://example.<iframe as a link.
      // The native URL constructor does not, so such links need to be ignored.
      try {
        const url = new URL(link.href);
        const cleanLink = `${url.origin}${url.pathname}`;

        return {
          ...link,
          url,
          clean: cleanLink.endsWith('/') ? cleanLink.slice(0, -1) : cleanLink,
        };
      } catch (error) {
        return null;
      }
    })
    .filter((item) => item !== null);

  return last.result;
};

const findMatch = function ({ find, string, transform }: FindMatchProps) {
  const link = findLinks(string)?.find(find);

  if (!link) return null;

  return transform(link);
};

export default findMatch;
