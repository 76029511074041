import { Editor, EditorNodesOptions, Node, NodeEntry } from 'slate';

import { BlockElement } from '../types';
import { isBlockElement } from './isBlockElement';

/**
 * Finds the first node in the editor that matches the given options.
 */
export const findNode = function <T extends Node>(
  editor: Editor,
  options: EditorNodesOptions<Node>
): NodeEntry<T> | undefined {
  const entries = Editor.nodes<T>(editor, options);

  for (const [node, path] of entries) {
    return [node, path];
  }
};

/**
 * Find a node by its key
 */
export const findNodeByKey = function (
  editor: Editor,
  key: string
): NodeEntry<BlockElement> | undefined {
  const nodeEntry = findNode<BlockElement>(editor, {
    at: [],
    match: (node: BlockElement) => node.key === key,
  });

  if (!nodeEntry || !isBlockElement(nodeEntry[0])) return undefined;

  return nodeEntry;
};
