import React, { useEffect } from 'react';

import { Spinner } from '../Spinner';
import { Broken } from '../Broken';
import FileManager, { Status } from './FileManager';

export interface Props {
  url: string;
  fetchFile: (url: string) => Promise<Blob>;
  children: (url: string) => JSX.Element;
}

export const LazyFile = function (props: Props): JSX.Element {
  const { url, fetchFile, children } = props;

  const [status, setStatus] = React.useState<string>(FileManager.status(url));
  const [clientUrl, setClientUrl] = React.useState<string>(
    FileManager.clientUrl(url)
  );

  useEffect(() => {
    FileManager.load(url, fetchFile).then((file) => {
      setStatus(file.status);
      setClientUrl(file.url);
    });
  });

  switch (status) {
    case Status.success:
      return children(clientUrl);
    case Status.loading:
      return <Spinner />;
    case Status.failed:
      return <Broken />;
    default:
      return null;
  }
};
