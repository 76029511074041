import * as HAST from 'hast';

import {
  Handlers as DefaultHandlers,
  StateOptions,
  createState as createDefaultState,
} from './default';
import * as Sast from '../sast';
import { HastNode } from '../types';

export type Handlers = DefaultHandlers<HastNode, Sast.Node>;

const defaultHandlers: Handlers = [
  [
    (_, node) => node.type === 'root',
    ({ multiple }, node) => {
      if (node.type !== 'root') return;

      const children = multiple(node.children, node);

      if (children.some((child) => child.type === 'root')) {
        throw new Error('Root nodes cannot have root nodes as children');
      }

      return {
        type: 'root',
        children,
      } as Sast.Root;
    },
  ],
  [
    (_, node) => node.type === 'text',
    (_, node: HAST.Text, parent: HastNode) => {
      // Ignore text nodes that are direct children of the body element
      // Since this should never happen when we copy/paste in the editor
      // but windows is adding empty text nodes there
      if (!!parent && parent.type === 'element' && parent.tagName === 'body')
        return [];

      return {
        type: 'text',
        value: node.value,
      };
    },
  ],
  // We don't want to show comments or doctype in the output
  [(_, node) => node.type === 'comment', () => []],
  [(_, node) => node.type === 'doctype', () => []],
];

export type Options = Omit<StateOptions<HastNode, Sast.Node>, 'getHandleKey'>;

export const createState = function (options: Options = {}) {
  const handlers = [...options.handlers, ...defaultHandlers];

  return createDefaultState<HastNode, Sast.Node>({ handlers });
};
